@charset "UTF-8";
/*! Copyright (©) 11ST. All Rights Reserved. */
.c_search_item { position: relative; box-sizing: border-box; border: 1px solid #ddd; background: #fff; }

.c_search_item .search_text { display: block; width: 100%; background: none; box-sizing: border-box; border: none; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; letter-spacing: 0; color: #111; outline: none; padding: 0; }

.c_search_item .search_text::placeholder { color: #999; }

.c_search_item .search_button { position: absolute; right: -1px; top: -1px; overflow: hidden; line-height: 200px; height: 100%; }

.c_search_item .c_layer_item { width: 100%; }

.c_billboard { position: relative; overflow: hidden; width: 1240px; height: 400px; }

.c_billboard .visual li { float: left; }

.c_billboard .navigator { position: absolute; right: 0; bottom: 25px; }

.c_billboard .navigator button { float: left; position: relative; overflow: hidden; line-height: 300px; }

.c_billboard .navigator button:before { position: absolute; content: ""; }

.c_billboard .navigator button.previous:before { left: 16px; }

.c_billboard .navigator button.next:before { right: 16px; }

.c_billboard .indicate { position: absolute; bottom: 25px; right: 102px; line-height: 45px; box-sizing: border-box; }

.c_billboard .indicate .pagination { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_billboard .indicate .pagination .current { font-weight: bold; }

.c_billboard .indicate .more { position: absolute; right: 0; top: 0; overflow: hidden; line-height: 300px; }

.c_billboard .indicate .more::before { position: absolute; right: 11px; top: 11px; content: ""; }

.c_billboard .control { position: absolute; right: 243px; bottom: 25px; }

.c_billboard .control button { position: relative; overflow: hidden; line-height: 300px; }

.c_billboard .control button:before { position: absolute; left: 50%; top: 50%; content: ""; }

.c_billboard_animated .visual .active { animation: fadeIn 1s ease-in; }

.c_billboard_animated .slide { transition: 1s all; }

.c-p-more { position: relative; display: inline-block; }

.c_more { position: relative; display: inline-block; padding: 0 12px 0 0; }

.c_more:after { position: absolute; top: 50%; right: 2px; width: 5px; height: 5px; margin-top: -3px; border: solid #666; border-width: 1px 1px 0 0; transform: rotate(45deg); content: ""; }

.c_more.c_more_down { padding: 0 14px 0 0; }

.c_more.c_more_down:after { margin-top: -4px; transform: rotate(135deg); }

.c_more.c_more_up { padding: 0 14px 0 0; }

.c_more.c_more_up:after { margin-top: -2px; transform: rotate(-45deg); }

.c_more_icon { display: block; overflow: hidden; position: relative; line-height: 300px; width: 50px; height: 50px; border: 1px solid #666; border-radius: 50%; }

.c_more_icon:after { position: absolute; top: 50%; right: 50%; width: 5px; height: 5px; margin-right: -3px; margin-top: -3px; border: solid #666; border-width: 1px 1px 0 0; transform: rotate(45deg); content: ""; }

.c-list-more { margin: 50px 0; }

.c-list-more__button { position: relative; display: block; padding: 0 30px; margin: 0 auto; background-color: #fff; border: 1px solid #ccc; border-radius: 28px; box-sizing: border-box; font-size: 17px; line-height: 54px; }

.c-list-more__button::after { width: 6px; height: 6px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(135deg); display: inline-block; margin: -9px 0 0 10px; content: ""; }

.c-list-more__text { color: #111; }

.c-list-more__text--highlight { color: #0b83e6; }

.c-list-more__text--emphasis { color: #ff0038; }

.c_list_card { position: relative; }

.c_list_card ul { zoom: 1; }

.c_list_card ul:after { display: block; content: ""; clear: both; }

.c_list_card li { position: relative; float: left; box-sizing: border-box; }

.c_list_card li:first-child { margin-left: 0; }

.c_list_card .c_adtext { position: absolute; left: 0; z-index: 10; }

.c_list_card .c-starrate__sati { margin: 2px 3px 0 0; }

.c_list_card .list_navigator button { overflow: hidden; position: absolute; top: 50%; line-height: 300px; background: #fff; z-index: 10; }

.c_list_card .list_navigator button::before { position: absolute; left: 50%; top: 50%; margin: -9px 0 0 -5px; content: ""; }

.c_list_card .list_navigator button.previous { left: -25px; }

.c_list_card .list_navigator button.next { right: -25px; }

.c_list_card .list_navigator button:disabled { display: none; }

.c_list_card.c_list_card_deal .list_navigator button, .c_list_card.c_list_card_collection .list_navigator button { width: 50px; height: 50px; margin-top: -25px; border-radius: 50%; box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_list_card.c_list_card_deal .list_navigator button.previous::before, .c_list_card.c_list_card_collection .list_navigator button.previous::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -87px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button.previous:hover::before, .c_list_card.c_list_card_collection .list_navigator button.previous:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -102px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button.next::before, .c_list_card.c_list_card_collection .list_navigator button.next::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: 0px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button.next:hover::before, .c_list_card.c_list_card_collection .list_navigator button.next:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -15px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button:hover, .c_list_card.c_list_card_collection .list_navigator button:hover { box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6); }

.c_list_card.c_list_card_deal { width: 1240px; }

.c_list_card.c_list_card_deal .swiper-container, .c_list_card.c_list_card_deal .c_slide_view { padding: 25px; margin: -25px; }

.c_list_card.c_list_card_deal .swiper-container.swiper_doing, .c_list_card.c_list_card_deal .swiper-container.c_slide_doing, .c_list_card.c_list_card_deal .c_slide_view.swiper_doing, .c_list_card.c_list_card_deal .c_slide_view.c_slide_doing { margin-right: -3px; margin-left: -3px; padding-right: 3px; padding-left: 3px; }

.c_list_card.c_list_card_deal .swiper-slide, .c_list_card.c_list_card_deal .c_slide { margin-right: 29px; }

.c_list_card.c_list_card_deal li:not(:first-of-type) { margin-left: 29px; }

.c_list_card.c_list_card_deal .c-card-item__soldout-text::before { width: 118px; height: 118px; background-size: 100% 100%; }

.c_list_card.c_list_card_collection { width: 1240px; }

.c_list_card.c_list_card_collection ul { display: flex; width: 100%; flex-wrap: wrap; }

.c_list_card.c_list_card_collection li { float: none; vertical-align: top; }

.c_list_card.c_list_card_collection .swiper-container, .c_list_card.c_list_card_collection .c_slide_view { padding: 25px; margin: -25px; }

.c_list_card.c_list_card_collection .swiper-container.swiper_doing, .c_list_card.c_list_card_collection .swiper-container.c_slide_doing, .c_list_card.c_list_card_collection .c_slide_view.swiper_doing, .c_list_card.c_list_card_collection .c_slide_view.c_slide_doing { margin-right: -3px; margin-left: -3px; padding-right: 3px; padding-left: 3px; }

.c_list_card.c_list_card_collection .swiper-slide, .c_list_card.c_list_card_collection .c_slide { margin-right: 30px; }

.c_list_card.c_list_card_collection .swiper-slide li:first-of-type, .c_list_card.c_list_card_collection .c_slide li:first-of-type { margin-left: 0px; }

.c_list_card.c_list_card_collection .c_adtext { top: 208px; }

.c_list_card.c_list_card_collection .c-nation { margin-bottom: 10px; }

.c_list_card.c_list_card_collection .c-card-item__soldout-text::before { width: 60%; height: 60%; background-size: 100% 100%; }

.c_list_card.c_list_card_collection.col_5 .swiper-slide, .c_list_card.c_list_card_collection.col_5 .c_slide { margin-right: 30px; }

.c_list_card.c_list_card_collection.col_5 li { flex: 0 1 224px; width: 224px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_collection.col_5 li:nth-child(1), .c_list_card.c_list_card_collection.col_5 li:nth-child(2), .c_list_card.c_list_card_collection.col_5 li:nth-child(3), .c_list_card.c_list_card_collection.col_5 li:nth-child(4), .c_list_card.c_list_card_collection.col_5 li:nth-child(5) { margin-top: 0; }

.c_list_card.c_list_card_collection.col_5 li:nth-child(5n - 4) { margin-left: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item { width: 224px; border-radius: 4px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__thumb { padding: 10px 10px 0 10px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__thumb-img { width: 204px; height: 204px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__soldout-text { left: 10px; top: 10px; width: 204px; height: 204px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__info { zoom: 1; padding: 17px 0 17px 20px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__info:after { display: block; content: ""; clear: both; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-time-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__name { max-height: 3em; padding: 0 20px 0 0; margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info { margin: 0 0 11px 0; padding: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info .c-card-item__lowest-value { clear: left; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info + .c-card-item__delivery { padding-left: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info + .c-card-item__delivery:before { display: none; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-starrate { float: left; line-height: 18px; margin: 0 0 7px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-starrate dd { float: left; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-starrate .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery { position: relative; float: none; clear: both; padding: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery:before { display: none; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery .allclub:before { float: left; margin: 1px 2px 0 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery .sktpass .c-card-item__point { line-height: 18px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__save { display: block; width: 100%; height: 52px; line-height: 52px; text-align: center; font-size: 17px; color: #ff0038; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__save:before { display: inline-block; margin: 12px 6px 0 0; vertical-align: top; overflow: hidden; line-height: 200px; content: ""; }

.c_list_card.c_list_card_collection.col_5 .c_adtext { top: 208px; }

.c_list_card.c_list_card_collection.col_4 .swiper-slide, .c_list_card.c_list_card_collection.col_4 .c_slide { margin-right: 30px; }

.c_list_card.c_list_card_collection.col_4 li { flex: 0 1 287px; width: 287px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_collection.col_4 li:nth-child(1), .c_list_card.c_list_card_collection.col_4 li:nth-child(2), .c_list_card.c_list_card_collection.col_4 li:nth-child(3), .c_list_card.c_list_card_collection.col_4 li:nth-child(4) { margin-top: 0; }

.c_list_card.c_list_card_collection.col_4 li:nth-child(4n-3) { margin-left: 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item { width: 287px; margin: 0 auto; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__thumb-img { overflow: hidden; width: 268px; height: 268px; margin: 0 auto; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__soldout-text { left: 10px; top: 10px; width: 268px; height: 268px; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__info { zoom: 1; padding: 15px 20px 19px; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__info:after { display: block; content: ""; clear: both; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-time-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__name { max-height: 3em; line-height: 1.5; margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__name-addition { max-height: 1.5em; margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info { margin: 0 0 10px 0; padding: 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info + .c-card-item__delivery { padding-left: 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info + .c-card-item__delivery:before { display: none; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-starrate dd { float: left; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__delivery .sktpass .c-card-item__point { line-height: 18px; }

.c_list_card.c_list_card_gallery { width: 1240px; }

.c_list_card.c_list_card_gallery ul { display: flex; width: 100%; flex-wrap: wrap; }

.c_list_card.c_list_card_gallery li { float: none; vertical-align: top; }

.c_list_card.c_list_card_gallery li:nth-of-type(n + 100):nth-of-type(-n + 999) .c-card-item__rank { width: 50px; }

.c_list_card.c_list_card_gallery .c-card-item .c-nation { margin-bottom: 10px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-starrate { margin: 0 0 8px 0; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-card-item__price-info { margin-bottom: 11px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-starrate + .c-card-item__price-info dt + .c-card-item__price { margin-top: 2px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-card-item__delivery .allclub { line-height: 18px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__soldout-text::before { width: 60%; height: 60%; background-size: 100% 100%; }

.c_list_card.c_list_card_gallery.col_5 li { flex: 0 1 224px; width: 224px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_gallery.col_5 li:nth-child(1), .c_list_card.c_list_card_gallery.col_5 li:nth-child(2), .c_list_card.c_list_card_gallery.col_5 li:nth-child(3), .c_list_card.c_list_card_gallery.col_5 li:nth-child(4), .c_list_card.c_list_card_gallery.col_5 li:nth-child(5) { margin-top: 0; }

.c_list_card.c_list_card_gallery.col_5 li:nth-child(5n - 4) { margin-left: 0; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item { width: 224px; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item .c-card-item__thumb-img { width: 204px; height: 204px; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item .c-card-item__soldout-text { height: 204px; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item .c-card-item__lowest-value { clear: left; }

.c_list_card.c_list_card_gallery.col_4 li { flex: 0 1 287px; width: 287px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_gallery.col_4 li:nth-child(1), .c_list_card.c_list_card_gallery.col_4 li:nth-child(2), .c_list_card.c_list_card_gallery.col_4 li:nth-child(3), .c_list_card.c_list_card_gallery.col_4 li:nth-child(4) { margin-top: 0; }

.c_list_card.c_list_card_gallery.col_4 li:nth-child(4n - 3) { margin-left: 0; }

.c_list_card.c_list_card_gallery.col_4 .c-card-item { width: 287px; }

.c_list_card.c_list_card_gallery.col_4 .c-card-item .c-card-item__thumb-img { width: 267px; height: 267px; }

.c_slide_view, .swiper-container { overflow: hidden; width: 100%; }

.c_slide_container, .swiper-wrapper { zoom: 1; }

.c_slide_container:after, .swiper-wrapper:after { display: block; content: ""; clear: both; }

.c_slide, .swiper-slide { float: left; }

.c_card_contents .contents_info a:hover { text-decoration: none; }

.c_card_contents .contents_thumb { overflow: hidden; position: relative; }

.c_card_contents .contents_thumb img { width: 100%; height: 100%; }

.c_card_contents .contents_thumb .video { position: absolute; overflow: hidden; left: 50%; top: 50%; line-height: 200px; z-index: 10; }

.c_card_contents .contents_name { overflow: hidden; }

.c_card_contents .contents_description { position: relative; overflow: hidden; }

.c_card_contents .contents_description .more { position: absolute; bottom: 1px; right: 0; }

.c_card_contents .contents_description .more::before { position: absolute; height: 100%; right: 0; top: 0; content: ""; }

.c_card_contents .contents_description .more:after { position: absolute; top: 50%; right: 1px; margin-top: -3px; }

.c_card_contents .contents_description .more span { position: relative; z-index: 5; }

.c_card_contents .contents_tag { overflow: hidden; }

.c_card_contents .contents_tag a { float: left; }

.c_card_contents .contents_tag a:first-child { margin-left: 0; }

.c_card_contents .contents_tag a:hover { text-decoration: underline; }

.c_card_contents .contents_meta { overflow: hidden; box-sizing: border-box; }

.c_card_contents .contents_meta dl { float: left; position: relative; }

.c_card_contents .contents_meta dt { float: left; }

.c_card_contents .contents_meta dd { float: left; }

.c_card_contents .contents_meta .writer { float: left; }

.c_card_contents .contents_meta .writer .thumb { float: left; }

.c_card_contents .contents_meta .writer .name { overflow: hidden; float: left; white-space: nowrap; text-overflow: ellipsis; }

.c_card_contents .contents_meta .date { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_card_contents .contents_meta .count { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) { .c_list_card.c_list_card_collection.col_4 li { float: left; margin-left: 26px !important; }
  .c_list_card.c_list_card_collection.col_4 li:first-of-type { margin-left: 0 !important; }
  .c_list_card.c_list_card_collection.col_4 li:nth-child(4n - 3) { margin-left: 0 !important; }
  .c_list_card.c_list_card_collection.col_5 li { float: left; margin-left: 26px !important; }
  .c_list_card.c_list_card_collection.col_5 li:first-of-type { margin-left: 0 !important; }
  .c_list_card.c_list_card_collection.col_5 li:nth-child(5n - 4) { margin-left: 0 !important; } }

.c_tab_item ul { zoom: 1; }

.c_tab_item ul:after { display: block; content: ""; clear: both; }

.c_tab_item li { float: left; }

.c_tab_item li button, .c_tab_item li > a { position: relative; display: block; color: #111; font-weight: bold; box-sizing: border-box; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; text-align: center; }

.c_tab_item li a:hover { text-decoration: none; }

.c_tab_item li button { width: 100%; }

.c_tab_item li .icon { display: block; margin: 0 auto; }

.c_tab_item li .icon img { width: 100%; height: 100%; }

.c_tab_item .tab_inner { overflow: hidden; }

.c_tab_item .tab_navigator button { overflow: hidden; position: absolute; top: 50%; line-height: 300px; background: #fff; z-index: 10; }

.c_tab_item .tab_navigator button::before { position: absolute; left: 50%; top: 50%; margin: -9px 0 0 -5px; content: ""; }

.c_tab_item .tab_navigator button.previous { left: -25px; }

.c_tab_item .tab_navigator button.next { right: -25px; }

.c_tab_item .tab_navigator button:disabled { display: none; }

.c_selectbox { display: inline-block; overflow: hidden; width: 220px; height: 46px; border: 1px solid #ddd; vertical-align: middle; }

.c_selectbox .select { width: 220px; /* wrap width + 20px */ height: 46px; border: none; padding-left: 12px; padding-right: 43px; background: url("/img/common/selectbox/style_1.png") no-repeat right 50%; background-size: 38px, 38px; border-radius: 0px; /* iOS 둥근모서리 제거 */ -webkit-appearance: none; /* 네이티브 외형 감추기 */ -moz-appearance: none; appearance: none; line-height: 46px; font-size: 16px; color: #666; /* IE 10, 11의 네이티브 화살표 숨기기 */ }

.c_selectbox .select::-ms-expand { display: none; }

.c_selectbox .select:disabled { background-color: #fafafa; color: #999; }

.c_selectbox.selected .select { color: #111; }

.c_selectbox.disabled { border-color: #f1f1f1; }

.c_selectbox.disabled .select { background-image: url("/img/common/selectbox/arrow_disabled.png"); }

.c_selectbox_style { display: inline-block; position: relative; vertical-align: middle; }

.c_selectbox_style .selectbox_selected { position: relative; min-width: 168px; height: 36px; line-height: 36px; padding: 0 35px 0 20px; text-overflow: ellipsis; font-size: 14px; color: #333; border: 1px solid #eee; box-sizing: border-box; text-align: left; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_selectbox_style .selectbox_button { position: absolute; top: 0; left: 0; overflow: hidden; width: 100%; height: 100%; line-height: 200px; }

.c_selectbox_style .selectbox_button:after { position: absolute; right: 16px; top: 50%; margin: -5px 0 0 -4px; width: 6px; height: 6px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(135deg); }

.c_selectbox_style.active .selectbox_button:after { margin-top: -2px; width: 6px; height: 6px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(-45deg); }

.c_selectbox_style .selectbox_option { display: none; position: absolute; left: 0; top: 35px; width: 100%; padding: 5px 0; box-sizing: border-box; border: 1px solid #eee; border-top-color: #f4f4f4; background: #fff; }

.c_selectbox_style .selectbox_option li > a, .c_selectbox_style .selectbox_option li > button { display: block; padding: 6px 20px; color: #333; box-sizing: border-box; font-size: 14px; }

.c_selectbox_style .selectbox_option li > button { width: 100%; text-align: left; }

.c_selectbox_style .selectbox_option li > button:hover { text-decoration: underline; }

.c_selectbox_style.active .selectbox_option { display: block; z-index: 10; }

.c_selectbox_design { display: inline-block; position: relative; vertical-align: middle; }

.c_selectbox_design:after { position: absolute; right: 16px; top: 50%; margin: -5px 0 0 -4px; width: 6px; height: 6px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(135deg); }

.c_selectbox_design.active:after { margin-top: -2px; width: 6px; height: 6px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(-45deg); }

.c_selectbox_design.active .selectbox_option { display: block; z-index: 10; }

.c_selectbox_design .selectbox_selected { position: relative; min-width: 168px; height: 36px; line-height: 34px; padding: 0 35px 0 20px; text-overflow: ellipsis; font-size: 14px; color: #333; border: 1px solid #eee; box-sizing: border-box; text-align: left; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; cursor: pointer; }

.c_selectbox_design .selectbox_option { display: none; position: absolute; left: 0; top: 35px; width: 100%; padding: 5px 0; box-sizing: border-box; border: 1px solid #eee; border-top-color: #f4f4f4; background: #fff; }

.c_selectbox_design .selectbox_option .option { display: block; width: 100%; padding: 6px 20px; color: #333; box-sizing: border-box; font-size: 14px; text-align: left; cursor: pointer; }

.c_selectbox_design .selectbox_option .option:hover, .c_selectbox_design .selectbox_option .option.focused { text-decoration: underline; }

.c_input_item { display: inline-block; height: 48px; padding: 0 16px; line-height: 46px; border: 1px solid #eee; box-sizing: border-box; font-size: 15px; color: #111; letter-spacing: 0; }

.c_input_item::placeholder { color: #999; }

.c_input_item_help { display: inline-block; }

.c_input_item_at { display: inline-block; margin: 0 8px; }

.c_input_item_message { color: #ff0038; }

.c_checkbox { position: relative; display: inline-block; }

.c_checkbox > span:nth-of-type(1), .c_checkbox > .lab:nth-of-type(1) { position: relative; display: inline-block; height: 22px; padding-left: 32px; vertical-align: middle; line-height: 22px; font-size: 15px; color: #666; }

.c_checkbox > span:nth-of-type(1):before, .c_checkbox > .lab:nth-of-type(1):before { content: ""; position: absolute; top: 0; left: 0; display: block; width: 22px; height: 22px; box-sizing: border-box; border: 1px solid #ccc; background-color: #fff; border-radius: 100%; }

.c_checkbox > span:nth-of-type(1):after, .c_checkbox > .lab:nth-of-type(1):after { content: ""; position: absolute; top: 6px; left: 5px; display: block; width: 10px; height: 6px; border: 1px solid #ddd; border-width: 0 0 1px 1px; transform: rotate(-45deg); }

.c_checkbox input { position: absolute; width: 0; height: 0; opacity: 0; }

.c_checkbox input:checked + span:before, .c_checkbox input:checked + .lab:before { border: 0; background: #ff0038; }

.c_checkbox input:checked + span:after, .c_checkbox input:checked + .lab:after { border-color: #fff; }

.c_checkbox input:focus-visible + span:before, .c_checkbox input:focus-visible + .lab:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.c_checkbox input:disabled + span:before, .c_checkbox input:disabled + .lab:before { border: 0; background-color: #eee; }

.c_checkbox input:disabled + span:after, .c_checkbox input:disabled + .lab:after { border-color: #fff; }

.c_checkbox input[type="checkbox"]:focus + span:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.c_checkbox--simple > .lab:nth-of-type(1)::before { display: none; }

.c_checkbox--simple > .lab:nth-of-type(1)::after { border-color: #ccc; }

.c_checkbox--simple input:checked + span:after, .c_checkbox--simple input:checked + .lab:after { border-color: #ff0038; }

.c_radiobox { position: relative; display: inline-block; }

.c_radiobox > span:nth-of-type(1) { position: relative; display: inline-block; height: 22px; padding-left: 32px; vertical-align: middle; line-height: 22px; font-size: 15px; color: #666; }

.c_radiobox > span:nth-of-type(1):before { content: ""; position: absolute; top: 0; left: 0; width: 24px; height: 24px; box-sizing: border-box; border: 1px solid #ccc; background-color: #fff; background-image: radial-gradient(closest-side, #ddd 47%, #fff 52%); border-radius: 100%; }

.c_radiobox input { position: absolute; width: 0; height: 0; opacity: 0; }

.c_radiobox input:checked + span:before { border-color: #ff0038; background-image: radial-gradient(closest-side, #fff 47%, #ff0038 52%); }

.c_radiobox input:focus-visible + span:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.c_radiobox input:disabled + span:before { border-color: #eee; background-image: radial-gradient(closest-side, #fff 47%, #eee 52%); }

.c_radiobox input:disabled + span { color: #ccc; }

.c_radiobox input[type="radio"]:focus + span:before { box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.c_animate_up { transform: translateY(20px); opacity: 0; transition: transform 0.4s, opacity 0.8s; }

.c_animate_up.loaded { transform: translateY(0); opacity: 1; }

.c-motion-scale .swiper-container, .c-motion-scale .swiper-container img { transition: opacity 1s, transform 1s; opacity: 0; transform: scale(1.05); }

.c-motion-scale.c-lazyloaded .swiper-container, .c-motion-scale .swiper-container img { opacity: 1; transform: scale(1.0005); }

.c-motion-up { transform: translateY(20px); opacity: 0; transition: transform 0.4s, opacity 0.8s; }

.c-motion-up.c-lazyloaded { transform: translateY(0); opacity: 1; }

.c-motion-delay .c-card-item { transition-delay: 0.1s; }

.c-lazyloaded .c-motion-up { transform: translateY(0); opacity: 1; }

.c-lazyloaded .c-motion-up--more { transform: translateY(20px); opacity: 0; }

.c-motion-cart::before { animation: drop-motion 0.3s ease-in; }

.c-button { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button[type="button"], .c-button[type="submit"] { border: 1px solid transparent; }

.c-button--bold { font-weight: bold; }

.c-button--text-left { text-align: left; }

.c-button:disabled, .c-button--disabled { cursor: pointer; }

.c-button:hover { text-decoration: none; }

.c-button--primary { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary[type="button"], .c-button--primary[type="submit"] { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary::after { border-color: #fff; }

.c-button--primary::after[type="button"], .c-button--primary::after[type="submit"] { border-color: #fff; }

.c-button--primary:disabled, .c-button--primary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--primary:disabled[type="button"], .c-button--primary:disabled[type="submit"], .c-button--primary.c-button--disabled[type="button"], .c-button--primary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert[type="button"], .c-button--alert[type="submit"] { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert::after { border-color: #ff0038; }

.c-button--alert::after[type="button"], .c-button--alert::after[type="submit"] { border-color: #ff0038; }

.c-button--alert:disabled, .c-button--alert.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert:disabled[type="button"], .c-button--alert:disabled[type="submit"], .c-button--alert.c-button--disabled[type="button"], .c-button--alert.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal[type="button"], .c-button--normal[type="submit"] { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal::after { border-color: #ccc; }

.c-button--normal::after[type="button"], .c-button--normal::after[type="submit"] { border-color: #ccc; }

.c-button--normal:disabled, .c-button--normal.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal:disabled[type="button"], .c-button--normal:disabled[type="submit"], .c-button--normal.c-button--disabled[type="button"], .c-button--normal.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary[type="button"], .c-button--secondary[type="submit"] { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary::after { border-color: #0b83e6; }

.c-button--secondary::after[type="button"], .c-button--secondary::after[type="submit"] { border-color: #0b83e6; }

.c-button--secondary:disabled, .c-button--secondary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary:disabled[type="button"], .c-button--secondary:disabled[type="submit"], .c-button--secondary.c-button--disabled[type="button"], .c-button--secondary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black { color: #fff; background-color: #333; border-color: #333; }

.c-button--black[type="button"], .c-button--black[type="submit"] { color: #fff; background-color: #333; border-color: #333; }

.c-button--black::after { border-color: #fff; }

.c-button--black::after[type="button"], .c-button--black::after[type="submit"] { border-color: #fff; }

.c-button--black:disabled, .c-button--black.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black:disabled[type="button"], .c-button--black:disabled[type="submit"], .c-button--black.c-button--disabled[type="button"], .c-button--black.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button-luxy[type="button"], .c-button-luxy[type="submit"] { border: 1px solid transparent; }

.c-button-luxy--bold { font-weight: bold; }

.c-button-luxy--text-left { text-align: left; }

.c-button-luxy:disabled, .c-button-luxy--disabled { cursor: pointer; }

.c-button-luxy:hover { text-decoration: none; }

.c-button-luxy--primary { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary[type="button"], .c-button-luxy--primary[type="submit"] { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary::after { border-color: #0f0fd9; }

.c-button-luxy--primary::after[type="button"], .c-button-luxy--primary::after[type="submit"] { border-color: #0f0fd9; }

.c-button-luxy--primary:disabled, .c-button-luxy--primary.c-button-luxy--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy--primary:disabled[type="button"], .c-button-luxy--primary:disabled[type="submit"], .c-button-luxy--primary.c-button-luxy--disabled[type="button"], .c-button-luxy--primary.c-button-luxy--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c_modal { display: none; position: fixed; left: 0; top: 0; right: 0; bottom: 0; z-index: 2000; }

.c_modal.active { display: block; }

.c_modal.c_modal_dimmed { background: rgba(0, 0, 0, 0.2); }

.c_modal--w500 .modal_inner { width: 500px; }

.c_modal .modal_inner { position: relative; margin: 50vh auto; transform: translateY(-50%); background: #fff; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12); }

.c_modal .modal_header { border-bottom: 1px solid #eee; }

.c_modal .modal_header .title { text-align: center; }

.c_modal .modal_content.scroll { overflow-y: auto; height: 100px; }

.c_modal .modal_footer { border-top: 1px solid #f4f4f4; text-align: center; }

.c_modal .modal_footer button { display: inline-block; margin: 0 5px; }

.c_modal .modal_close button { position: absolute; top: 17px; right: 21px; width: 36px; height: 36px; overflow: hidden; line-height: 200px; }

.c_modal .modal_close button:before, .c_modal .modal_close button:after { position: absolute; top: 50%; left: 50%; width: 48px; height: 3px; background-color: #111; content: ""; }

.c_modal .modal_close button:before { -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(45deg); transform: translate(-50%, -50%) scale(0.5) rotate(45deg); }

.c_modal .modal_close button:after { -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(135deg); transform: translate(-50%, -50%) scale(0.5) rotate(135deg); }

.c_layer_expand { display: inline-block; position: relative; }

.c_layer_expand.active { z-index: 100; }

.c_layer_expand.active .c_layer_item { display: block; }

.c_layer_item { display: none; position: absolute; left: 0; width: 240px; padding: 20px; box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36); background: #fff; border-radius: 4px; box-sizing: border-box; z-index: 10; }

.c_layer_item.active { display: block; }

.c_layer_item .layer_info_subject dt { padding-bottom: 6px; font-size: 14px; font-weight: bold; color: #111; }

.c_layer_item .layer_info_subject dd { margin-top: 4px; }

.c_layer_item .layer_info_subject .dash::before { display: inline-block; width: 4px; height: 1px; margin-right: 4px; background-color: #666; vertical-align: 5px; content: ""; }

.c_layer_item .layer_info_subject .dash_align { position: relative; padding-left: 8px; }

.c_layer_item .layer_info_subject .dash_align::before { position: absolute; left: 0; top: 50%; width: 4px; height: 1px; background-color: #666; content: ""; }

.c_layer_item .layer_info li { margin-top: 4px; }

.c_layer_item .layer_info li:first-child { margin-top: 0; }

.c_layer_item .layer_info .dash::before { display: inline-block; width: 4px; height: 1px; margin-right: 4px; background-color: #666; vertical-align: 5px; content: ""; }

.c_layer_item .layer_info .dash_align { position: relative; padding-left: 8px; }

.c_layer_item .layer_info .dash_align::before { position: absolute; left: 0; top: 50%; width: 4px; height: 1px; background-color: #666; content: ""; }

.c_layer_item .layer_close { position: absolute; right: 15px; top: 15px; width: 23px; height: 23px; overflow: hidden; line-height: 200px; }

.c_layer_item .layer_close:before, .c_layer_item .layer_close:after { position: absolute; top: 50%; left: 50%; width: 17px; height: 1px; background-color: #111; content: ""; }

.c_layer_item .layer_close:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.c_layer_item .layer_close:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.c_bi_amazon { display: inline-block; width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon.svg"); background-size: 80px 25px; background-size: 100% 100%; vertical-align: middle; overflow: hidden; line-height: 200px; }

.c_bi_amazon.c_bi_amazon_allwhite { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_allwhite.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_bi_amazon.c_bi_amazon_white { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_white.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_bi_amazon.c_bi_amazon_grey { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_black.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_icon_amazon { display: inline-block; width: 14px; height: 14px; background-image: url("/img/svg/logo/amazon_icon.svg"); background-size: 14px 14px; background-size: 100% 100%; vertical-align: middle; overflow: hidden; line-height: 200px; }

.c_icon_allclub { display: inline-block; width: 49px; height: 20px; background-image: url("/img/svg/logo/allclub.svg"); background-size: 49px 20px; background-size: 100% 100%; vertical-align: middle; overflow: hidden; line-height: 200px; }

.c-p-info__title { color: #111; font-size: inherit; font-weight: bold; }

.c-p-info__title:not(:first-child) { margin-top: 20px; }

.c-p-info__sentence { position: relative; margin-top: 4px; color: inherit; font-size: inherit; line-height: inherit; text-align: inherit; }

.c-p-info__sentence:first-of-type { margin-top: 10px; }

.c-p-info__sentence::before { display: none; position: absolute; top: 9px; left: 5px; width: 2px; height: 2px; border-radius: 50%; background-color: #666; content: ""; }

.c-p-info__sentence--bullet { padding-left: 13px; }

.c-p-info__sentence--bullet::before { display: block; }

.c-p-info__sentence--bullet-emphasis { padding-left: 13px; color: #ff0038; }

.c-p-info__sentence--bullet-emphasis::before { display: block; background-color: #ff0038; }

.c-p-info__sentence--bullet-highlight { padding-left: 13px; color: #0b83e6; }

.c-p-info__sentence--bullet-highlight::before { display: block; background-color: #0b83e6; }

.c-p-info__list { margin-top: 10px; color: inherit; font-size: inherit; line-height: inherit; }

.c-p-info__list:first-child { margin-top: 0; }

.c-p-info__list.c-p-info__list--bullet .c-p-info__list-item::before { background-color: #666; }

.c-p-info__list .c-p-info__list-item { margin-top: 4px; }

.c-p-info__list .c-p-info__list-item:first-child { margin-top: 0; }

.c-p-info__list-item { position: relative; color: inherit; font-size: inherit; line-height: inherit; }

.c-p-info__list-item--emphasis { color: #ff0038; }

.c-p-info__list-item--emphasis::before { background-color: #ff0038; }

.c-p-info__list-item--highlight { color: #0b83e6; }

.c-p-info__list-item--highlight::before { background-color: #0b83e6; }

.c-p-info__list--bullet .c-p-info__list-item { padding-left: 13px; }

.c-p-info__list--bullet .c-p-info__list-item::before { position: absolute; top: 9px; left: 5px; display: block; width: 2px; height: 2px; border-radius: 50%; content: ""; }

.c-p-info__list--bullet .c-p-info__list-item--emphasis { color: #ff0038; }

.c-p-info__list--bullet .c-p-info__list-item--emphasis::before { background-color: #ff0038; }

.c-p-info__list--bullet .c-p-info__list-item--highlight { color: #0b83e6; }

.c-p-info__list--bullet .c-p-info__list-item--highlight::before { background-color: #0b83e6; }

.c-p-info__text--emphasis { color: #ff0038; }

.c-p-info__text--highlight { color: #0b83e6; }

.c-p-info--center { text-align: center; }

.c-iconinfo { position: relative; display: inline-block; height: 14px; width: 14px; border: 0; padding: 0; background: transparent; text-indent: -9999px; vertical-align: middle; overflow: hidden; }

.c-iconinfo:after { position: absolute; content: ""; display: block; left: 0; top: 0; }

.c-iconinfo--help:after { width: 14px; height: 14px; background-image: url("/img/svg/logo/icon_help.svg"); background-size: 14px 14px; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.c-iconinfo--info:after { width: 14px; height: 14px; background-image: url("/img/svg/logo/icon_info.svg"); background-size: 14px 14px; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.c-n-flag { overflow: hidden; float: left; align-items: center; height: 18px; padding: 0 6px; margin-right: 4px; color: #333; font-size: 12px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: 700; line-height: 18px; background-color: #f5f6f8; white-space: nowrap; text-align: center; vertical-align: top; }

.c-n-flag--fill { color: #fff; padding: 0 7px; font-weight: 700; font-size: 12px; background-color: #0f0fd9; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-flag { position: relative; display: inline-block; vertical-align: middle; overflow: hidden; zoom: 1; height: 22px; }

.c-flag:after { display: block; content: ""; clear: both; }

.c-flag.c-flag--full { display: block; overflow: visible; }

.c-flag dt { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; font-size: 6px; line-height: 1; white-space: nowrap; }

.c-flag dd { float: left; background-color: #fff; }

.c-flag dd { margin-right: 2px; }

.c-flag dd:last-of-type { margin-right: 0; }

.c-flag.c-flag--full { height: 24px; }

.c-flag.c-flag--full dd { margin: 0 2px 2px 0; }

.c-flag.c-flag--full dd:last-of-type { margin-right: 0; }

.c-flag .c-flag__item { position: relative; display: block; height: 22px; padding: 2px 4px 1px; border: 0; font-size: 12px; line-height: 1.4; word-break: break-all; box-sizing: border-box; overflow: hidden; background-color: #fff; }

.c-flag .c-flag__item::after { position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; content: ""; }

.c-flag .c-flag__item { font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-flag .c-flag__item.c-flag__item--ff0038 { border: 1px solid transparent; border-color: rgba(255, 0, 56, 0.4); color: #ff0038; }

.c-flag .c-flag__item.c-flag__item--ff0038::after { background: rgba(255, 0, 56, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff0038 { color: #ff0038; }

.c-flag .c-flag__item.c-flag__item--f43142 { border: 1px solid transparent; border-color: rgba(244, 49, 66, 0.4); color: #f43142; }

.c-flag .c-flag__item.c-flag__item--f43142::after { background: rgba(244, 49, 66, 0.04); }

.c-flag .c-flag__item.c-flag__time--f43142 { color: #f43142; }

.c-flag .c-flag__item.c-flag__item--ff334c { border: 1px solid transparent; border-color: rgba(255, 51, 76, 0.4); color: #ff334c; }

.c-flag .c-flag__item.c-flag__item--ff334c::after { background: rgba(255, 51, 76, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff334c { color: #ff334c; }

.c-flag .c-flag__item.c-flag__item--f01b70 { border: 1px solid transparent; border-color: rgba(240, 27, 112, 0.4); color: #f01b70; }

.c-flag .c-flag__item.c-flag__item--f01b70::after { background: rgba(240, 27, 112, 0.04); }

.c-flag .c-flag__item.c-flag__time--f01b70 { color: #f01b70; }

.c-flag .c-flag__item.c-flag__item--f53fc6 { border: 1px solid transparent; border-color: rgba(245, 63, 198, 0.4); color: #f53fc6; }

.c-flag .c-flag__item.c-flag__item--f53fc6::after { background: rgba(245, 63, 198, 0.04); }

.c-flag .c-flag__item.c-flag__time--f53fc6 { color: #f53fc6; }

.c-flag .c-flag__item.c-flag__item--6423ff { border: 1px solid transparent; border-color: rgba(100, 35, 255, 0.4); color: #6423ff; }

.c-flag .c-flag__item.c-flag__item--6423ff::after { background: rgba(100, 35, 255, 0.04); }

.c-flag .c-flag__item.c-flag__time--6423ff { color: #6423ff; }

.c-flag .c-flag__item.c-flag__item--0b83e6 { border: 1px solid transparent; border-color: rgba(11, 131, 230, 0.4); color: #0b83e6; }

.c-flag .c-flag__item.c-flag__item--0b83e6::after { background: rgba(11, 131, 230, 0.04); }

.c-flag .c-flag__item.c-flag__time--0b83e6 { color: #0b83e6; }

.c-flag .c-flag__item.c-flag__item--00adf2 { border: 1px solid transparent; border-color: rgba(0, 173, 242, 0.4); color: #00adf2; }

.c-flag .c-flag__item.c-flag__item--00adf2::after { background: rgba(0, 173, 242, 0.04); }

.c-flag .c-flag__item.c-flag__time--00adf2 { color: #00adf2; }

.c-flag .c-flag__item.c-flag__item--249356 { border: 1px solid transparent; border-color: rgba(36, 147, 86, 0.4); color: #249356; }

.c-flag .c-flag__item.c-flag__item--249356::after { background: rgba(36, 147, 86, 0.04); }

.c-flag .c-flag__item.c-flag__time--249356 { color: #249356; }

.c-flag .c-flag__item.c-flag__item--ff8100 { border: 1px solid transparent; border-color: rgba(255, 129, 0, 0.4); color: #ff8100; }

.c-flag .c-flag__item.c-flag__item--ff8100::after { background: rgba(255, 129, 0, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff8100 { color: #ff8100; }

.c-flag .c-flag__item.c-flag__item--b9830b { border: 1px solid transparent; border-color: rgba(185, 131, 11, 0.4); color: #b9830b; }

.c-flag .c-flag__item.c-flag__item--b9830b::after { background: rgba(185, 131, 11, 0.04); }

.c-flag .c-flag__item.c-flag__time--b9830b { color: #b9830b; }

.c-flag .c-flag__item.c-flag__item--687581 { border: 1px solid transparent; border-color: rgba(104, 117, 129, 0.4); color: #687581; }

.c-flag .c-flag__item.c-flag__item--687581::after { background: rgba(104, 117, 129, 0.04); }

.c-flag .c-flag__item.c-flag__time--687581 { color: #687581; }

.c-flag .c-flag__item.c-flag__item--426bd1 { border: 1px solid transparent; border-color: rgba(66, 107, 209, 0.4); color: #426bd1; }

.c-flag .c-flag__item.c-flag__item--426bd1::after { background: rgba(66, 107, 209, 0.04); }

.c-flag .c-flag__item.c-flag__time--426bd1 { color: #426bd1; }

.c-flag .c-flag__item.c-flag__item--0097a5 { border: 1px solid transparent; border-color: rgba(0, 151, 165, 0.4); color: #0097a5; }

.c-flag .c-flag__item.c-flag__item--0097a5::after { background: rgba(0, 151, 165, 0.04); }

.c-flag .c-flag__item.c-flag__time--0097a5 { color: #0097a5; }

.c-flag .c-flag__item.c-flag__item--00ae6f { border: 1px solid transparent; border-color: rgba(0, 174, 111, 0.4); color: #00ae6f; }

.c-flag .c-flag__item.c-flag__item--00ae6f::after { background: rgba(0, 174, 111, 0.04); }

.c-flag .c-flag__item.c-flag__time--00ae6f { color: #00ae6f; }

.c-flag .c-flag__item.c-flag__item--3617ce { border: 1px solid transparent; border-color: rgba(54, 23, 206, 0.4); color: #3617ce; }

.c-flag .c-flag__item.c-flag__item--3617ce::after { background: rgba(54, 23, 206, 0.04); }

.c-flag .c-flag__item.c-flag__time--3617ce { color: #3617ce; }

.c-flag .c-flag__item.c-flag__item--000000 { border: 1px solid transparent; border-color: rgba(0, 0, 0, 0.4); color: #000000; }

.c-flag .c-flag__item.c-flag__item--000000::after { background: rgba(0, 0, 0, 0.04); }

.c-flag .c-flag__item.c-flag__time--000000 { color: #000000; }

.c-flag .c-flag__item.c-flag__item--0f0fd9 { border: 1px solid transparent; border-color: rgba(15, 15, 217, 0.4); color: #0f0fd9; }

.c-flag .c-flag__item.c-flag__item--0f0fd9::after { background: rgba(15, 15, 217, 0.04); }

.c-flag .c-flag__item.c-flag__time--0f0fd9 { color: #0f0fd9; }

.c-flag .c-flag__item.c-flag__item--5274ff { border: 1px solid transparent; border-color: rgba(82, 116, 255, 0.4); color: #5274ff; }

.c-flag .c-flag__item.c-flag__item--5274ff::after { background: rgba(82, 116, 255, 0.04); }

.c-flag .c-flag__item.c-flag__time--5274ff { color: #5274ff; }

.c-flag .c-flag__item.c-flag__item--ff7700 { border: 1px solid transparent; border-color: rgba(255, 119, 0, 0.4); color: #ff7700; }

.c-flag .c-flag__item.c-flag__item--ff7700::after { background: rgba(255, 119, 0, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff7700 { color: #ff7700; }

.c-flag .c-flag__item > strong { line-height: 1.45; }

.c-flag .c-flag__time { margin-top: 1px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 21px; }

.c-flag .c-flag__time.c-flag__item--ff0038 { border: 1px solid transparent; border-color: rgba(255, 0, 56, 0.4); color: #ff0038; }

.c-flag .c-flag__time.c-flag__item--ff0038::after { background: rgba(255, 0, 56, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff0038 { color: #ff0038; }

.c-flag .c-flag__time.c-flag__item--f43142 { border: 1px solid transparent; border-color: rgba(244, 49, 66, 0.4); color: #f43142; }

.c-flag .c-flag__time.c-flag__item--f43142::after { background: rgba(244, 49, 66, 0.04); }

.c-flag .c-flag__time.c-flag__time--f43142 { color: #f43142; }

.c-flag .c-flag__time.c-flag__item--ff334c { border: 1px solid transparent; border-color: rgba(255, 51, 76, 0.4); color: #ff334c; }

.c-flag .c-flag__time.c-flag__item--ff334c::after { background: rgba(255, 51, 76, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff334c { color: #ff334c; }

.c-flag .c-flag__time.c-flag__item--f01b70 { border: 1px solid transparent; border-color: rgba(240, 27, 112, 0.4); color: #f01b70; }

.c-flag .c-flag__time.c-flag__item--f01b70::after { background: rgba(240, 27, 112, 0.04); }

.c-flag .c-flag__time.c-flag__time--f01b70 { color: #f01b70; }

.c-flag .c-flag__time.c-flag__item--f53fc6 { border: 1px solid transparent; border-color: rgba(245, 63, 198, 0.4); color: #f53fc6; }

.c-flag .c-flag__time.c-flag__item--f53fc6::after { background: rgba(245, 63, 198, 0.04); }

.c-flag .c-flag__time.c-flag__time--f53fc6 { color: #f53fc6; }

.c-flag .c-flag__time.c-flag__item--6423ff { border: 1px solid transparent; border-color: rgba(100, 35, 255, 0.4); color: #6423ff; }

.c-flag .c-flag__time.c-flag__item--6423ff::after { background: rgba(100, 35, 255, 0.04); }

.c-flag .c-flag__time.c-flag__time--6423ff { color: #6423ff; }

.c-flag .c-flag__time.c-flag__item--0b83e6 { border: 1px solid transparent; border-color: rgba(11, 131, 230, 0.4); color: #0b83e6; }

.c-flag .c-flag__time.c-flag__item--0b83e6::after { background: rgba(11, 131, 230, 0.04); }

.c-flag .c-flag__time.c-flag__time--0b83e6 { color: #0b83e6; }

.c-flag .c-flag__time.c-flag__item--00adf2 { border: 1px solid transparent; border-color: rgba(0, 173, 242, 0.4); color: #00adf2; }

.c-flag .c-flag__time.c-flag__item--00adf2::after { background: rgba(0, 173, 242, 0.04); }

.c-flag .c-flag__time.c-flag__time--00adf2 { color: #00adf2; }

.c-flag .c-flag__time.c-flag__item--249356 { border: 1px solid transparent; border-color: rgba(36, 147, 86, 0.4); color: #249356; }

.c-flag .c-flag__time.c-flag__item--249356::after { background: rgba(36, 147, 86, 0.04); }

.c-flag .c-flag__time.c-flag__time--249356 { color: #249356; }

.c-flag .c-flag__time.c-flag__item--ff8100 { border: 1px solid transparent; border-color: rgba(255, 129, 0, 0.4); color: #ff8100; }

.c-flag .c-flag__time.c-flag__item--ff8100::after { background: rgba(255, 129, 0, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff8100 { color: #ff8100; }

.c-flag .c-flag__time.c-flag__item--b9830b { border: 1px solid transparent; border-color: rgba(185, 131, 11, 0.4); color: #b9830b; }

.c-flag .c-flag__time.c-flag__item--b9830b::after { background: rgba(185, 131, 11, 0.04); }

.c-flag .c-flag__time.c-flag__time--b9830b { color: #b9830b; }

.c-flag .c-flag__time.c-flag__item--687581 { border: 1px solid transparent; border-color: rgba(104, 117, 129, 0.4); color: #687581; }

.c-flag .c-flag__time.c-flag__item--687581::after { background: rgba(104, 117, 129, 0.04); }

.c-flag .c-flag__time.c-flag__time--687581 { color: #687581; }

.c-flag .c-flag__time.c-flag__item--426bd1 { border: 1px solid transparent; border-color: rgba(66, 107, 209, 0.4); color: #426bd1; }

.c-flag .c-flag__time.c-flag__item--426bd1::after { background: rgba(66, 107, 209, 0.04); }

.c-flag .c-flag__time.c-flag__time--426bd1 { color: #426bd1; }

.c-flag .c-flag__time.c-flag__item--0097a5 { border: 1px solid transparent; border-color: rgba(0, 151, 165, 0.4); color: #0097a5; }

.c-flag .c-flag__time.c-flag__item--0097a5::after { background: rgba(0, 151, 165, 0.04); }

.c-flag .c-flag__time.c-flag__time--0097a5 { color: #0097a5; }

.c-flag .c-flag__time.c-flag__item--00ae6f { border: 1px solid transparent; border-color: rgba(0, 174, 111, 0.4); color: #00ae6f; }

.c-flag .c-flag__time.c-flag__item--00ae6f::after { background: rgba(0, 174, 111, 0.04); }

.c-flag .c-flag__time.c-flag__time--00ae6f { color: #00ae6f; }

.c-flag .c-flag__time.c-flag__item--3617ce { border: 1px solid transparent; border-color: rgba(54, 23, 206, 0.4); color: #3617ce; }

.c-flag .c-flag__time.c-flag__item--3617ce::after { background: rgba(54, 23, 206, 0.04); }

.c-flag .c-flag__time.c-flag__time--3617ce { color: #3617ce; }

.c-flag .c-flag__time.c-flag__item--000000 { border: 1px solid transparent; border-color: rgba(0, 0, 0, 0.4); color: #000000; }

.c-flag .c-flag__time.c-flag__item--000000::after { background: rgba(0, 0, 0, 0.04); }

.c-flag .c-flag__time.c-flag__time--000000 { color: #000000; }

.c-flag .c-flag__time.c-flag__item--0f0fd9 { border: 1px solid transparent; border-color: rgba(15, 15, 217, 0.4); color: #0f0fd9; }

.c-flag .c-flag__time.c-flag__item--0f0fd9::after { background: rgba(15, 15, 217, 0.04); }

.c-flag .c-flag__time.c-flag__time--0f0fd9 { color: #0f0fd9; }

.c-flag .c-flag__time.c-flag__item--5274ff { border: 1px solid transparent; border-color: rgba(82, 116, 255, 0.4); color: #5274ff; }

.c-flag .c-flag__time.c-flag__item--5274ff::after { background: rgba(82, 116, 255, 0.04); }

.c-flag .c-flag__time.c-flag__time--5274ff { color: #5274ff; }

.c-flag .c-flag__time.c-flag__item--ff7700 { border: 1px solid transparent; border-color: rgba(255, 119, 0, 0.4); color: #ff7700; }

.c-flag .c-flag__time.c-flag__item--ff7700::after { background: rgba(255, 119, 0, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff7700 { color: #ff7700; }

.c-flag .c-flag__item--11festa { color: #fff; border: 1px solid transparent; border-color: #f44654; background-color: #f44654; }

.c-flag .c-flag__item--choice { color: #fff; border: 1px solid transparent; border-color: #232f3e; background: #232f3e; }

.c-flag .c-flag__item--gradient-red { color: #fff; border: 0; background: linear-gradient(114.84deg, #ff465d 31.64%, #ff00ef 100%); line-height: 16px; }

.c-flag .c-flag__item--ooah { position: relative; width: 37px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ooah::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 37px; height: 12.33333px; background-position: -203.01266px -171.28205px; background-size: 405px 400px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--freshtable { position: relative; width: 51px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--freshtable::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 51px; height: 11.76923px; background-position: -325.27595px -97.94872px; background-size: 387px 382px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--shockingdeal { position: relative; width: 44px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--shockingdeal::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 44px; height: 18px; background-position: -186px -215px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--timedeal { position: relative; width: 57px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--timedeal::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 57px; height: 14px; background-position: -332px -57px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--ootd { position: relative; width: 46px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ootd::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 47px; height: 13px; background-position: -244px -222px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--mart-plus { position: relative; width: 60px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--mart-plus::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 60px; height: 12px; background-position: -182.84557px -2.99487px; background-size: 296px 292px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--ssg-emart { position: relative; width: 66px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ssg-emart::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 66px; height: 20px; background-position: -244px -154px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--ssg-dawn { position: relative; width: 66px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ssg-dawn::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 66px; height: 20px; background-position: -244px -126px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag-box { overflow: hidden; max-height: 49px; zoom: 1; }

.c-flag-box:after { display: block; content: ""; clear: both; }

.c-flag-box dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-flag-box dd { display: inline; }

.c-flag-box .c-time { margin: 0 4px 4px 0; }

.c-flag-box .c-flag { overflow: initial; height: auto; }

.c-flag-box .c-flag .c-flag__item { float: left; margin: 0 4px 4px 0; }

.c-flag-box .c-flag .c-flag__item:last-child { margin-right: 0; }

.c-flag-box .c-flag .c-flag__time { float: left; margin: 1px 4px 4px 1px; }

.c-flag-box .c-flag .c-flag__time:last-child { margin-right: 0; }

.c-nation { margin-bottom: 10px; }

.c-nation dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-nation dd { min-height: 18px; }

.c-nation .amazon { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 54px; height: 16.39286px; background-position: -1.92405px -1.92821px; background-size: 190px 188px; display: inline-block; overflow: hidden; margin: 3px 0px -6px 0; color: transparent; }

.c-nation .nation { position: relative; display: inline-block; padding-left: 9px; font-size: 13px; color: #333; }

.c-nation .nation:before { position: absolute; top: 50%; left: 4px; margin-top: -1px; width: 2px; height: 2px; background: #ddd; border-radius: 100%; content: ""; }

.c-time-flag { display: inline-block; vertical-align: middle; }

.c-time { float: left; height: 22px; min-width: 73px; padding: 3px 5px 2px; margin-right: 2px; border-color: #49a3c7; background-color: #49a3c7; font-size: 12px; color: #fff; line-height: 1.3; white-space: nowrap; box-sizing: border-box; }

.c-time [role="timer"] { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-time:before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -30px -83px; width: 12px; height: 12px; background-size: 112px 101px; display: inline-block; margin: -2px 3px 0 0; vertical-align: middle; content: ""; }

.c-time--gradient-purple { color: #fff; border: 0; background: linear-gradient(90deg, rgba(120, 14, 255, 0.85) 0%, rgba(44, 89, 255, 0.85) 100%); line-height: 18px; }

.c-starrate { zoom: 1; }

.c-starrate:after { display: block; content: ""; clear: both; }

.c-starrate dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-starrate dd:first-of-type .c-starrate__review .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; color: #666; }

.c-starrate .c-review-event + dd.c-starrate__review .sr-only, .c-starrate dd.c-starrate__review:first-of-type .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; color: #666; }

.c-starrate.c-starrate--amazon dt { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 14px; height: 14px; background-position: -154px -243px; background-size: 395px 390px; overflow: hidden; float: left; margin: 2px 4px 0 0; color: transparent; }

.c-starrate.c-starrate--simply.c-starrate--amazon dt { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 12px; height: 12px; background-position: -131.77722px -208.10769px; background-size: 338px 334px; overflow: hidden; float: left; margin: 3px 2px 0 0; color: transparent; }

.c-starrate.c-starrate--simply .c-starrate__sati { display: flex; gap: 2px; align-items: center; width: auto; height: 18px; margin: 0; background: none; }

.c-starrate.c-starrate--simply .c-starrate__review { margin: 0 0 0 2px; color: #949494; font-size: 13px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 18px; }

.c-starrate.c-starrate--simply .c-starrate__review .value { font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-starrate.c-starrate--simply .c-starrate__review a { color: #949494; font-size: 13px; line-height: 18px; }

.c-starrate.c-starrate--simply .c-qty { display: flex; line-height: 17px; }

.c-starrate.c-starrate--simply .c-qty:not(:first-of-type)::before { display: block; width: 2px; height: 2px; border-radius: 50%; margin: 8px 4px; background-color: #ddd; content: ""; }

.c-starrate.c-starrate--simply .c-qty__value { color: #949494; font-size: 13px; }

.c-starrate__sati { position: relative; float: left; overflow: hidden; margin: 2px 4px 0 0; color: transparent; font-size: 10px; width: 64px; height: 12px; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 64px; height: 12px; background-position: -244px -182px; background-size: 395px 390px; }

.c-starrate__sati .c-starrate__gauge { position: absolute; top: 0; left: 0; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 64px; height: 12px; background-position: -244px -202px; background-size: 395px 390px; }

.c-starrate__sati .c-starrate__title { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 12px; height: 12px; background-position: -106px -195px; background-size: 395px 390px; }

.c-starrate__sati .c-starrate__text { color: #111; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; font-weight: 400; line-height: 18px; }

.c-starrate__review { float: left; color: #666; font-size: 12px; letter-spacing: 0; }

.c-starrate__review .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-addition { position: relative; font-size: 12px; line-height: 16px; color: #666; }

.c-addition + .c-addition { margin-top: -1px; }

.c-addition > div, .c-addition > ul, .c-addition > dl, .c-addition > a, .c-addition .c-addition-link { display: block; position: relative; height: 20px; }

.c-addition .c-addition-coupon { overflow: hidden; position: relative; height: 20px; }

.c-addition .c-addition-coupon:not(:first-of-type) { margin-left: 12px; }

.c-addition .c-addition-coupon:nth-child(1):nth-last-child(1) .c-addition-coupon__text { max-width: 75%; }

.c-addition .c-addition-coupon .c-addition-coupon__rate { position: relative; display: inline-block; height: 20px; padding: 0 13px 0 5px; vertical-align: top; font-size: 11px; line-height: 20px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #111; }

.c-addition .c-addition-coupon .c-addition-coupon__rate em { display: inline-block; white-space: nowrap; vertical-align: top; font-weight: 700; }

.c-addition .c-addition-coupon .c-addition-coupon__rate em:before, .c-addition .c-addition-coupon .c-addition-coupon__rate em:after { height: 3px; width: 4px; position: absolute; z-index: 1; right: 5px; content: ""; }

.c-addition .c-addition-coupon .c-addition-coupon__rate em:before { top: 0; background-image: url("/img/eui/sprites/sp_card_item_2x_2022107_112716.png"); background-position: -11px 0px; width: 6px; height: 3px; background-size: 17px 3px; }

.c-addition .c-addition-coupon .c-addition-coupon__rate em:after { bottom: 0; background-image: url("/img/eui/sprites/sp_card_item_2x_2022107_112716.png"); background-position: 0px 0px; width: 6px; height: 3px; background-size: 17px 3px; }

.c-addition .c-addition-coupon .c-addition-coupon__rate:before { height: 20px; width: 8px; position: absolute; right: 0; top: 0; background: linear-gradient(#ff5a2e, #ff0038 52%, #ff00ef); content: ""; }

.c-addition .c-addition-coupon .c-addition-coupon__rate:after { position: absolute; left: 0; right: 0; top: 0; bottom: 0; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 2px; content: ""; }

.c-addition .c-addition-coupon .c-addition-coupon__text { position: relative; display: inline-block; margin: 0 0 0 4px; padding: 0 10px 0 0; line-height: 20px; font-size: 13px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 23%; }

.c-addition .c-addition-coupon .c-addition-coupon__text:after { position: absolute; top: 50%; right: 1px; width: 5px; height: 5px; margin-top: -3px; border: solid #666; border-width: 1px 1px 0 0; transform: rotate(45deg); content: ""; }

.c-addition .c-addition-coupon:hover { text-decoration: none; }

.c-card-item { position: relative; background: #fff; line-height: 1.5; letter-spacing: 0; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item > a:hover { text-decoration: none; }

.c-card-item .c-nation { margin-right: 6px; margin-bottom: 8px; }

.c-card-item .c-nation .amazon { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 60px; height: 18.21429px; background-position: -2.13671px -2.13333px; background-size: 211px 208px; margin-top: 6px; }

.c-card-item .c-card-item__price-info .c-card-item__price del { font-size: 13px; line-height: 22px; color: #999; }

.c-card-item .c-card-item__price-info .c-card-item__price del .value { font-size: 16px; }

.c-card-item--list { display: table; width: 100%; }

.c-card-item--list .c-card-item__thumb { display: table-cell; vertical-align: top; }

.c-card-item--list .c-card-item__info { display: table-cell; }

.c-card-item--list .c-review-event { float: left; }

.c-card-item--list .c-starrate__review { margin: -1px 0 0 2px; color: #666; }

.c-card-item--list .c-starrate__review:first-of-type { margin: -1px 0 0 0; }

.c-card-item.c-card-item--deal { box-sizing: border-box; overflow: hidden; width: 394px; border-radius: 4px; }

.c-card-item.c-card-item--deal .c-card-item__thumb-img { display: inline-block; width: 100%; height: 197px; border-top-left-radius: 4px; border-top-right-radius: 4px; }

.c-card-item.c-card-item--deal .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c-card-item.c-card-item--deal .c-card-item__thumb-emblem { top: 8px; left: 12px; }

.c-card-item.c-card-item--deal .c-card-item__thumb-emblem img { width: 80px; height: 80px; }

.c-card-item.c-card-item--deal .c-card-item__info { position: relative; min-height: 214px; padding: 12px 0 13px 20px; box-sizing: border-box; }

.c-card-item.c-card-item--deal .c-card-item__info .c-nation { float: left; }

.c-card-item.c-card-item--deal .c-card-item__info .c-time-flag { float: left; margin: 0 0 10px 0; }

.c-card-item.c-card-item--deal .c-card-item__info .c-flag { float: left; margin-bottom: 8px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__name { clear: both; height: 1.5em; margin: 0 20px 27px 0; overflow: hidden; font-size: 15px; color: #111; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__name-addition { margin-top: -26px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__counsel { margin: -5px 0 0 20px; color: #0b83e6; font-size: 20px; font-weight: normal; }

.c-card-item.c-card-item--deal .c-card-item__info .c-starrate { position: relative; margin: 13px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__info .c-starrate dd { float: left; }

.c-card-item.c-card-item--deal .c-card-item__info .c-starrate .c-starrate__review { margin: -1px 0 0 2px; color: #666; }

.c-card-item.c-card-item--deal .c-card-item__info .c-starrate .c-starrate__review .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__benefit { position: relative; margin: 5px 0 0 0; height: 18px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__benefit .benefit { padding: 0 0 0 12px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__benefit .benefit:before { position: absolute; left: 6px; top: 9px; width: 2px; height: 2px; border-radius: 50%; background: #ccc; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__benefit dt + .benefit { padding-left: 0; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__benefit dt + .benefit::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__deal-guide { color: #666; }

.c-card-item.c-card-item--deal .c-card-item__info .card_addition { height: 53px; margin: 0 21px; padding: 15px 0 0 0; border-top: 1px solid #f4f4f4; }

.c-card-item.c-card-item--deal .c-card-item__info .card_addition .c_prd_coupon { margin-left: 12px; }

.c-card-item.c-card-item--deal .c-card-item__info .card_addition .c-card-item__deal { padding: 0; border: none; }

.c-card-item.c-card-item--deal .c-card-item__info .card_addition .c-card-item__deal:after { display: none; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info { zoom: 1; padding: 0; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info:after { display: block; content: ""; clear: both; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__rate { font-size: 16px; line-height: 22px; margin-right: 8px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__rate .value { display: inline-block; margin: 0 0 0 0; font-size: 24px; line-height: 24px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__rate .c-card-item__special { display: inline-block; margin: 2px 0 0 0; font-size: 18px; line-height: 22px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__price { float: left; font-size: 15px; line-height: 22px; margin-right: 8px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__price .value { display: inline-block; margin: 0 0 1px 0; font-size: 24px; line-height: 24px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__price del { font-size: 13px; line-height: 22px; color: #999; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__price del .value { font-size: 16px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__price .c-card-item__detail { margin-top: 11px; }

.c-card-item.c-card-item--deal .c-card-item__info .c-card-item__price-info .c-card-item__price-del { float: left; margin: 2px 0 0 0; color: #949494; font-size: 16px; line-height: 22px; }

.c-card-item.c-card-item--deal .c-card-item__delivery { zoom: 1; clear: both; padding: 6px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__delivery:after { display: block; content: ""; clear: both; }

.c-card-item.c-card-item--deal .c-card-item__delivery dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-card-item.c-card-item--deal .c-card-item__delivery dd { position: relative; float: left; line-height: 20px; }

.c-card-item.c-card-item--deal .c-card-item__delivery dt + dd, .c-card-item.c-card-item--deal .c-card-item__delivery dt + .delivery { padding-left: 0; }

.c-card-item.c-card-item--deal .c-card-item__delivery dt + dd::before, .c-card-item.c-card-item--deal .c-card-item__delivery dt + .delivery::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__delivery .delivery { padding: 0 0 0 14px; }

.c-card-item.c-card-item--deal .c-card-item__delivery .delivery::before { position: absolute; left: 2px; top: 9px; }

.c-card-item.c-card-item--deal .c-card-item__delivery .allclub { font-size: 13px; color: #666; }

.c-card-item.c-card-item--deal .c-card-item__delivery .allclub:before { width: 40px; height: 16px; background-image: url("/img/svg/logo/allclub.svg"); background-size: 40px 16px; display: inline-block; margin: -2px 0px 1px 0; vertical-align: middle; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__delivery .sktpass { font-size: 13px; color: #666; line-height: 20px; }

.c-card-item.c-card-item--deal .c-card-item__delivery .sktpass:before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 56px; height: 16px; background-position: -195.2px -22.4px; background-size: 316px 312px; display: block; float: left; margin: 3px 4px 0 0; line-height: 20px; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__delivery .sktpass + .delivery { clear: none; }

.c-card-item.c-card-item--deal .c-card-item__delivery .sktpass + .delivery::before { display: block; position: absolute; left: 2px; top: 9px; }

.c-card-item.c-card-item--deal .c-card-item__delivery .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery, .c-card-item.c-card-item--deal .c-card-item__extra .c-card-item__delivery { padding-top: 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-relates, .c-card-item.c-card-item--deal .c-card-item__extra .c-relates { height: 50px; padding: 15px 0; box-sizing: border-box; border-top: none; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-relates__button-link, .c-card-item.c-card-item--deal .c-card-item__extra .c-relates__button-link { padding: 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__relates, .c-card-item.c-card-item--deal .c-card-item__extra .c-card-item__relates { height: 50px; padding: 15px 0; box-sizing: border-box; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__text, .c-card-item.c-card-item--deal .c-card-item__extra .c-card-item__text { height: 50px; padding: 15px 0; line-height: 20px; box-sizing: border-box; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__deal, .c-card-item.c-card-item--deal .c-card-item__extra .c-card-item__deal { height: 50px; padding: 15px 0; box-sizing: border-box; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info { position: relative; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery { position: relative; height: 50px; padding: 15px 0 15px; line-height: 20px; box-sizing: border-box; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-delivery { float: left; margin: 5px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-delivery::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 13.2px; background-position: -67.02278px -48.28718px; background-size: 217px 214px; margin-top: -1px; display: block; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-delivery + .delivery { padding-left: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-delivery + .delivery em { margin-right: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-delivery + .delivery::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-install { float: left; margin: 5px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-install::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 53px; height: 12.92683px; background-position: -61.97468px -138.92308px; background-size: 255px 252px; margin-top: -1px; display: block; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-install + .delivery { padding-left: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-install + .delivery em { margin-right: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-install + .delivery::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-plus { float: left; margin: 5px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-plus::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 85px; height: 15px; background-position: -122px -142px; background-size: 395px 390px; margin-top: -1px; display: block; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-plus + .delivery { padding-left: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-plus + .delivery em { margin-right: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-plus + .delivery::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-fresh { float: left; margin: 5px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-fresh::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 13px; background-position: -332px -79px; background-size: 395px 390px; margin-top: -1px; display: block; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-fresh + .delivery { padding-left: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-fresh + .delivery em { margin-right: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-fresh + .delivery::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-seller { float: left; margin: 5px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-seller::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 12.5px; background-position: -203.23038px -85.56923px; background-size: 329px 324px; margin-top: -1px; display: block; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-seller + .delivery { padding-left: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-seller + .delivery em { margin-right: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-seller + .delivery::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-freeze { float: left; margin: 5px 0 0 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-freeze::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 13.09524px; background-position: -2.61266px -140.57692px; background-size: 258px 255px; margin-top: -1px; display: block; content: ""; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-freeze + .delivery { padding-left: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-freeze + .delivery em { margin-right: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .shooting-freeze + .delivery::before { display: none; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .ssg-emart { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .ssg-emart strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 49px; height: 13px; background-position: -332px -199px; background-size: 395px 390px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .ssg-dawn { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .ssg-dawn strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 49px; height: 13px; background-position: -332px -178px; background-size: 395px 390px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .freshtable { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__delivery .freshtable strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 12.69231px; background-position: -350.49114px -105.64103px; background-size: 417px 412px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info .c-card-item__qty { bottom: 15px; right: 0; }

.c-card-item.c-card-item--deal .c-card-item__bottom-info:not(:first-child) { border-top: 1px solid #f4f4f4; }

.c-card-item.c-card-item--collection { box-sizing: border-box; width: 224px; height: 100%; border-radius: 4px; }

.c-card-item.c-card-item--collection.c-card-item--space { padding-bottom: 52px; }

.c-card-item.c-card-item--collection.c-card-item--space .c-card-item__info .c-starrate { margin: 0; }

.c-card-item.c-card-item--collection.c-card-item--space .c-relates { position: absolute; left: 20px; right: 20px; bottom: 0; }

.c-card-item.c-card-item--collection .c-card-item__thumb { padding: 10px 10px 0 10px; }

.c-card-item.c-card-item--collection .c-card-item__thumb-img { width: 204px; height: 204px; }

.c-card-item.c-card-item--collection .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c-card-item.c-card-item--collection .c-card-item__info { zoom: 1; padding: 17px 0 17px 20px; }

.c-card-item.c-card-item--collection .c-card-item__info:after { display: block; content: ""; clear: both; }

.c-card-item.c-card-item--collection .c-card-item__info .c-flag { margin: 0 0 8px 0; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__name { max-height: 3em; padding: 0 20px 0 0; margin: 0 0 8px 0; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__price-info { zoom: 1; margin: 0 0 11px 0; padding: 0; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__price-info:after { display: block; content: ""; clear: both; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__price-info .c-card-item__rate { margin-right: 8px; font-size: 16px; line-height: 22px; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__price-info .c-card-item__rate > .value { display: inline-block; margin: 0 0 2px 0; font-size: 22px; line-height: 22px; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__price-info .c-card-item__price > .value { font-size: 22px; font-weight: bold; line-height: 22px; }

.c-card-item.c-card-item--collection .c-card-item__info .c-starrate { margin: 0 0 4px 0; line-height: 18px; }

.c-card-item.c-card-item--collection .c-card-item__info .c-starrate dd { float: left; }

.c-card-item.c-card-item--collection .c-card-item__info .c-starrate .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__counsel { display: block; margin: 0 0 12px 0; font-size: 13px; color: #666; font-weight: normal; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__counsel em { display: block; font-size: 16px; margin: 0 0 2px 0; line-height: 22px; color: #0b83e6; }

.c-card-item.c-card-item--collection .c-card-item__info .c-starrate__review { margin: -1px 0 0 2px; color: #666; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__delivery { position: relative; }

.c-card-item.c-card-item--collection .c-card-item__info .c-card-item__delivery .sktpass:before { float: left; }

.c-card-item.c-card-item--collection .c-card-item__save { display: block; width: 100%; height: 52px; line-height: 52px; text-align: center; font-size: 17px; color: #ff0038; }

.c-card-item.c-card-item--collection .c-card-item__save:before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -33px -50px; width: 24px; height: 24px; background-size: 112px 101px; display: inline-block; margin: 12px 6px 0 0; vertical-align: top; overflow: hidden; line-height: 200px; content: ""; }

.c-card-item.c-card-item--collection .c-card-item__addition .c-cart-button { position: absolute; bottom: 0; clear: both; border-top: 1px solid #eee; }

.c-card-item.c-card-item--gallery { box-sizing: border-box; height: 100%; padding: 10px 10px 20px 10px; border-radius: 4px; box-sizing: border-box; }

.c-card-item.c-card-item--gallery.c-card-item--space { padding-bottom: 52px; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__info .c-card-item__name { margin-bottom: 11px; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__addition { position: absolute; left: 20px; right: 20px; bottom: 0px; margin: 0; padding: 15px 0; border-top: 1px solid #f4f4f4; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__addition dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__addition .c-card-item__deal { padding: 0; border: 0; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__addition .c-card-item__deal::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -62px -50px; width: 22px; height: 22px; background-size: 112px 101px; margin-right: 3px; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__addition .c-card-item__deal::after { display: none; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-relates { position: absolute; left: 20px; right: 20px; bottom: 0; }

.c-card-item.c-card-item--gallery .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c-card-item.c-card-item--gallery .c-card-item__swatch { margin: 20px 0 -8px 0; }

.c-card-item.c-card-item--gallery .c-card-item__info { zoom: 1; padding: 16px 0 0 10px; }

.c-card-item.c-card-item--gallery .c-card-item__info:after { display: block; content: ""; clear: both; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-time-flag { float: left; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-flag { float: left; margin: 0 0 8px 0; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-flag-box { margin-bottom: 8px; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-flag-box .c-flag { margin-bottom: 0; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__name { clear: both; max-height: 3em; padding: 0 10px 0 0; margin: 0 0 7px 0; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate { margin: 0 0 2px 0; line-height: 18px; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate dd:first-of-type .c-starrate__review .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate dd.c-starrate__review:nth-of-type(1) { margin-left: 0; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate dd.c-starrate__review:nth-of-type(1) .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; color: #666; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate .c-starrate__review { margin: -1px 0 0 2px; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate .c-review-event ~ .c-starrate__sati { margin-top: 3px; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate .c-review-event ~ .c-starrate__review { margin-top: 0; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate--simply .c-starrate__review { margin: 0 0 0 2px; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-starrate--simply .c-review-event ~ .c-starrate__review { margin-top: 2px; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__counsel { display: block; margin: 0 0 12px 0; font-size: 13px; color: #666; font-weight: normal; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__counsel em { display: block; font-size: 16px; margin: 0 0 2px 0; line-height: 22px; color: #0b83e6; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__benefit .benefit { float: none; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__delivery .delivery::before { display: none; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__delivery .sktpass { zoom: 1; margin: 0 0 2px 0; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__delivery .sktpass:after { display: block; content: ""; clear: both; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__delivery .sktpass::before { float: left; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__delivery [class^="ssg-"] { clear: none; float: left; }

.c-card-item.c-card-item--gallery .c-card-item__info .c-card-item__delivery [class^="ssg-"] + [class^="ssg-"] { margin-left: 2px; }

.c-card-item.c-card-item--gallery .c-card-item__price-info { zoom: 1; margin: 0 0 12px 0; padding: 0; }

.c-card-item.c-card-item--gallery .c-card-item__price-info:after { display: block; content: ""; clear: both; }

.c-card-item.c-card-item--gallery .c-card-item__lowest { margin-bottom: 0; }

.c-card-item.c-card-item--gallery .c-card-item__rate { margin-right: 8px; font-size: 14px; line-height: 24px; }

.c-card-item.c-card-item--gallery .c-card-item__rate .value { display: inline-block; margin: 0; font-size: 14px; line-height: 24px; vertical-align: top; }

.c-card-item.c-card-item--gallery .c-card-item__price > .value { font-size: 22px; line-height: 22px; }

.c-card-item.c-card-item--gallery .c-card-item__price-del { float: left; color: #949494; font-size: 13px; line-height: 22px; }

.c-card-item.c-card-item--gallery .c-card-item__price-del del { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 14px; }

.c-card-item.c-card-item--gallery .c-card-item__special { font-size: 13px; }

.c-card-item.c-card-item--gallery .c-card-item__price { clear: both; display: block; margin: 0 0 1px 0; font-size: 15px; line-height: 22px; }

.c-card-item.c-card-item--gallery .c-card-item__price del { font-size: 13px; line-height: 22px; color: #999; }

.c-card-item.c-card-item--gallery .c-card-item__price del .value { font-size: 16px; }

.c-card-item.c-card-item--gallery .c-card-item__addition { margin: 7px 0 0 10px; }

.c-card-item.c-card-item--gallery .c-card-item__addition .c-cart-button { clear: both; border-top: 1px solid #eee; }

.c-card-item.c-card-item--gallery .c-card-item__deal { position: relative; height: 30px; padding: 4px 20px 0 6px; box-sizing: border-box; color: #333; }

.c-card-item.c-card-item--gallery .c-card-item__deal::before { margin: -1px 2px 1px 0; }

.c-card-item.c-card-item--gallery .c-card-item__deal::after { position: absolute; right: 9px; top: 10px; margin: 0; }

.c-card-item.c-card-item--box { box-sizing: border-box; border-radius: 4px; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); }

.c-card-item.c-card-item--box:hover { box-shadow: 0px 6px 32px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.7); }

.c-card-item.c-card-item--box:hover .c-card-item__thumb-img img { transform: scale(1.08); }

.c-card-item.c-card-item--zzim .c-card-item__info .c-flag, .c-card-item.c-card-item--zzim .c-card-item__info .c-card-item__mart, .c-card-item.c-card-item--zzim .c-card-item__info .c-card-item__name { margin-right: 35px; }

.c-card-item.c-card-item--zzim .c-card-item__button { position: absolute; top: 0; right: 0; left: 0; margin-top: 100%; }

.c-card-item.c-card-item--zzim .c-card-item__button .c-like-button { left: auto; top: 0; right: 20px; z-index: 5; margin-top: 5px; }

.c-card-item.c-card-item--soldout .c-card-item__info .c-card-item__counsel em { color: #666; }

.c-card-item .c-flag-box + .c-card-item__brand { margin-top: 2px; }

.c-card-item-badge { position: absolute; left: 0; top: 0; font-size: 0; z-index: 2; }

.c-card-item-badge__status { display: inline-block; padding: 3px 8px; background-color: #666; font-weight: normal; font-size: 13px; line-height: 18px; color: #fff; letter-spacing: 0; vertical-align: top; }

.c-card-item-badge__status--active { background-color: #ff0038; }

.c-card-item-badge__time { display: inline-block; padding: 3px 8px; background-color: #fafafa; font-weight: bold; font-size: 14px; line-height: 18px; color: #333; letter-spacing: 1px; vertical-align: top; }

.c-card-item__badge-text { position: absolute; left: 0; top: 0; z-index: 2; }

.c-card-item__brand { zoom: 1; margin: 6px 0 2px; color: #111; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 14px; font-weight: 900; line-height: 17px; }

.c-card-item__brand:after { display: block; content: ""; clear: both; }

.c-card-item__brand dd { zoom: 1; }

.c-card-item__brand dd:after { display: block; content: ""; clear: both; }

.c-card-item__brand:first-of-type { margin-top: 0; }

.c-card-item__brand-flag { margin-left: 4px; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 41px; height: 17px; background-position: -139px -267px; background-size: 395px 390px; overflow: hidden; line-height: 200px; }

.c-card-item__brand-flag:first-child { margin: 0 4px 0 0; }

.c-card-item__brand-name { display: inline-block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.c-card-item__floting { position: absolute; right: 0; bottom: 0; }

.c-card-item__recommend { height: 40px; padding: 7px 10px; border-radius: 4px; border: solid 1px #ccc; background-color: #fff; color: #111; font-size: 15px; }

.c-card-item__recommend::after { width: 6px; height: 6px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(135deg); display: inline-block; margin: -7px 0 0 4px; content: ""; }

.c-card-item__recommend[aria-expanded="true"]::after { width: 6px; height: 6px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(-45deg); display: inline-block; margin: 0px 0 0 4px; content: ""; }

.c-card-item__option { zoom: 1; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: initial; margin: 6px 0; max-height: 34px; color: #6d96c0; line-height: 17px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 14px; }

.c-card-item__option:after { display: block; content: ""; clear: both; }

.c-card-item__option dd { display: inline; }

.c-card-item__option dd:not(:first-of-type)::before { display: inline; content: " / "; }

.c-card-item__name-flag { color: #0b83e6; margin-right: 4px; }

.c-card-item__rank { position: absolute; left: 0; top: 0; min-width: 32px; padding: 0 11px; height: 32px; background: rgba(255, 247, 248, 0.9); border: solid 1px rgba(255, 0, 56, 0.1); box-sizing: border-box; color: #ff0038; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: bold; text-align: center; line-height: 32px; letter-spacing: 0.5px; z-index: 10; }

.c-card-item__rank.c-card-item__rank--highlight { height: 32px; line-height: 32px; background: rgba(244, 49, 66, 0.8); border: solid 1px rgba(244, 49, 66, 0.1); color: #fff; text-align: center; }

.c-card-item__rank.c-card-item__rank--impact { padding-left: 12px; text-align: left; font-size: 19px; line-height: 45px; background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: 0px 0px; width: 59px; height: 45px; background-size: 112px 101px; color: #fff; background-color: transparent; border: none; }

.c-card-item__thumb { position: relative; text-align: center; }

.c-card-item__thumb a { display: block; width: 100%; height: 100%; }

.c-card-item__thumb .c-card-item__thumb-img { display: inline-block; overflow: hidden; position: relative; vertical-align: top; width: 100%; height: 100%; }

.c-card-item__thumb .c-card-item__thumb-img img { position: absolute; top: 0; bottom: 0; right: 0; left: 0; max-width: 100%; max-height: 100%; margin: auto; }

.c-card-item__thumb .c-card-item__thumb-emblem { position: absolute; top: 0; left: 0; z-index: 10; pointer-events: none; }

.c-card-item__thumb .c-card-item__thumb-emblem img { width: 50px; height: 50px; }

.c-card-item__thumb .c-emblem { position: absolute; top: 12px; left: 12px; z-index: 10; }

.c-card-item__soldout-text { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3); overflow: hidden; line-height: 700px; z-index: 20; }

.c-card-item__soldout-text::before { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 180px; height: 180px; background: url("/img/common/v3/soldout.png") no-repeat; background-size: 180px 180px; content: ""; }

.c-card-item__info dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-card-item__info .c-card-item__mart { font-size: 13px; font-weight: bold; line-height: 23px; letter-spacing: 0; }

.c-card-item__info .c-card-item__mart a { color: inherit; }

.c-card-item__info .c-card-item__mart a:hover { text-decoration: none; }

.c-card-item__info .c-card-item__mart.c-card-item__mart--emart { color: #ffb100; }

.c-card-item__info .c-card-item__mart.c-card-item__mart--homeplus { color: #f43142; }

.c-card-item__info .c-card-item__mart.c-card-item__mart--gsfresh { color: #81c443; }

.c-card-item__info .c-card-item__mart.c-card-item__mart--lottemart { color: #e11b22; }

.c-card-item__info .c-card-item__mart .c-card-item__mart-rank { display: inline-block; margin: -2px 5px 0 0; width: 23px; height: 23px; color: #fff; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 12px; background-image: linear-gradient(to bottom, rgba(255, 49, 49, 0.6), rgba(255, 49, 49, 0.6)), linear-gradient(306deg, #ff408b 96%, #ff8e3c 16%); line-height: 23px; text-align: center; vertical-align: middle; }

.c-card-item__info .c-card-item__name { display: block; color: #111; word-break: break-all; font-size: 15px; overflow: hidden; zoom: 1; }

.c-card-item__info .c-card-item__name:after { display: block; content: ""; clear: both; }

.c-card-item__info .c-card-item__name a { color: #111; }

.c-card-item__info .c-card-item__deal-guide { margin: 0 0 12px 0; font-size: 15px; margin-top: 10px; color: #ff0038; font-size: 14px; line-height: 17px; }

.c-card-item__info .c-card-item__name-addition { overflow: hidden; margin: 0 20px 7px 0; color: #0b83e6; font-size: 13px; height: 1.5em; word-break: break-all; }

.c-card-item__info .c-card-item__counsel { color: #111; font-size: 18px; font-weight: bold; letter-spacing: 0; }

.c-card-item__info .c-card-item__rate { float: left; font-size: 14px; line-height: 18px; letter-spacing: 0; color: #ff0038; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__info .c-card-item__rate .value { font-size: 20px; line-height: 20px; vertical-align: bottom; }

.c-card-item__info .c-card-item__special { font-size: 16px; }

.c-card-item__info .c-card-item__rental { font-size: 16px; color: #0b83e6; }

.c-card-item__info .c-card-item__price { color: #111; font-size: 13px; line-height: 18px; letter-spacing: 0; }

.c-card-item__info .c-card-item__price .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; vertical-align: bottom; }

.c-card-item__info .c-card-item__price > .value { font-size: 20px; font-weight: bold; line-height: 20px; }

.c-card-item__info .c-card-item__price del { color: #666; font-size: 12px; line-height: 16px; }

.c-card-item__info .c-card-item__price del .value { font-size: 14px; }

.c-card-item__info .c-card-item__price .c-card-item__price-per { color: #999; }

.c-card-item__info .c-card-item__price .c-card-item__price-lowest { color: #ff0038; font-size: 13px; line-height: 18px; letter-spacing: 0; }

.c-card-item__info .c-card-item__price .c-card-item__price-lowest .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; font-size: 15px; line-height: 18px; }

.c-card-item__info .c-card-item__price .c-card-item__price-lowest .value { font-size: 20px; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: bold; line-height: 20px; vertical-align: bottom; }

.c-card-item__info .c-card-item__price.c-card-item__price--point { color: #ff0038; }

.c-card-item__info .c-card-item__price .c-card-item__detail { display: block; margin: 7px 0 0 0; color: #666; font-size: 13px; }

.c-card-item__info .c-card-item__price-per { color: #949494; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__info .c-card-item__price-del { color: #949494; }

.c-card-item__info .c-card-item__lowest { color: #ff0038; font-size: 14px; line-height: 19px; margin-bottom: 3px; zoom: 1; }

.c-card-item__info .c-card-item__lowest:after { display: block; content: ""; clear: both; }

.c-card-item__info .c-card-item__lowest-text { float: left; margin-right: 4px; }

.c-card-item__info .c-card-item__lowest-value { float: left; position: relative; padding-left: 9px; margin-right: 2px; }

.c-card-item__info .c-card-item__lowest-value::before { position: absolute; top: 11px; left: 0; width: 0; height: 0; content: ""; -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); border-top: 5px solid #ff0038; border-left: 4px solid transparent; border-right: 4px solid transparent; content: ""; }

.c-card-item__info .c-card-item__lowest .c-iconinfo--help { float: left; margin-top: 2.5px; height: 15px; width: 15px; }

.c-card-item__info .c-card-item__lowest .c-iconinfo--help:after { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 15px; height: 15px; background-position: -108px -243px; background-size: 395px 390px; }

.c-card-item__info .c-card-item__lowest .c_layer_expand { z-index: 10; }

.c-card-item__info .c-card-item__lowest .c_layer_expand .c-iconinfo--help { float: none; margin-top: -2px; }

.c-card-item__info .c-card-item__lowest .c_layer_item { width: 294px; padding-right: 54px; color: #333; font-size: 15px; line-height: 22px; }

.c-card-item__info .c-card-item__benefit { overflow: hidden; color: #666; font-size: 13px; letter-spacing: 0; line-height: 18px; }

.c-card-item__info .c-card-item__benefit .benefit { float: left; position: relative; font-size: 13px; }

.c-card-item__info .c-card-item__benefit .value { color: #0b83e6; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__info .c-card-item__advertise { width: 100%; color: #249356; font-size: 13px; line-height: 18px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.c-card-item__info .c-card-item__store { color: #666; font-size: 13px; line-height: 18px; }

.c-card-item__info .c-card-item__store .office { color: #6d96c0; }

.c-card-item__info .c-card-item__compare { vertical-align: middle; }

.c-card-item__info .c-card-item__compare .compare { color: #111; font-size: 13px; line-height: 22px; vertical-align: middle; }

.c-card-item__info .c-card-item__compare .count { color: #0b83e6; }

.c-card-item__info .c-card-item__compare .count .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__info .c-card-item__compare .beta { display: inline-block; overflow: hidden; line-height: 200px; background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -64px 0px; width: 48px; height: 22px; background-size: 112px 101px; }

.c-card-item__info .c-card-item__compare .beta .info { display: inline-block; line-height: 120px; vertical-align: middle; }

.c-card-item__info .c-card-item__compare.active .c_prd_layer { display: block; z-index: 10; }

.c-card-item__info .c-card-item__compare-text { color: #666; font-weight: normal; font-size: 13px; line-height: 19px; zoom: 1; }

.c-card-item__info .c-card-item__compare-text dt { float: left; position: static !important; width: auto !important; height: auto !important; margin: 0 2px 0 0 !important; clip: auto !important; white-space: normal !important; color: #666; font-weight: normal; font-size: 13px; line-height: 19px; }

.c-card-item__info .c-card-item__compare-text dd { float: left; }

.c-card-item__info .c-card-item__compare-text:after { display: block; content: ""; clear: both; }

.c-card-item__delivery { color: #666; font-size: 13px; letter-spacing: 0; line-height: 18px; zoom: 1; }

.c-card-item__delivery .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__delivery em { color: #0b83e6; }

.c-card-item__delivery .shooting-delivery { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .shooting-delivery strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 13.2px; background-position: -67.02278px -48.28718px; background-size: 217px 214px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .shooting-delivery em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .shooting-delivery em::before { display: none; }

.c-card-item__delivery .shooting-delivery .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .shooting-install { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .shooting-install strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 53px; height: 12.92683px; background-position: -61.97468px -138.92308px; background-size: 255px 252px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .shooting-install em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .shooting-install em::before { display: none; }

.c-card-item__delivery .shooting-install .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .shooting-plus { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .shooting-plus strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 85px; height: 15px; background-position: -122px -142px; background-size: 395px 390px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .shooting-plus em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .shooting-plus em::before { display: none; }

.c-card-item__delivery .shooting-plus .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .shooting-fresh { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .shooting-fresh strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 13px; background-position: -332px -79px; background-size: 395px 390px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .shooting-fresh em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .shooting-fresh em::before { display: none; }

.c-card-item__delivery .shooting-fresh .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .shooting-seller { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .shooting-seller strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 56px; height: 12.72727px; background-position: -206.93671px -87.15385px; background-size: 335px 330px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .shooting-seller em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .shooting-seller em::before { display: none; }

.c-card-item__delivery .shooting-seller .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .shooting-freeze { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .shooting-freeze strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 13.09524px; background-position: -2.61266px -140.57692px; background-size: 258px 255px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .shooting-freeze em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .shooting-freeze em::before { display: none; }

.c-card-item__delivery .shooting-freeze .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .ssg-emart { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .ssg-emart strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 49px; height: 13px; background-position: -332px -199px; background-size: 395px 390px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .ssg-emart em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .ssg-emart em::before { display: none; }

.c-card-item__delivery .ssg-emart .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .ssg-dawn { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .ssg-dawn strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 49px; height: 13px; background-position: -332px -178px; background-size: 395px 390px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .ssg-dawn em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .ssg-dawn em::before { display: none; }

.c-card-item__delivery .ssg-dawn .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .freshtable { float: left; margin-right: 2px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; line-height: 19px; min-height: 19px; }

.c-card-item__delivery .freshtable strong { float: left; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 55px; height: 12.69231px; background-position: -350.49114px -105.64103px; background-size: 417px 412px; overflow: hidden; line-height: 200; margin-top: 4px; }

.c-card-item__delivery .freshtable em { float: left; margin-left: 4px; color: #0b83e6; font-weight: normal; }

.c-card-item__delivery .freshtable em::before { display: none; }

.c-card-item__delivery .freshtable .c-card-item__text { display: block; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; font-weight: 400; font-size: 13px; line-height: 18px; }

.c-card-item__delivery .delivery:not(:first-of-type)::before { width: 2px; height: 2px; background-color: #ccc; display: inline-block; margin: 0 2px 0 4px; vertical-align: middle; border-radius: 100%; content: ""; }

.c-card-item__delivery .delivery .c-card-item__point { color: #0b83e6; }

.c-card-item__delivery .allclub { display: inline-block; font-size: 13px; color: #666; line-height: 1.5; }

.c-card-item__delivery .allclub .c-card-item__point { color: #ff0038; }

.c-card-item__delivery .allclub .c-card-item__text { display: inline-block; }

.c-card-item__delivery .allclub:before { width: 40px; height: 16px; background-image: url("/img/svg/logo/allclub.svg"); background-size: 40px 16px; display: inline-block; margin: -2px 0px 1px 0; vertical-align: middle; content: ""; }

.c-card-item__delivery .sktpass { display: inline-block; font-size: 13px; color: #666; line-height: 18px; }

.c-card-item__delivery .sktpass .c-card-item__point { color: #0b83e6; font-size: 13px; }

.c-card-item__delivery .sktpass .c-card-item__text { display: inline-block; }

.c-card-item__delivery .sktpass:before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 56px; height: 16px; background-position: -195.2px -22.4px; background-size: 316px 312px; display: inline-block; margin: 1px 4px 0 0; vertical-align: top; content: ""; }

.c-card-item__delivery .sktpass + .delivery { clear: both; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__delivery .sktpass + .delivery::before { display: none; }

.c-card-item__delivery:after { display: block; content: ""; clear: both; }

.c-card-item__deal { display: inline-block; padding: 7px 10px 6px 6px; border: 1px solid #eee; font-size: 13px; color: #666; box-sizing: border-box; }

.c-card-item__deal .c-card-item__point { color: #ff0038; }

.c-card-item__deal .c-card-item__point .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__deal::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -64px -27px; width: 18px; height: 18px; background-size: 112px 101px; display: inline-block; margin: -3px 2px 1px 0; vertical-align: middle; content: ""; }

.c-card-item__deal::after { width: 7px; height: 7px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(45deg); display: inline-block; margin: -2px 0 1px 2px; vertical-align: middle; content: ""; }

.c-card-item__cart { zoom: 1; }

.c-card-item__cart:after { display: block; content: ""; clear: both; }

.c-card-item__cart .c-card-item__amount { float: left; position: relative; width: calc(100% - 40px); height: 40px; border: 1px solid #eee; box-sizing: border-box; vertical-align: middle; }

.c-card-item__cart .c-card-item__amount .c-card-item__quantity { display: block; width: auto; height: 100%; border: none; padding: 0 40px; border: 0; color: #111; font-size: 16px; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 40px; background: none; text-align: center; }

.c-card-item__cart .c-card-item__amount button { position: absolute; top: 0; bottom: 0; display: inline-block; width: 40px; line-height: 200px; overflow: hidden; background-color: #fff; }

.c-card-item__cart .c-card-item__amount .c-card-item__add { right: 0; border-left: 1px solid #eee; }

.c-card-item__cart .c-card-item__amount .c-card-item__add::after { display: block; position: absolute; top: 50%; left: 50%; width: 50%; height: 2px; background-color: #999; margin-top: -1px; margin-left: calc(-25% + 2px); content: ""; }

.c-card-item__cart .c-card-item__amount .c-card-item__add::before { display: block; position: absolute; top: 50%; left: 50%; width: 2px; height: 50%; background-color: #999; margin-top: -25%; content: ""; }

.c-card-item__cart .c-card-item__amount .c-card-item__remove { left: 0; border-right: 1px solid #eee; }

.c-card-item__cart .c-card-item__amount .c-card-item__remove::after { display: block; position: absolute; top: 50%; left: 50%; width: 50%; height: 2px; background-color: #999; margin-top: -1px; margin-left: calc(-25% + 2px); content: ""; }

.c-card-item__cart .c-card-item__save { float: right; overflow: hidden; position: relative; width: 40px; height: 40px; line-height: 200px; border: 1px solid #eee; border-left: none; }

.c-card-item__cart .c-card-item__save:before { content: ""; position: absolute; background-color: #ff0038; width: 4px; height: 4px; left: 50%; top: -5px; }

.c-card-item__cart .c-card-item__save:after { content: ""; position: absolute; top: 50%; left: 50%; z-index: 1; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 18px; height: 18px; background-position: -218px -295px; background-size: 395px 390px; transform: translate(-50%, -50%); }

.c-card-item__cart .c-card-item__link { position: relative; display: block; height: 40px; padding-left: 16px; color: #333; border: 1px solid #eee; font-size: 15px; line-height: 38px; }

.c-card-item__cart .c-card-item__link:hover { text-decoration: none; }

.c-card-item__cart .c-card-item__link::after { position: absolute; right: 16px; top: 15px; content: ""; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; transform: rotate(45deg); }

.c-card-item__cart--border-top { border-top: 1px solid #f4f4f4; }

.c-card-item__cart--border-top .c-card-item__amount { border: none; }

.c-card-item__cart--border-top .c-card-item__amount .c-card-item__remove, .c-card-item__cart--border-top .c-card-item__amount .c-card-item__add { border: none; }

.c-card-item__cart--border-top .c-card-item__save { border: none; border-left: 1px solid #f4f4f4; }

.c-card-item__compare-button button { position: relative; min-width: 108px; height: 29px; padding: 0 12px; border-radius: 14.5px; border: solid 1px #eee; background-color: #fff; color: #111; font-size: 11px; letter-spacing: 0; line-height: 26px; overflow: hidden; vertical-align: bottom; text-align: center; }

.c-card-item__compare-button .count { display: inline-block; font-weight: bold; color: #111; }

.c-card-item__compare-button .beta { display: inline-block; background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -47px -83px; width: 15px; height: 8px; background-size: 112px 101px; line-height: 200px; margin: 5px 0 0 1px; vertical-align: top; }

.c-card-item__lowest-button { display: block; min-width: 183px; height: 40px; border-radius: 20px; border: solid 1px #ccc; background-color: #fff; box-sizing: border-box; color: #333; font-size: 14px; letter-spacing: 0; line-height: 37px; text-align: center; overflow: hidden; }

.c-card-item__lowest-button:after { display: inline-block; margin: -3px 0 0 5px; vertical-align: middle; width: 6px; height: 6px; border: 1px solid #0b83e6; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(45deg); }

.c-card-item__addition .c-cart-button { display: block; width: 100%; height: 52px; line-height: 52px; text-align: center; font-size: 17px; color: #ff0038; }

.c-card-item__addition .c-cart-button:before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: 0px -50px; width: 28px; height: 28px; background-size: 112px 101px; display: inline-block; margin: -2px 6px 0 0; vertical-align: middle; overflow: hidden; line-height: 200px; content: ""; }

.c-card-item__button .c-like-button { position: absolute; top: 0; left: 0; }

.c-card-item__extra { margin: 0 21px; border-top: 1px solid #f4f4f4; box-sizing: border-box; }

.c-card-item__extra dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-card-item__extra .c-card-item__deal { padding: 0; border: 0; }

.c-card-item__extra .c-card-item__deal::after { display: none; }

.c-card-item__extra .c-card-item__bottom-info .c-card-item__text { font-size: 13px; }

.c-card-item__qty { position: absolute; right: 20px; bottom: 16px; font-size: 13px; color: #666; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-relates { border-top: 1px solid #eee; }

.c-relates__button-link { display: block; padding: 15px 0 17px; color: #333; font-size: 13px; }

.c-relates__button-link:hover { text-decoration: none; }

.c-relates__icon { display: inline-block; height: 18px; vertical-align: middle; margin-top: -2px; }

.c-relates__icon img { height: 100%; }

.c-relates__icon--lg { width: 20px; height: 20px; margin-top: -2.5px; }

.c-card-item__swatch { margin: 8px 0 4px; font-size: 0; text-align: center; }

.c-card-item__swatch dl { display: flex; justify-content: center; }

.c-card-item__swatch-item { display: flex; justify-content: center; }

.c-card-item__swatch .c-card-item__thumb { width: 26px; height: 26px; margin-right: 5px; overflow: hidden; position: relative; border-radius: 100%; }

.c-card-item__swatch .c-card-item__thumb img { position: absolute; top: 0; bottom: 0; right: 0; left: 0; max-width: 100%; max-height: 100%; margin: auto; }

.c-card-item__swatch .c-card-item__thumb:after { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 1px solid #ddd; border-radius: 100%; box-sizing: border-box; content: ""; }

.c-card-item__swatch .c-card-item__swatch-number { margin-left: 2px; font-size: 12px; color: #999; line-height: 26px; vertical-align: top; }

.c-card-item__swatch .c-card-item__swatch-number .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__color { margin-top: 8px; }

.c-card-item__color dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-card-item__color dd { display: flex; justify-content: center; cursor: pointer; }

.c-card-item__color dd span { overflow: hidden; width: 26px; height: 26px; margin: 0 5px 0 0; border: 1px solid #ddd; border-radius: 50%; font-size: 7px; color: transparent; line-height: 200px; box-sizing: border-box; }

.c-card-item__color-number { margin-left: 1px; font-size: 12px; color: #999; line-height: 26px; }

.c-card-item__color-number .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__anchor { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1; width: 100%; height: 100%; background-color: transparent; }

.c-card-item__bundle { overflow: hidden; }

.c-card-item__bundle > dd { margin-left: -13px; }

.c-card-item__bundle > dd > dl { float: left; position: relative; padding-left: 13px; z-index: 2; }

.c-card-item__bundle > dd > dl::before { position: absolute; top: 3.5px; left: 6px; display: block; width: 1px; height: 12px; background-color: #eee; content: ""; }

.c-card-item__price-special { width: 100%; color: #ff0038; font-size: 14px; line-height: 21px; }

.c-card-item__price-special strong { font-weight: normal; }

.c-card-item__discount { font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-card-item__discount--point { color: #ff0038; }

.c-card-item__discount .value { font-weight: 700; }

.c-button { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button[type="button"], .c-button[type="submit"] { border: 1px solid transparent; }

.c-button--bold { font-weight: bold; }

.c-button--text-left { text-align: left; }

.c-button:disabled, .c-button--disabled { cursor: pointer; }

.c-button:hover { text-decoration: none; }

.c-button--primary { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary[type="button"], .c-button--primary[type="submit"] { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary::after { border-color: #fff; }

.c-button--primary::after[type="button"], .c-button--primary::after[type="submit"] { border-color: #fff; }

.c-button--primary:disabled, .c-button--primary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--primary:disabled[type="button"], .c-button--primary:disabled[type="submit"], .c-button--primary.c-button--disabled[type="button"], .c-button--primary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert[type="button"], .c-button--alert[type="submit"] { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert::after { border-color: #ff0038; }

.c-button--alert::after[type="button"], .c-button--alert::after[type="submit"] { border-color: #ff0038; }

.c-button--alert:disabled, .c-button--alert.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert:disabled[type="button"], .c-button--alert:disabled[type="submit"], .c-button--alert.c-button--disabled[type="button"], .c-button--alert.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal[type="button"], .c-button--normal[type="submit"] { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal::after { border-color: #ccc; }

.c-button--normal::after[type="button"], .c-button--normal::after[type="submit"] { border-color: #ccc; }

.c-button--normal:disabled, .c-button--normal.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal:disabled[type="button"], .c-button--normal:disabled[type="submit"], .c-button--normal.c-button--disabled[type="button"], .c-button--normal.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary[type="button"], .c-button--secondary[type="submit"] { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary::after { border-color: #0b83e6; }

.c-button--secondary::after[type="button"], .c-button--secondary::after[type="submit"] { border-color: #0b83e6; }

.c-button--secondary:disabled, .c-button--secondary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary:disabled[type="button"], .c-button--secondary:disabled[type="submit"], .c-button--secondary.c-button--disabled[type="button"], .c-button--secondary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black { color: #fff; background-color: #333; border-color: #333; }

.c-button--black[type="button"], .c-button--black[type="submit"] { color: #fff; background-color: #333; border-color: #333; }

.c-button--black::after { border-color: #fff; }

.c-button--black::after[type="button"], .c-button--black::after[type="submit"] { border-color: #fff; }

.c-button--black:disabled, .c-button--black.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black:disabled[type="button"], .c-button--black:disabled[type="submit"], .c-button--black.c-button--disabled[type="button"], .c-button--black.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button-luxy[type="button"], .c-button-luxy[type="submit"] { border: 1px solid transparent; }

.c-button-luxy--bold { font-weight: bold; }

.c-button-luxy--text-left { text-align: left; }

.c-button-luxy:disabled, .c-button-luxy--disabled { cursor: pointer; }

.c-button-luxy:hover { text-decoration: none; }

.c-button-luxy--primary { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary[type="button"], .c-button-luxy--primary[type="submit"] { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary::after { border-color: #0f0fd9; }

.c-button-luxy--primary::after[type="button"], .c-button-luxy--primary::after[type="submit"] { border-color: #0f0fd9; }

.c-button-luxy--primary:disabled, .c-button-luxy--primary.c-button-luxy--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy--primary:disabled[type="button"], .c-button-luxy--primary:disabled[type="submit"], .c-button-luxy--primary.c-button-luxy--disabled[type="button"], .c-button-luxy--primary.c-button-luxy--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c_modal { display: none; position: fixed; left: 0; top: 0; right: 0; bottom: 0; z-index: 2000; }

.c_modal.active { display: block; }

.c_modal.c_modal_dimmed { background: rgba(0, 0, 0, 0.2); }

.c_modal--w500 .modal_inner { width: 500px; }

.c_modal .modal_inner { position: relative; margin: 50vh auto; transform: translateY(-50%); background: #fff; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12); }

.c_modal .modal_header { border-bottom: 1px solid #eee; }

.c_modal .modal_header .title { text-align: center; }

.c_modal .modal_content.scroll { overflow-y: auto; height: 100px; }

.c_modal .modal_footer { border-top: 1px solid #f4f4f4; text-align: center; }

.c_modal .modal_footer button { display: inline-block; margin: 0 5px; }

.c_modal .modal_close button { position: absolute; top: 17px; right: 21px; width: 36px; height: 36px; overflow: hidden; line-height: 200px; }

.c_modal .modal_close button:before, .c_modal .modal_close button:after { position: absolute; top: 50%; left: 50%; width: 48px; height: 3px; background-color: #111; content: ""; }

.c_modal .modal_close button:before { -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(45deg); transform: translate(-50%, -50%) scale(0.5) rotate(45deg); }

.c_modal .modal_close button:after { -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(135deg); transform: translate(-50%, -50%) scale(0.5) rotate(135deg); }

.c_layer_expand { display: inline-block; position: relative; }

.c_layer_expand.active { z-index: 100; }

.c_layer_expand.active .c_layer_item { display: block; }

.c_layer_item { display: none; position: absolute; left: 0; width: 240px; padding: 20px; box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36); background: #fff; border-radius: 4px; box-sizing: border-box; z-index: 10; }

.c_layer_item.active { display: block; }

.c_layer_item .layer_info_subject dt { padding-bottom: 6px; font-size: 14px; font-weight: bold; color: #111; }

.c_layer_item .layer_info_subject dd { margin-top: 4px; }

.c_layer_item .layer_info_subject .dash::before { display: inline-block; width: 4px; height: 1px; margin-right: 4px; background-color: #666; vertical-align: 5px; content: ""; }

.c_layer_item .layer_info_subject .dash_align { position: relative; padding-left: 8px; }

.c_layer_item .layer_info_subject .dash_align::before { position: absolute; left: 0; top: 50%; width: 4px; height: 1px; background-color: #666; content: ""; }

.c_layer_item .layer_info li { margin-top: 4px; }

.c_layer_item .layer_info li:first-child { margin-top: 0; }

.c_layer_item .layer_info .dash::before { display: inline-block; width: 4px; height: 1px; margin-right: 4px; background-color: #666; vertical-align: 5px; content: ""; }

.c_layer_item .layer_info .dash_align { position: relative; padding-left: 8px; }

.c_layer_item .layer_info .dash_align::before { position: absolute; left: 0; top: 50%; width: 4px; height: 1px; background-color: #666; content: ""; }

.c_layer_item .layer_close { position: absolute; right: 15px; top: 15px; width: 23px; height: 23px; overflow: hidden; line-height: 200px; }

.c_layer_item .layer_close:before, .c_layer_item .layer_close:after { position: absolute; top: 50%; left: 50%; width: 17px; height: 1px; background-color: #111; content: ""; }

.c_layer_item .layer_close:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.c_layer_item .layer_close:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.c_search_item { position: relative; box-sizing: border-box; border: 1px solid #ddd; background: #fff; }

.c_search_item .search_text { display: block; width: 100%; background: none; box-sizing: border-box; border: none; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; letter-spacing: 0; color: #111; outline: none; padding: 0; }

.c_search_item .search_text::placeholder { color: #999; }

.c_search_item .search_button { position: absolute; right: -1px; top: -1px; overflow: hidden; line-height: 200px; height: 100%; }

.c_search_item .c_layer_item { width: 100%; }

.c_bi_amazon { display: inline-block; width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon.svg"); background-size: 80px 25px; background-size: 100% 100%; vertical-align: middle; overflow: hidden; line-height: 200px; }

.c_bi_amazon.c_bi_amazon_allwhite { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_allwhite.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_bi_amazon.c_bi_amazon_white { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_white.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_bi_amazon.c_bi_amazon_grey { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_black.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_icon_amazon { display: inline-block; width: 14px; height: 14px; background-image: url("/img/svg/logo/amazon_icon.svg"); background-size: 14px 14px; background-size: 100% 100%; vertical-align: middle; overflow: hidden; line-height: 200px; }

.l_amazon_header { position: relative; z-index: 500; width: 100%; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); background: linear-gradient(to left, #a5e6cc, #a5e6cc 44%, #84d7e2 48%, #84d7e1); }

.l_amazon_header.l_amazon_header_fixed { position: fixed; top: 69px; left: 0; right: 0; z-index: 1100; }

.b_amazon_header { zoom: 1; letter-spacing: 0; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; position: relative; width: 1240px; height: 70px; margin: 0 auto; background: linear-gradient(to left, #a5e6cc, #a5e6cc 44%, #84d7e1); }

.b_amazon_header:after { display: block; content: ""; clear: both; }

.c_amazon_header_category { float: left; position: relative; }

.c_amazon_header_category .category_button { position: relative; width: 224px; height: 70px; padding: 0 40px 0 0; font-size: 17px; font-weight: bold; color: #111; text-align: left; z-index: 10; }

.c_amazon_header_category .category_button .c_bi_amazon { margin: 8px 10px 0 0; }

.c_amazon_header_category .category_button:after { content: ""; position: absolute; right: 17px; top: 33px; width: 0; border-style: solid; border-width: 6px 5px 0; border-color: #111 transparent; }

.c_amazon_header_category .category_button::before { position: absolute; right: 0; top: 0; width: 1px; height: 100%; background: #41a1ab; opacity: 0.29; content: ""; }

.c_amazon_header_category.category_visible .category_button:after { transform: rotate(180deg); }

.c_amazon_header_category.category_visible:before { position: absolute; right: 0; top: 0; width: 3000px; height: 100%; background: #41a1ab; opacity: 0.2; content: ""; }

.c_amazon_header_category.category_visible .c_amazon_header_category_layer { display: block; }

.c_amazon_header_category_layer { display: none; position: absolute; left: 0; top: 70px; overflow: hidden; width: 672px; background: #fff; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36); }

.c_amazon_header_category_layer.subcategory_none { width: 224px; }

.c_amazon_header_category_layer > ul { position: relative; width: 224px; height: 100%; border-right: 1px solid #eee; box-sizing: border-box; }

.c_amazon_header_category_layer > ul > li a { position: relative; display: block; height: 44px; padding: 0 10px 0 20px; line-height: 42px; box-sizing: border-box; font-size: 15px; }

.c_amazon_header_category_layer > ul > li a:hover { text-decoration: none; }

.c_amazon_header_category_layer > ul > li > a { border-top: 1px solid #fafafa; border-bottom: 1px solid #fafafa; background: #fafafa; }

.c_amazon_header_category_layer > ul > li:first-child.active > a { border-top: 1px solid #fafafa; }

.c_amazon_header_category_layer > ul > li.active > a { color: #ff0038; font-weight: bold; background: #fff; border-top: 1px solid #eee; border-bottom: 1px solid #eee; }

.c_amazon_header_category_layer > ul > li.active > a::before { width: 5px; height: 5px; border: 1px solid #ff0038; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(45deg); position: absolute; right: 20px; top: 18px; content: ""; }

.c_amazon_header_category_layer > ul > li.active > a::after { position: absolute; right: -1px; top: 0; width: 1px; height: 100%; background: #fff; content: ""; }

.c_amazon_header_category_layer > ul > li.active .box_2depth { display: block; }

.c_amazon_header_category_layer > ul > li.depth_none > a::before { display: none; }

.c_amazon_header_category_layer .box_2depth { zoom: 1; display: none; position: absolute; left: 223px; top: 0; width: 448px; }

.c_amazon_header_category_layer .box_2depth:after { display: block; content: ""; clear: both; }

.c_amazon_header_category_layer .box_2depth ul { position: relative; float: left; width: 224px; }

.c_amazon_header_category_layer .box_2depth ul:nth-child(2):before { display: none; }

.c_amazon_header_category_layer .box_2depth ul:before { position: absolute; right: 0; top: 0; width: 1px; height: 800px; background: #eee; content: ""; }

.c_amazon_header_category_layer .box_2depth a:hover { color: #ff0038; font-weight: bold; }

.c_amazon_header_servicelink { float: left; margin: 0 0 0 25px; }

.c_amazon_header_servicelink ul { float: left; }

.c_amazon_header_servicelink li { float: left; margin: 0 0 0 20px; }

.c_amazon_header_servicelink li:first-child { margin-left: 0; }

.c_amazon_header_servicelink li a { display: block; height: 70px; line-height: 70px; font-weight: bold; font-size: 16px; color: #111; }

.c_amazon_universepass_landing { position: absolute; right: 0; top: 0; padding: 15px 0 0 0; }

.c_amazon_universepass_landing .text { float: left; margin: 9px 10px 0 0; color: #000; font-size: 15px; }

.c_amazon_universepass_landing .link { position: relative; float: left; height: 40px; padding: 7px 25px 0 15px; box-sizing: border-box; background: #3617ce; border-radius: 21px; color: #fff; font-size: 16px; }

.c_amazon_universepass_landing .link:hover { text-decoration: none; }

.c_amazon_universepass_landing .link::after { width: 5px; height: 5px; border: 1px solid #fff; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(45deg); position: absolute; right: 17px; top: 17px; content: ""; }

.c_amazon_universepass_landing .link .universepass { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -802px -25px; width: 56px; height: 16px; background-size: 1191px 201px; display: inline-block; margin: 5px 4px 0 0; overflow: hidden; line-height: 200px; vertical-align: top; }

.c-p-info__title { color: #111; font-size: inherit; font-weight: bold; }

.c-p-info__title:not(:first-child) { margin-top: 20px; }

.c-p-info__sentence { position: relative; margin-top: 4px; color: inherit; font-size: inherit; line-height: inherit; text-align: inherit; }

.c-p-info__sentence:first-of-type { margin-top: 10px; }

.c-p-info__sentence::before { display: none; position: absolute; top: 9px; left: 5px; width: 2px; height: 2px; border-radius: 50%; background-color: #666; content: ""; }

.c-p-info__sentence--bullet { padding-left: 13px; }

.c-p-info__sentence--bullet::before { display: block; }

.c-p-info__sentence--bullet-emphasis { padding-left: 13px; color: #ff0038; }

.c-p-info__sentence--bullet-emphasis::before { display: block; background-color: #ff0038; }

.c-p-info__sentence--bullet-highlight { padding-left: 13px; color: #0b83e6; }

.c-p-info__sentence--bullet-highlight::before { display: block; background-color: #0b83e6; }

.c-p-info__list { margin-top: 10px; color: inherit; font-size: inherit; line-height: inherit; }

.c-p-info__list:first-child { margin-top: 0; }

.c-p-info__list.c-p-info__list--bullet .c-p-info__list-item::before { background-color: #666; }

.c-p-info__list .c-p-info__list-item { margin-top: 4px; }

.c-p-info__list .c-p-info__list-item:first-child { margin-top: 0; }

.c-p-info__list-item { position: relative; color: inherit; font-size: inherit; line-height: inherit; }

.c-p-info__list-item--emphasis { color: #ff0038; }

.c-p-info__list-item--emphasis::before { background-color: #ff0038; }

.c-p-info__list-item--highlight { color: #0b83e6; }

.c-p-info__list-item--highlight::before { background-color: #0b83e6; }

.c-p-info__list--bullet .c-p-info__list-item { padding-left: 13px; }

.c-p-info__list--bullet .c-p-info__list-item::before { position: absolute; top: 9px; left: 5px; display: block; width: 2px; height: 2px; border-radius: 50%; content: ""; }

.c-p-info__list--bullet .c-p-info__list-item--emphasis { color: #ff0038; }

.c-p-info__list--bullet .c-p-info__list-item--emphasis::before { background-color: #ff0038; }

.c-p-info__list--bullet .c-p-info__list-item--highlight { color: #0b83e6; }

.c-p-info__list--bullet .c-p-info__list-item--highlight::before { background-color: #0b83e6; }

.c-p-info__text--emphasis { color: #ff0038; }

.c-p-info__text--highlight { color: #0b83e6; }

.c-p-info--center { text-align: center; }

.c_modal_lowest .modal_inner { width: 500px; }

.c_modal_lowest .modal_header .title { line-height: 70px; color: #111; font-size: 19px; }

.c_modal_lowest .modal_content { padding: 30px; font-size: 16px; color: #333; }

.c_modal_lowest .c-p-info { font-size: 16px; color: #333; line-height: 19px; }

.c_modal_lowest .c-p-info__list.c-p-info__list--bullet .c-p-info__list-item::before { background-color: #333; }

.c_modal_lowest .c-p-info__list .c-p-info__list-item { margin-top: 16px; }

.c_modal_lowest .c-p-info__list .c-p-info__list-item:first-child { margin-top: 0; }

.c_modal_lowest .c-p-info__list--bullet .c-p-info__list-item::before { width: 3px; height: 3px; }

.l_amazon_wide { width: 100%; }

.l_amazon_wide .b_amazon { width: 1240px; margin: 0 auto; }

.l_amazon { width: 1240px; margin: 0 auto; }

.l_amazon_bg { background: #fafafa; }

.s_amazon { padding: 0 0 100px 0; }

.b_amazon { zoom: 1; }

.b_amazon:after { display: block; content: ""; clear: both; }

.swiper-container { zoom: 1; overflow: hidden; width: 1240px; }

.swiper-container:after { display: block; content: ""; clear: both; }

.swiper-container .swiper-slide { float: left; }

.c_list_card { clear: both; }

.c_list_card_collection.col_4 .c-card-item__info { min-height: 167px; }

.c_list_card_collection.col_4 .c-card-item--space .c-card-item__info { min-height: 156px; }

.c_list_card_collection.col_4 .c-card-item__name-addition { max-height: 1.5em; margin: 0 0 8px 0; font-size: 15px; }

.c_list_card_collection.col_4 .c-card-item__price { font-size: 15px; line-height: 20px; }

.c_list_card_collection.col_5 .c-card-item__lowest__rate { clear: left; }

.c-card-item .c-card-item__info { position: relative; }

.c-card-item .c-card-item__rank { padding: 0 6px; background: rgba(244, 49, 66, 0.8); border: solid 1px rgba(244, 49, 66, 0.1); color: #fff; }

.c-card-item .c-card-item__rank.c-card-item__rank--light { height: 32px; line-height: 32px; border: solid 1px rgba(255, 0, 56, 0.1); background: #fff7f8; text-align: center; color: #ff0038; }

.c-card-item.c-card-item--deal .c-card-item__info { min-height: 234px; }

.c-card-item.c-card-item--collection .c-card-item__rank { top: 10px; left: 10px; }

.c-card-item.c-card-item--gallery.c-card-item--space { padding-bottom: 52px; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__info { padding-bottom: 20px; }

.c-card-item.c-card-item--gallery.c-card-item--space .c-card-item__addition { border-top: 1px solid #eee; }

.c_amazon_card_deal .swiper-slide { margin-right: 29px; }

.c_amazon_card_deal li { margin: 0 0 0 29px; }

.c_amazon_card_deal .c-card-item { overflow: hidden; width: 394px; height: 446px; border-radius: 4px; }

.c_amazon_card_deal .c-card-item .c-card-item__thumb-img { display: inline-block; width: 100%; height: 197px; border-top-left-radius: 4px; border-top-right-radius: 4px; }

.c_amazon_card_deal .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_amazon_card_deal .c-card-item .c-card-item__thumb-emblem { top: 9px; left: 9px; }

.c_amazon_card_deal .c-card-item .c-card-item__thumb-emblem img { width: 80px; height: 80px; }

.c_amazon_card_deal .c-card-item .c-card-item__info { min-height: 194px; padding: 12px 0 18px 20px; box-sizing: border-box; }

.c_amazon_card_deal .c-card-item .c-nation { float: left; margin-bottom: 10px; }

.c_amazon_card_deal .c-card-item .c-nation .amazon { margin-right: 1px; }

.c_amazon_card_deal .c-card-item .c-time-flag { float: left; margin: 0 0 10px 8px; }

.c_amazon_card_deal .c-card-item .c-flag { float: left; margin-bottom: 6px; }

.c_amazon_card_deal .c-card-item .c-card-item__name { clear: both; height: 1.5em; margin: 0 20px 1px 0; }

.c_amazon_card_deal .c-card-item .c-card-item__name-addition { overflow: hidden; margin: 0 20px 7px 0; color: #0b83e6; font-size: 13px; height: 1.5em; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info { padding: 0; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info .c-card-item__rate .value { font-size: 24px; line-height: 24px; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info .c-card-item__price { margin-right: 8px; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info .c-card-item__price .value { font-size: 24px; line-height: 24px; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info .c-card-item__price-del { color: #949494; font-size: 16px; line-height: 24px; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info .c-card-item__price--point { color: #ff0038; }

.c_amazon_card_deal .c-card-item .c-card-item__price-info .c-card-item__detail { display: block; margin: 10px 0 0 0; color: #666; font-size: 13px; }

.c_amazon_card_deal .c-card-item .c-card-item__counsel { margin: -5px 0 0 20px; }

.c_amazon_card_deal .c-card-item .c-starrate { position: relative; float: left; margin: 13px 0 0 0; padding: 0 12px 0 0; }

.c_amazon_card_deal .c-card-item .c-starrate dd { float: left; }

.c_amazon_card_deal .c-card-item .c-starrate:after { position: absolute; right: 4px; top: 9px; width: 2px; height: 2px; border-radius: 50%; background: #ccc; content: ""; }

.c_amazon_card_deal .c-card-item .c-starrate .c-starrate__review .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_amazon_card_deal .c-card-item .c-card-item__benefit { float: left; margin: 13px 0 0 0; height: 18px; }

.c_amazon_card_deal .c-card-item .c-card-item__benefit .benefit { padding: 0 0 0 12px; }

.c_amazon_card_deal .c-card-item .c-card-item__benefit .benefit:before { position: absolute; left: 5px; top: 9px; width: 2px; height: 2px; border-radius: 50%; background: #ccc; content: ""; }

.c_amazon_card_deal .c-card-item .c-card-item__benefit dt + .benefit { padding-left: 0; }

.c_amazon_card_deal .c-card-item .c-card-item__benefit dt + .benefit::before { display: none; }

.c_amazon_card_deal .c-card-item .c-card-item__delivery { clear: both; padding: 5px 0 0 0; }

.c_amazon_card_deal .c-card-item .c-card-item__delivery dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_amazon_card_deal .c-card-item .c-card-item__delivery dd { position: relative; float: left; }

.c_amazon_card_deal .c-card-item .c-card-item__delivery .allclub { font-size: 13px; color: #666; }

.c_amazon_card_deal .c-card-item .c-card-item__delivery .allclub:before { width: 40px; height: 16px; background-image: url("/img/svg/logo/allclub.svg"); background-size: 40px 16px; display: inline-block; margin: -2px 0px 1px 0; vertical-align: middle; content: ""; }

.c_amazon_card_deal .c-card-item .c-card-item__delivery .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_card_deal .c-card-item .c-card-item__deal-guide { color: #666; }

.c_amazon_card_deal .c-card-item .card_addition { height: 53px; margin: 0 21px; padding: 15px 0 0 0; border-top: 1px solid #f4f4f4; }

.c_amazon_card_deal .c-card-item .card_addition .c_prd_coupon { margin-left: 12px; }

.c_amazon_card_deal .c-card-item .card_addition .c-card-item__deal { padding: 0; border: none; }

.c_amazon_card_deal .c-card-item .card_addition .c-card-item__deal:after { display: none; }

.c_amazon_card_row li { height: 270px; margin: 0 20px; padding: 20px 0 0 0; border-top: 1px solid #f4f4f4; box-sizing: border-box; }

.c_amazon_card_row li:first-child { border-top: none; }

.c_amazon_card_row .c-card-item:hover .c-card-item__thumb-img img { transform: scale(1.08); }

.c_amazon_card_row .c-card-item a:hover { text-decoration: none; }

.c_amazon_card_row .c-card-item .c-card-item__thumb-img { overflow: hidden; display: inline-block; width: 128px; height: 128px; vertical-align: top; }

.c_amazon_card_row .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_amazon_card_row .c-card-item .c-card-item__soldout-text { width: 128px; height: 128px; }

.c_amazon_card_row .c-card-item .c-card-item__soldout-text::before { width: 60%; height: 60%; background-size: 100% 100%; }

.c_amazon_card_row .c-card-item .c-card-item__info { zoom: 1; padding: 4px 0 0 20px; vertical-align: top; }

.c_amazon_card_row .c-card-item .c-card-item__info:after { display: block; content: ""; clear: both; }

.c_amazon_card_row .c-card-item .c-time-flag { margin: 0 0 8px 0; }

.c_amazon_card_row .c-card-item .c-flag { margin: 0 0 8px 0; }

.c_amazon_card_row .c-card-item .c-card-item__name { max-height: 3em; line-height: 1.5; margin: 0 0 10px 0; }

.c_amazon_card_row .c-card-item .c-card-item__name-addition { overflow: hidden; max-height: 1.5em; margin: 0 0 15px 0; color: #0b83e6; line-height: 1.5; }

.c_amazon_card_row .c-card-item .c-card-item__price-info { margin: 0 0 12px 0; padding: 0; }

.c_amazon_card_row .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; font-size: 16px; }

.c_amazon_card_row .c-card-item .c-card-item__price-info .c-card-item__rate .value { font-size: 22px; line-height: 22px; }

.c_amazon_card_row .c-card-item .c-card-item__price-info .c-card-item__price { font-size: 15px; }

.c_amazon_card_row .c-card-item .c-card-item__price-info .c-card-item__price .value { font-size: 22px; line-height: 22px; }

.c_amazon_card_row .c-card-item .c-card-item__price-info + .c-card-item__delivery { padding-left: 0; }

.c_amazon_card_row .c-card-item .c-card-item__price-info + .c-card-item__delivery.before { display: none; }

.c_amazon_card_row .c-card-item .c-starrate { float: left; margin: 0 0 7px 0; line-height: 18px; }

.c_amazon_card_row .c-card-item .c-starrate dd { float: left; }

.c_amazon_card_row .c-card-item .c-starrate .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_amazon_card_row .c-card-item .c-card-item__delivery { position: relative; float: none; clear: both; padding: 0; }

.c_amazon_card_row .c-card-item .c-card-item__delivery:before { position: absolute; left: 5px; top: 9px; width: 2px; height: 2px; background: #ccc; border-radius: 100%; content: ""; }

.c_amazon_card_row .c-card-item .c-card-item__delivery .allclub:before { float: left; margin: 2px 2px 0 0; }

.c_amazon_card_row .c-card-item .c-card-item__delivery .sktpass .c-card-item__point { line-height: 18px; }

.c_amazon_card_row .c-card-item .card_ranking_flag { padding-left: 12px; font-size: 19px; line-height: 45px; color: #fff; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -512px -25px; width: 59px; height: 45px; background-size: 1191px 201px; border-top-left-radius: 4px; }

.c_amazon_card_review li { margin: 30px 0 0 30px; }

.c_amazon_card_review li:nth-child(1), .c_amazon_card_review li:nth-child(2) { margin-top: 0; }

.c_amazon_card_review li:nth-child(2n-1) { margin-left: 0; }

.c_amazon_card_review .c-card-item { display: block; width: 605px; border-radius: 4px; }

.c_amazon_card_review .c-card-item .c-card-item__thumb { padding: 20px 0 20px 20px; }

.c_amazon_card_review .c-card-item .c-card-item__thumb-img { overflow: hidden; display: inline-block; width: 160px; height: 160px; vertical-align: top; }

.c_amazon_card_review .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_amazon_card_review .c-card-item .c-card-item__soldout-text { width: 160px; height: 160px; left: 20px; top: 20px; }

.c_amazon_card_review .c-card-item .c-card-item__soldout-text::before { width: 60%; height: 60%; background-size: 100% 100%; }

.c_amazon_card_review .c-card-item .c-card-item__info { padding: 27px 20px 20px 20px; vertical-align: top; }

.c_amazon_card_review .c-card-item .c-nation { margin-bottom: 8px; }

.c_amazon_card_review .c-card-item .c-flag { margin: 0 0 8px 0; }

.c_amazon_card_review .c-card-item .c-card-item__rank { top: 20px; left: 20px; width: 32px; height: 32px; line-height: 32px; text-align: center; color: #fff; background: rgba(244, 49, 66, 0.8); }

.c_amazon_card_review .c-card-item .c-card-item__name { max-height: 1.5em; line-height: 1.5; margin: 0 0 9px 0; }

.c_amazon_card_review .c-card-item .c-card-item__price-info { margin: 0 0 10px 0; padding: 0; }

.c_amazon_card_review .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; }

.c_amazon_card_review .c-card-item .c-starrate { float: left; line-height: 18px; }

.c_amazon_card_review .c-card-item .c-starrate dd { float: left; }

.c_amazon_card_review .c-card-item .c-starrate .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_amazon_card_review .c-card-item .c-card-item__delivery { position: relative; float: left; padding: 0 0 0 12px; }

.c_amazon_card_review .c-card-item .c-card-item__delivery:before { position: absolute; left: 5px; top: 9px; width: 2px; height: 2px; background: #ccc; border-radius: 100%; content: ""; }

.c_amazon_card_review .c-card-item .c-card-item__delivery .allclub:before { float: left; margin: 2px 2px 0 0; }

.c_amazon_card_review .c-card-item .card_review { clear: both; position: relative; border-top: 1px solid #f4f4f4; }

.c_amazon_card_review .c-card-item .card_review .review_box { position: relative; padding: 20px 20px 20px 42px; }

.c_amazon_card_review .c-card-item .card_review .review_box:before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -901px -25px; width: 14px; height: 14px; background-size: 1191px 201px; position: absolute; left: 19px; top: 20px; content: ""; }

.c_amazon_card_review .c-card-item .card_review .review_text { overflow: hidden; height: 4.5em; font-size: 15px; line-height: 22px; }

.c_amazon_card_review .c-card-item .card_review .review_info { zoom: 1; margin: 15px 0 0 0; }

.c_amazon_card_review .c-card-item .card_review .review_info:after { display: block; content: ""; clear: both; }

.c_amazon_card_review .c-card-item .card_review .review_user { float: left; margin: 0 4px 0 0; line-height: 18px; color: #999; font-weight: normal; }

.c_amazon_card_review .c-card-item .card_review .c-starrate { float: left; position: relative; padding: 1px 0 0 8px; }

.c_amazon_card_review .c-card-item .card_review .c-starrate:before { position: absolute; left: 0; top: 8px; width: 2px; height: 2px; background: #ccc; border-radius: 100%; content: ""; }

.c_amazon_card_review .c-card-item .card_review .c-starrate .c-starrate__review { margin: -1px 0 0 0; font-size: 14px; line-height: 18px; color: #333; }

.c_amazon_card_review .c-card-item .card_review .review_navigator { position: absolute; right: 22px; bottom: 18px; padding: 0 28px; text-align: center; }

.c_amazon_card_review .c-card-item .card_review .review_navigator .page { display: inline-block; margin: 0 10px; font-size: 15px; line-height: 28px; color: #666; }

.c_amazon_card_review .c-card-item .card_review .review_navigator .page .current { color: #111; font-weight: bold; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button { overflow: hidden; position: absolute; width: 28px; height: 28px; line-height: 300px; border-radius: 50%; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); background: #fff; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button::before { position: absolute; left: 50%; top: 50%; content: ""; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button.previous { left: 0; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button.previous::before { margin: -6px 0 0 -5px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -920px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button.previous:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -946px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button.next { right: 0; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button.next::before { margin: -6px 0 0 -3px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -959px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button.next:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -985px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button:disabled.previous::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -933px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button:disabled.next::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -972px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_card_review .c-card-item .card_review .review_navigator button:disabled:hover { box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); }

.c_amazon_card_review .c-card-item .card_review .review_navigator button:hover { box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_tab_item .tab_navigator button { width: 50px; height: 50px; margin-top: -25px; border-radius: 50%; box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_tab_item .tab_navigator button.previous::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -626px -50px; width: 10px; height: 18px; background-size: 1191px 201px; }

.c_tab_item .tab_navigator button.previous:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -641px -50px; width: 10px; height: 18px; background-size: 1191px 201px; }

.c_tab_item .tab_navigator button.next::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -656px -50px; width: 10px; height: 18px; background-size: 1191px 201px; }

.c_tab_item .tab_navigator button.next:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -671px -50px; width: 10px; height: 18px; background-size: 1191px 201px; }

.c_tab_item .tab_navigator button:hover { box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6); }

.s_amazon_main { padding-bottom: 0; }

.c_amazon_main_billboard { width: 1240px; height: 400px; }

.c_amazon_main_billboard .visual li { width: 1240x; height: 400px; transition: opacity 0.3s ease-in-out; }

.c_amazon_main_billboard .visual li img { width: 1240px; height: 400px; }

.c_amazon_main_billboard .navigator { height: 45px; border-radius: 23px; background: rgba(0, 0, 0, 0.2); }

.c_amazon_main_billboard .navigator button { width: 46px; height: 45px; }

.c_amazon_main_billboard .navigator button:before { top: 11px; }

.c_amazon_main_billboard .navigator button.previous:before { left: 16px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -538px -100px; width: 24px; height: 24px; background-size: 1191px 201px; }

.c_amazon_main_billboard .navigator button.next:before { right: 16px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -480px -100px; width: 24px; height: 24px; background-size: 1191px 201px; }

.c_amazon_main_billboard .indicate { height: 45px; line-height: 45px; padding: 0 17px; border-radius: 23px; background: rgba(0, 0, 0, 0.2); }

.c_amazon_main_billboard .indicate .pagination { display: inline-block; width: 70px; font-size: 17px; color: #fff; text-align: center; }

.c_amazon_main_billboard .indicate .pagination .line { margin: 0 4px; opacity: 0.6; }

.c_amazon_main_billboard .indicate .pagination .total { opacity: 0.6; }

.c_amazon_main_billboard .indicate .more { position: absolute; right: 0; top: 0; width: 45px; height: 45px; overflow: hidden; line-height: 200px; }

.c_amazon_main_billboard .indicate .more::before { position: absolute; right: 11px; top: 11px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -451px -100px; width: 24px; height: 24px; background-size: 1191px 201px; content: ""; }

.c_amazon_main_billboard .control { right: 216px; border-radius: 23px; background: rgba(0, 0, 0, 0.2); }

.c_amazon_main_billboard .control button { width: 45px; height: 45px; }

.c_amazon_main_billboard .control button:before { margin: -12px 0 0 -12px; }

.c_amazon_main_billboard .control button.stop:before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -567px -100px; width: 24px; height: 24px; background-size: 1191px 201px; }

.c_amazon_main_billboard .control button.play:before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -509px -100px; width: 24px; height: 24px; background-size: 1191px 201px; }

.c_amazon_title { zoom: 1; position: relative; margin: 0 0 21px 0; }

.c_amazon_title:after { display: block; content: ""; clear: both; }

.c_amazon_title.c_amazon_title_line { padding-bottom: 20px; border-bottom: 1px solid #eee; }

.c_amazon_title .title { float: left; font-size: 24px; color: #111; vertical-align: middle; }

.c_amazon_title .point { color: #0b83e6; }

.c_amazon_title .description { float: left; margin: 6px 0 0 19px; font-size: 16px; color: #666; }

.c_amazon_title .description .date { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_title .c_more { position: absolute; right: 1px; top: 7px; }

.c_amazon_title .c_more:after { color: #111; }

.c_amazon_title + .c_amazon_theme_text { margin-top: -15px; }

.c_amazon_main_timedeal_sort { zoom: 1; margin: 0 0 25px 0; }

.c_amazon_main_timedeal_sort:after { display: block; content: ""; clear: both; }

.c_amazon_main_timedeal_sort li { float: left; margin: 0 0 0 10px; }

.c_amazon_main_timedeal_sort li:first-child { margin-left: 0; }

.c_amazon_main_timedeal_sort a[aria-selected="true"] .c-card-item { border-color: #111; }

.c_amazon_main_timedeal_sort .c-card-item { width: 240px; height: 80px; padding: 14px 0 0 14px; border: 1px solid #eee; box-sizing: border-box; border-radius: 40px; background: #fff; }

.c_amazon_main_timedeal_sort .c-card-item__thumb { float: left; width: 50px; margin: 0 10px 0 0; }

.c_amazon_main_timedeal_sort .c-card-item__thumb-img { overflow: hidden; display: inline-block; width: 50px; height: 50px; border-radius: 25px; vertical-align: top; }

.c_amazon_main_timedeal_sort .c-card-item__info { float: left; width: 140px; }

.c_amazon_main_timedeal_sort .c-card-item__name { overflow: hidden; margin: 2px 0 0 0; height: 1.5em; font-size: 15px; color: #333; }

.c_amazon_main_timedeal_sort .card_timedeal_status { overflow: hidden; height: 1.5em; font-size: 15px; color: #49a3c7; font-weight: bold; }

.c_card_amazon_timedeal .c-card-item { zoom: 1; overflow: hidden; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); background: #fff; }

.c_card_amazon_timedeal .c-card-item:after { display: block; content: ""; clear: both; }

.c_card_amazon_timedeal .c-card-item:hover .c-card-item__thumb-img img { transform: scale(1.08); }

.c_card_amazon_timedeal .c-card-item .c-card-item__thumb { float: left; overflow: hidden; position: relative; width: 605px; height: 302px; margin: 0 40px 0 0; text-align: center; }

.c_card_amazon_timedeal .c-card-item .c-card-item__thumb img { overflow: hidden; max-width: 100%; height: 100%; transition: transform 0.2s ease-in; }

.c_card_amazon_timedeal .c-card-item .c-card-item__thumb:after { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.02); content: ""; }

.c_card_amazon_timedeal .c-card-item .c-card-item__thumb-img { width: 100%; height: 100%; }

.c_card_amazon_timedeal .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_card_amazon_timedeal .c-card-item .c-card-item__thumb-img:hover img { transform: scale(1.05); }

.c_card_amazon_timedeal .c-card-item .c-card-item__info { position: relative; float: left; width: 595px; height: 302px; padding: 22px 50px 0 0; box-sizing: border-box; }

.c_card_amazon_timedeal .c-card-item .c-nation { float: left; margin: 0 8px 8px 0; line-height: 20px; }

.c_card_amazon_timedeal .c-card-item .c-nation .amazon { margin-right: 1px; }

.c_card_amazon_timedeal .c-card-item .c-flag { float: left; margin-bottom: 8px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__name { clear: both; height: 1.5em; margin: 0 0 28px 0; font-size: 19px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__name a { color: #111; }

.c_card_amazon_timedeal .c-card-item .c-card-item__name-addition { overflow: hidden; height: 20px; margin: -28px 0 8px 0; color: #0b83e6; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info { zoom: 1; padding: 0; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info:after { display: block; content: ""; clear: both; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info .c-card-item__rate .value { font-size: 24px; line-height: 24px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info .c-card-item__special { display: inline-block; font-size: 18px; line-height: 22px; margin-top: 2px; vertical-align: top; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info .c-card-item__price { float: left; margin-right: 8px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info .c-card-item__price .value { font-size: 24px; line-height: 24px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info .c-card-item__price-del { float: left; font-size: 16px; line-height: 24px; color: #949494; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info + .c-card-item__benefit { padding-left: 0; }

.c_card_amazon_timedeal .c-card-item .c-card-item__price-info + .c-card-item__benefit::before { display: none; }

.c_card_amazon_timedeal .c-card-item .c-card-item__counsel { margin: -5px 0 0 20px; }

.c_card_amazon_timedeal .c-card-item .c-starrate { float: left; margin: 28px 0 0 0; }

.c_card_amazon_timedeal .c-card-item .c-starrate dd { float: left; }

.c_card_amazon_timedeal .c-card-item .c-starrate .c-starrate__review .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_card_amazon_timedeal .c-card-item .c-starrate__review { color: #666; }

.c_card_amazon_timedeal .c-card-item .c-card-item__benefit { float: left; position: relative; overflow: hidden; height: 18px; margin: 28px 0 0 0; padding: 0 0 0 14px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__benefit:before { position: absolute; left: 7px; top: 9px; width: 2px; height: 2px; border-radius: 50%; background: #ccc; content: ""; }

.c_card_amazon_timedeal .c-card-item .c-card-item__benefit .benefit { padding: 0 0 0 14px; color: #666; }

.c_card_amazon_timedeal .c-card-item .c-card-item__benefit .benefit:before { position: absolute; left: 6px; top: 9px; width: 2px; height: 2px; border-radius: 50%; background: #ccc; content: ""; }

.c_card_amazon_timedeal .c-card-item .c-card-item__benefit dt + .benefit { padding-left: 0; }

.c_card_amazon_timedeal .c-card-item .c-card-item__benefit dt + .benefit::before { display: none; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery { clear: both; overflow: hidden; height: 18px; padding: 8px 0 0 0; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery .allclub { float: left; font-size: 13px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery .allclub:before { margin-bottom: 0; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery .sktpass { float: left; font-size: 13px; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery .delivery { float: left; position: relative; padding: 0 0 0 14px; color: #666; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery .delivery:before { position: absolute; left: 3px; top: 9px; width: 2px; height: 2px; border-radius: 50%; background: #ccc; content: ""; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery dt + .delivery { padding-left: 0; }

.c_card_amazon_timedeal .c-card-item .c-card-item__delivery dt + .delivery::before { display: none; }

.c_card_amazon_timedeal .c-card-item .c-card-item__info-text { font-size: 18px; line-height: 24px; color: #666; }

.c_card_amazon_timedeal .c-card-item .c-card-item__info-text + .c-card-item__benefit { padding-left: 0; }

.c_card_amazon_timedeal .c-card-item .c-card-item__info-text + .c-card-item__benefit::before { display: none; }

.c_card_amazon_timedeal .c_timecount { zoom: 1; display: block; margin: 0 0 12px 0; color: #ff0038; font-size: 28px; font-weight: bold; transition: 0.5s; }

.c_card_amazon_timedeal .c_timecount:after { display: block; content: ""; clear: both; }

.c_card_amazon_timedeal .c_timecount dd { float: left; }

.c_card_amazon_timedeal .c_timecount .status { margin: 0 10px 0 0; line-height: 48px; }

.c_card_amazon_timedeal .c_timecount .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_card_amazon_timedeal .c_timecount .hour, .c_card_amazon_timedeal .c_timecount .minutes, .c_card_amazon_timedeal .c_timecount .second, .c_card_amazon_timedeal .c_timecount .colon { float: left; }

.c_card_amazon_timedeal .c_timecount .box { float: left; overflow: hidden; width: 20px; height: 40px; }

.c_card_amazon_timedeal .c_timecount .minutes:after, .c_card_amazon_timedeal .c_timecount .hour:after { font-size: 32px; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #ff0038; content: ":"; }

.c_card_amazon_timedeal .c_timecount .number_group { display: block; width: 20px; }

.c_card_amazon_timedeal .c_timecount .number_group .number { display: block; height: 40px; font-size: 32px; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: bold; color: #ff0038; text-align: center; }

.c_card_amazon_timedeal .c-card-item__soldout-text { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3); overflow: hidden; line-height: 1600px; z-index: 20; }

.c_card_amazon_timedeal .c-card-item__soldout-text:before { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 160px; height: 160px; background: url("/img/common/v3/soldout.png") no-repeat; background-size: 160px 160px; content: ""; }

.c_amazon_shoppingtip { position: relative; float: left; width: 605px; height: 185px; padding: 29px 30px 0 30px; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); box-sizing: border-box; border-radius: 4px; }

.c_amazon_shoppingtip .tip_title { margin: 0 0 27px 0; font-size: 20px; line-height: 24px; color: #111; }

.c_amazon_shoppingtip li { overflow: hidden; position: relative; margin: 15px 0 0 0; padding: 0 65px 0 0; }

.c_amazon_shoppingtip li:first-child { margin-top: 0; }

.c_amazon_shoppingtip li a { display: inline-block; overflow: hidden; max-width: 332px; height: 28px; font-size: 17px; line-height: 28px; color: #333; vertical-align: top; white-space: nowrap; text-overflow: ellipsis; }

.c_amazon_shoppingtip .tip_category { display: inline-block; height: 30px; margin: 0 10px 0 0; padding: 0 15px; font-size: 13px; line-height: 28px; border-radius: 15px; color: #111; border: 1px solid rgba(51, 51, 51, 0.2); box-sizing: border-box; vertical-align: top; font-weight: bold; }

.c_amazon_shoppingtip .tip_category.tip_category_point { color: #ff0038; border-color: rgba(244, 49, 66, 0.2); }

.c_amazon_shoppingtip .tip_date { position: absolute; right: 0; top: 4px; color: #999; font-size: 15px; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_shoppingtip .tip_new { overflow: hidden; display: inline-block; margin: 3px 0 0 6px; vertical-align: middle; line-height: 200px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -882px -25px; width: 14px; height: 14px; background-size: 1191px 201px; }

.c_amazon_shoppingtip .tip_more { position: absolute; right: 30px; top: 30px; }

.c_amazon_coupon { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: 0px 0px; width: 303px; height: 201px; background-size: 1191px 201px; position: relative; float: left; margin: -6px -6px -6px 21px; }

.c_amazon_coupon .coupon_box { position: relative; padding: 34px 98px 0 37px; box-sizing: border-box; }

.c_amazon_coupon .coupon_name { overflow: hidden; height: 60px; margin: 0 0 3px 0; color: #49a3c7; font-weight: bold; font-size: 20px; line-height: 28px; }

.c_amazon_coupon .coupon_name .value { font-size: 26px; }

.c_amazon_coupon .coupon_name .unit { display: inline-block; font-size: 17px; }

.c_amazon_coupon .coupon_detail { overflow: hidden; height: 36px; color: #333; font-size: 15px; line-height: 18px; }

.c_amazon_coupon .coupon_download { position: absolute; left: 7px; top: 6px; width: 290px; height: 135px; overflow: hidden; line-height: 500px; }

.c_amazon_coupon .coupon_download:after { position: absolute; right: 25px; top: 68px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -308px -100px; width: 50px; height: 50px; background-size: 1191px 201px; content: ""; }

.c_amazon_coupon .coupon_navigator { position: absolute; left: 37px; bottom: 30px; padding: 0 28px; text-align: center; }

.c_amazon_coupon .coupon_navigator .page { display: inline-block; margin: 0 10px; font-size: 15px; line-height: 28px; color: #666; }

.c_amazon_coupon .coupon_navigator .page .current { color: #111; font-weight: bold; }

.c_amazon_coupon .coupon_navigator .line { margin: 0 4px; }

.c_amazon_coupon .coupon_navigator button { overflow: hidden; position: absolute; width: 28px; height: 28px; line-height: 300px; border-radius: 50%; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); background: #fff; }

.c_amazon_coupon .coupon_navigator button::before { position: absolute; left: 50%; top: 50%; content: ""; }

.c_amazon_coupon .coupon_navigator button.previous { left: 0; }

.c_amazon_coupon .coupon_navigator button.previous::before { margin: -6px 0 0 -5px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -920px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_coupon .coupon_navigator button.previous:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -946px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_coupon .coupon_navigator button.next { right: 0; }

.c_amazon_coupon .coupon_navigator button.next::before { margin: -6px 0 0 -3px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -959px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_coupon .coupon_navigator button.next:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -985px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_coupon .coupon_navigator button:disabled.previous::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -933px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_coupon .coupon_navigator button:disabled.next::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -972px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_coupon .coupon_navigator button:disabled:hover { box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); }

.c_amazon_coupon .coupon_navigator button:hover { box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_amazon_infotip { position: relative; float: left; width: 287px; height: 185px; margin: 0 0 0 30px; overflow: hidden; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); }

.c_amazon_infotip img { width: 100%; height: 100%; }

.c_amazon_infotip .navigator { position: absolute; left: 30px; bottom: 20px; padding: 0 28px; text-align: center; }

.c_amazon_infotip .navigator .page { display: inline-block; margin: 0 10px; font-size: 15px; line-height: 28px; color: #666; }

.c_amazon_infotip .navigator .page .current { color: #111; font-weight: bold; }

.c_amazon_infotip .navigator .line { margin: 0 4px; }

.c_amazon_infotip .navigator button { overflow: hidden; position: absolute; width: 28px; height: 28px; line-height: 300px; border-radius: 50%; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); background: #fff; }

.c_amazon_infotip .navigator button::before { position: absolute; left: 50%; top: 50%; content: ""; }

.c_amazon_infotip .navigator button.previous { left: 0; }

.c_amazon_infotip .navigator button.previous::before { margin: -6px 0 0 -5px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -920px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_infotip .navigator button.previous:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -946px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_infotip .navigator button.next { right: 0; }

.c_amazon_infotip .navigator button.next::before { margin: -6px 0 0 -3px; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -959px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_infotip .navigator button.next:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -985px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_infotip .navigator button:disabled.previous::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -933px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_infotip .navigator button:disabled.next::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -972px -25px; width: 8px; height: 11px; background-size: 1191px 201px; }

.c_amazon_infotip .navigator button:disabled:hover { box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); }

.c_amazon_infotip .navigator button:hover { box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_amazon_picklist { zoom: 1; margin: 0 0 25px 0; }

.c_amazon_picklist:after { display: block; content: ""; clear: both; }

.c_amazon_picklist li { float: left; width: 302px; margin: 0 0 0 11px; box-shadow: none; }

.c_amazon_picklist li:first-child { margin-left: 0; }

.c_amazon_picklist li:last-child { margin-left: 10px; }

.c_amazon_picklist li.active > .c-card-item { border: 1px solid #111; }

.c_amazon_picklist li.active > .c-card-item .c-card-item__thumb img { transform: scale(1.08); }

.c_amazon_picklist li.dummy > .c-card-item { border: 1px solid #eee; }

.c_amazon_picklist li .recommnedlist { display: none; position: absolute; left: 0; top: 140px; width: 1240px; }

.c_amazon_picklist li > .c-card-item { border: 1px solid #eee; border-radius: 55px; height: 110px; }

.c_amazon_picklist li > .c-card-item a { display: block; height: 100%; padding: 15px 0 0 15px; box-sizing: border-box; }

.c_amazon_picklist li > .c-card-item a:hover { text-decoration: none; }

.c_amazon_picklist li > .c-card-item .c-card-item__info { margin: 0 0 0 95px; }

.c_amazon_picklist li > .c-card-item .c-card-item__thumb { float: left; overflow: hidden; width: 80px; height: 80px; border-radius: 50%; }

.c_amazon_picklist li > .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_amazon_picklist li > .c-card-item .c-card-item__name { overflow: hidden; width: 150px; height: 3em; margin: 0 0 8px 0; }

.c_amazon_picklist li > .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 4px; }

.c_amazon_tab_tag { overflow: hidden; margin: 0 0 25px 0; height: 42px; }

.c_amazon_tab_tag li { margin: 0 0 0 10px; }

.c_amazon_tab_tag li:first-child { margin-left: 0; }

.c_amazon_tab_tag li button { overflow: hidden; display: inline-block; max-width: 220px; height: 40px; padding: 0 15px; line-height: 38px; vertical-align: top; border: 1px solid #ddd; border-radius: 20px; box-sizing: border-box; white-space: nowrap; text-overflow: ellipsis; font-size: 15px; font-weight: normal; color: #333; background: #fff; }

.c_amazon_tab_tag li button:hover { text-decoration: underline; }

.c_amazon_tab_tag li button[aria-selected="true"] { color: #49a3c7; border-color: rgba(73, 163, 199, 0.4); font-weight: bold; }

.c_amazon_theme_text { position: relative; margin: 40px 0 24px 0; }

.c_amazon_theme_text .text { position: relative; display: inline-block; color: #0b83e6; font-size: 20px; }

.c_amazon_theme_text .text::before { position: absolute; left: 0; bottom: 0; width: 100%; height: 12px; background: rgba(11, 131, 230, 0.1); content: ""; }

.c_amazon_theme_text .c_more { position: absolute; right: 0; top: 8px; }

.c_amazon_card_mdhot_review { zoom: 1; }

.c_amazon_card_mdhot_review:after { display: block; content: ""; clear: both; }

.c_amazon_card_mdhot_review li { overflow: hidden; float: left; width: 394px; height: 388px; margin: 31px 0 0 29px; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); }

.c_amazon_card_mdhot_review li:nth-child(1), .c_amazon_card_mdhot_review li:nth-child(2), .c_amazon_card_mdhot_review li:nth-child(3) { margin-top: 0; }

.c_amazon_card_mdhot_review li:nth-child(3n-2) { margin-left: 0; }

.c_amazon_card_mdhot_review li:hover { box-shadow: 0px 6px 32px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.7); }

.c_amazon_card_mdhot_review li:hover .c-card-item__thumb-img img { transform: scale(1.08); }

.c_amazon_card_mdhot_review dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_amazon_card_mdhot_review a:hover { text-decoration: none; }

.c_amazon_card_mdhot_review .card_review_info { padding: 17px 0 0 0; }

.c_amazon_card_mdhot_review .c-card-item__thumb { position: relative; }

.c_amazon_card_mdhot_review .c-card-item__thumb .video { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -383px -25px; width: 60px; height: 60px; background-size: 1191px 201px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); overflow: hidden; line-height: 200px; z-index: 10; }

.c_amazon_card_mdhot_review .c-card-item__thumb .c-card-item__thumb-img { width: 394px; height: 197px; }

.c_amazon_card_mdhot_review .c-card-item__thumb img { transition: transform 0.2s ease-in; }

.c_amazon_card_mdhot_review .c-starrate { margin: 0 0 15px 0; text-align: center; }

.c_amazon_card_mdhot_review .c-starrate.c-starrate--amazon dt { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 18px; height: 18px; background-position: -197.66582px -312.16154px; background-size: 507px 501px; float: none; display: inline-block; margin: -3px 5px 0 0; vertical-align: middle; }

.c_amazon_card_mdhot_review .c-starrate dd { display: inline-block; }

.c_amazon_card_mdhot_review .c-starrate .c-starrate__sati { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 108px; height: 20.25px; background-position: -411.40253px -307.06667px; background-size: 666px 658px; float: none; display: inline-block; margin: 0 5px 0 0; vertical-align: top; }

.c_amazon_card_mdhot_review .c-starrate .c-starrate__sati .c-starrate__gauge { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 108px; height: 20.25px; background-position: -411.40253px -340.81026px; background-size: 666px 658px; }

.c_amazon_card_mdhot_review .c-starrate .c-starrate__review { float: none; display: inline-block; font-size: 16px; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: bold; color: #333; }

.c_amazon_card_mdhot_review .c-starrate .c-starrate__review .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_amazon_card_mdhot_review .c-card-item__name-addition { overflow: hidden; height: 22px; margin: 0 40px 10px 40px; color: #0b83e6; font-size: 15px; line-height: 22px; text-align: center; }

.c_amazon_card_mdhot_review .card_review_text { position: relative; overflow: hidden; height: 3em; margin: 0 0 7px 0; padding: 0 40px 0 40px; text-align: center; color: #333; font-size: 15px; line-height: 22px; }

.c_amazon_card_mdhot_review .card_review_text dd { position: relative; display: inline-block; max-width: 314px; }

.c_amazon_card_mdhot_review .card_review_text dd:before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -901px -25px; width: 14px; height: 14px; background-size: 1191px 201px; display: inline-block; vertical-align: top; margin: 0 8px 0 0; content: ""; }

.c_amazon_card_mdhot_review .card_review_number { text-align: center; font-size: 15px; color: #999; }

.c_amazon_tab_keyword { position: relative; margin: 0 0 20px 0; }

.c_amazon_tab_keyword .tab_inner { overflow: hidden; height: 50px; }

.c_amazon_tab_keyword ul { margin-top: -10px; transition: all 0.3s ease; }

.c_amazon_tab_keyword li { padding: 10px 10px 0 0; }

.c_amazon_tab_keyword li button { overflow: hidden; display: inline-block; max-width: 220px; height: 40px; padding: 0 15px; line-height: 38px; vertical-align: top; border: 1px solid #ddd; border-radius: 25px; box-sizing: border-box; font-size: 15px; color: #333; background: #fff; font-weight: normal; }

.c_amazon_tab_keyword li button[aria-selected="true"] { color: #fff; background: #49a3c7; border-color: #49a3c7; font-weight: bold; }

.c_amazon_tab_keyword .tab_navigator button { margin-top: -30px; }

.c_amazon_tab_sort { float: left; margin: -1px 0 0 20px; height: 40px; border-radius: 20px; background: #f4f4f4; box-shadow: inset 0 0 0 1px #eee; }

.c_amazon_tab_sort li { float: left; margin: 0 0 0 -10px; }

.c_amazon_tab_sort li:first-child { margin-left: 0; }

.c_amazon_tab_sort li:first-child button { left: 0; margin-left: 0; }

.c_amazon_tab_sort button { position: relative; height: 40px; padding: 0 24px; vertical-align: top; border-radius: 20px; }

.c_amazon_tab_sort button[aria-selected="true"] { background: #49a3c7; color: #fff; }

.c_amazon_main_trendgender { zoom: 1; }

.c_amazon_main_trendgender:after { display: block; content: ""; clear: both; }

.c_amazon_main_trendgender > ul > li { float: left; overflow: hidden; width: 394px; margin: 30px 0 0 29px; border-radius: 4px; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); }

.c_amazon_main_trendgender > ul > li:nth-child(3n-2) { margin-left: 0; }

.c_amazon_main_trendgender > ul > li:nth-child(1), .c_amazon_main_trendgender > ul > li:nth-child(2), .c_amazon_main_trendgender > ul > li:nth-child(3) { margin-top: 0; }

.c_amazon_main_trendgender .card_trend_thumb img { width: 394px; height: 168px; }

.c_amazon_main_trendgender .c_amazon_card_row { height: 810px; }

.c_amazon_main_trendgender .card_trend_more { display: block; position: relative; margin: -1px 0 0 0; border-top: 1px solid #f4f4f4; height: 58px; line-height: 58px; text-align: center; font-size: 15px; }

.c_amazon_main_trendgender .card_trend_more .point { color: #0b83e6; }

.c_amazon_main_trendgender .card_trend_more .c_more { padding: 0 30px 0 0; }

.c_amazon_main_trendgender .card_trend_more .c_more:before { position: absolute; top: 50%; right: -2px; width: 22px; height: 22px; margin: -11px 0 0 0; border: 1px solid #ddd; border-radius: 100%; content: ""; }

.c_amazon_main_trendgender .card_trend_more .c_more:after { right: 7px; width: 6px; height: 6px; border-color: #0b83e6; }

.c_amazon_main_banner { text-align: center; }

.c_amazon_main_banner img { height: 100px; vertical-align: top; }

.c_amazon_card_more { position: relative; margin: 30px 0 0 0; text-align: center; }

.c_amazon_card_more:after { position: absolute; left: 0; top: 50%; width: 100%; height: 1px; background: #eee; content: ""; }

.c_amazon_card_more .c_more { height: 56px; padding: 0 50px 0 30px; line-height: 54px; border: 1px solid #ccc; font-size: 17px; border-radius: 28px; color: #111; background: #fff; z-index: 10; }

.c_amazon_card_more .c_more:after { right: 35px; margin-top: -5px; width: 8px; height: 8px; border: 1px solid #111; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(45deg); }

.c_amazon_card_more .c_more.c_more_down:after { right: 28px; margin-top: -7px; width: 8px; height: 8px; border: 1px solid #111; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(135deg); }

.c_amazon_card_more .c_more:hover { text-decoration: none; }

.c_amazon_card_more .c_more .point { color: #ff0038; }

.c_amazon_card_more .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_brand { position: relative; width: 1240px; margin: 30px 0 0 0; }

.c_amazon_brand:first-child { margin-top: 0; }

.c_amazon_brand .brand_visual { width: 1240px; height: 446px; background: #999; }

.c_amazon_brand .brand_visual img { width: 100%; height: 100%; }

.c_amazon_brand .brand_more { position: absolute; left: 149px; top: 274px; padding: 0 30px; height: 52px; line-height: 52px; border: 1px solid #fff; border-radius: 26px; box-sizing: border-box; color: #fff; font-size: 17px; font-weight: bold; }

.c_amazon_brand .brand_more:after { right: 20px; margin-top: -4px; width: 6px; height: 6px; border: 1px solid #fff; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(45deg); }

.c_amazon_brand .c_amazon_card_col_5 { position: absolute; left: 478px; top: 50px; }

.c_amazon_realtime_refresh { zoom: 1; position: relative; margin: 20px 0 0 0; }

.c_amazon_realtime_refresh:after { display: block; content: ""; clear: both; }

.c_amazon_realtime_refresh:before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -308px 0px; width: 883px; height: 20px; background-size: 1191px 201px; position: absolute; left: 132px; top: 13px; content: ""; }

.c_amazon_realtime_refresh .refresh_time { position: relative; float: right; height: 38px; margin: 0 39px 0 0; line-height: 36px; padding: 0 45px 0 20px; border: 1px solid #eee; border-radius: 19px; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05); background: #fff; color: #333; font-size: 15px; z-index: 10; }

.c_amazon_realtime_refresh .refresh_time .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_realtime_refresh .refresh_button { position: absolute; left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; line-height: 200px; }

.c_amazon_realtime_refresh .refresh_button::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -596px -100px; width: 24px; height: 24px; background-size: 1191px 201px; position: absolute; right: 20px; top: 6px; content: ""; }

.c_amazon_hotbrand { zoom: 1; }

.c_amazon_hotbrand:after { display: block; content: ""; clear: both; }

.c_amazon_hotbrand > ul > li { float: left; overflow: hidden; width: 394px; margin: 29px 0 0 29px; border-radius: 4px; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); }

.c_amazon_hotbrand > ul > li:nth-child(1), .c_amazon_hotbrand > ul > li:nth-child(2), .c_amazon_hotbrand > ul > li:nth-child(3) { margin-top: 0; }

.c_amazon_hotbrand > ul > li:nth-child(3n-2) { margin-left: 0; }

.c_amazon_hotbrand > ul > li > a { display: block; }

.c_amazon_hotbrand > ul > li > a:hover { text-decoration: none; }

.c_amazon_hotbrand .c_amazon_card_row { height: 705px; }

.c_amazon_hotbrand .c_amazon_card_row li { height: 235px; }

.c_amazon_hotbrand .card_brand_thumb img { width: 394px; height: 197px; }

.c_amazon_hotbrand .card_brand_description { position: relative; z-index: 10; margin: -50px 20px 0 20px; border-radius: 4px; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); background-color: #fff; }

.c_amazon_hotbrand .card_brand_description .text { overflow: hidden; height: 4.5em; margin: 0 20px 13px 20px; padding: 16px 0 0 0; color: #666; font-size: 15px; }

.c_amazon_hotbrand .card_brand_more { display: block; border-top: 1px solid #f4f4f4; height: 48px; line-height: 48px; text-align: center; font-size: 15px; color: #111; }

.c_amazon_hotbrand .card_brand_more .point { color: #0b83e6; }

.c_amazon_hotbrand .card_brand_more .c_more:after { width: 6px; height: 6px; border-color: #111; }

.c_amazon_tab_metacategory { zoom: 1; position: relative; margin: 0 0 25px 0; }

.c_amazon_tab_metacategory:after { display: block; content: ""; clear: both; }

.c_amazon_tab_metacategory .tab_inner { position: relative; width: 1240px; margin: 0 auto; box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) inset; background: #fff; }

.c_amazon_tab_metacategory li { position: relative; float: left; width: 103px; height: 90px; }

.c_amazon_tab_metacategory li.all { width: 105px; font-weight: bold; }

.c_amazon_tab_metacategory li.all button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -45px 0px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.brand button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: 0px -90px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.trend button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -90px -225px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.beauty button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -90px 0px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.food button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: 0px -135px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.sports button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -225px -45px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.baby button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: 0px -45px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.interior button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: 0px -180px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.life button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -90px -180px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.digital button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -135px -45px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.health button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -180px 0px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.hobby button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -180px -90px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.car button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -90px -90px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.gift button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -90px -135px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.overseas button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -180px -180px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.tour button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: 0px -225px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.ticket button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -225px -135px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active button { color: #fff; }

.c_amazon_tab_metacategory li.active.all { background: #ff0038; }

.c_amazon_tab_metacategory li.active.all button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -180px -45px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.brand { background: #fe6d01; }

.c_amazon_tab_metacategory li.active.brand button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -45px -90px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.trend { background: #ffa705; }

.c_amazon_tab_metacategory li.active.trend button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -135px -225px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.beauty { background: #689e36; }

.c_amazon_tab_metacategory li.active.beauty button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -90px -45px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.food { background: #388e3c; }

.c_amazon_tab_metacategory li.active.food button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -45px -135px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.sports { background: #0e997b; }

.c_amazon_tab_metacategory li.active.sports button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -225px -90px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.baby { background: #00887a; }

.c_amazon_tab_metacategory li.active.baby button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -45px -45px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.interior { background: #02acc0; }

.c_amazon_tab_metacategory li.active.interior button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -45px -180px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.life { background: #0288cf; }

.c_amazon_tab_metacategory li.active.life button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -135px -180px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.digital { background: #0d47a1; }

.c_amazon_tab_metacategory li.active.digital button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -135px -90px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.health { background: #311b91; }

.c_amazon_tab_metacategory li.active.health button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: 0px 0px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.hobby { background: #5e34b1; }

.c_amazon_tab_metacategory li.active.hobby button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -180px -135px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.car { background: #7c1da2; }

.c_amazon_tab_metacategory li.active.car button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -135px 0px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.gift { background: #4a148c; }

.c_amazon_tab_metacategory li.active.gift button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -135px -135px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.overseas { background: #d71a60; }

.c_amazon_tab_metacategory li.active.overseas button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -225px 0px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.tour { background: #870f50; }

.c_amazon_tab_metacategory li.active.tour button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -45px -225px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory li.active.ticket { background: #3e2722; }

.c_amazon_tab_metacategory li.active.ticket button::before { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: -225px -180px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory button { display: block; width: 100%; height: 100%; padding: 43px 0 0 0; text-align: center; box-sizing: border-box; }

.c_amazon_tab_metacategory button::before { position: absolute; left: 50%; top: 11px; margin-left: -20px; content: ""; }

.c_amazon_tab_metacategory button.all { background: #ff0038; }

.c_amazon_tab_metacategory button.brand { background-image: url("/img/amazon/sprites/sp_mdtab_2x_2021210_104347.png"); background-position: 0px -90px; width: 40px; height: 40px; background-size: 265px 265px; }

.c_amazon_tab_metacategory button.trend { background: #ffa705; }

.c_amazon_tab_metacategory button.beauty { background: #689e36; }

.c_amazon_tab_metacategory button.food { background: #388e3c; }

.c_amazon_tab_metacategory button.sports { background: #0e997b; }

.c_amazon_tab_metacategory button.baby { background: #00887a; }

.c_amazon_tab_metacategory button.interior { background: #02acc0; }

.c_amazon_tab_metacategory button.life { background: #0288cf; }

.c_amazon_tab_metacategory button.digital { background: #0d47a1; }

.c_amazon_tab_metacategory button.health { background: #311b91; }

.c_amazon_tab_metacategory button.hobby { background: #5e34b1; }

.c_amazon_tab_metacategory button.car { background: #7c1da2; }

.c_amazon_tab_metacategory button.gift { background: #4a148c; }

.c_amazon_tab_metacategory button.overseas { background: #d71a60; }

.c_amazon_tab_metacategory button.tour { background: #870f50; }

.c_amazon_tab_metacategory button.ticket { background: #3e2722; }

.c_amazon_tab_metacategory .icon { position: absolute; left: 50%; top: 11px; width: 40px; height: 40px; margin-left: -20px; background-size: 40px 40px; }

.c_pagination.c_pagination_style_1 { margin-top: 30px; }

.c_amazon_title_subtop { zoom: 1; position: relative; padding: 55px 0 22px 0; }

.c_amazon_title_subtop:after { display: block; content: ""; clear: both; }

.c_amazon_title_subtop .title { float: left; font-size: 28px; color: #111; font-weight: bold; }

.c_amazon_title_subtop .description { float: left; margin: 9px 0 0 10px; font-size: 17px; color: #666; }

.c_amazon_title_subtop .c_amazon_refresh { position: absolute; right: 0; top: 55px; }

.c_amazon_title_subtop .point { color: #0b83e6; }

.c_amazon_title_subtop .c_more { position: absolute; right: 0; top: 63px; }

.c_amazon_title_subtop + .c_amazon_theme_text { margin-top: -5px; }

.c_amazon_shoppingtip_board { border-top: 1px solid #eee; }

.c_amazon_shoppingtip_board dt { position: relative; display: block; padding: 27px 140px 28px 100px; font-size: 17px; letter-spacing: -0.5px; border-bottom: 1px solid #eee; color: #111; }

.c_amazon_shoppingtip_board .tip_category { position: absolute; left: 0; top: 25px; overflow: hidden; max-width: 98px; height: 30px; line-height: 28px; padding: 0 12px; border: 1px solid rgba(51, 51, 51, 0.2); border-radius: 15px; box-sizing: border-box; font-size: 13px; letter-spacing: -0.5px; font-weight: bold; color: #111; }

.c_amazon_shoppingtip_board .tip_category.tip_category_point { color: #ff0038; letter-spacing: 0; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; border: solid 1px rgba(244, 49, 66, 0.2); }

.c_amazon_shoppingtip_board .tip_date { position: absolute; right: 63px; top: 29px; font-size: 15px; color: #999; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_shoppingtip_board .tip_new { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -576px -50px; width: 20px; height: 20px; background-size: 1191px 201px; display: inline-block; margin: -3px 0 0 8px; overflow: hidden; line-height: 200px; vertical-align: middle; }

.c_amazon_shoppingtip_board .tip_button { position: absolute; left: 0; right: 0; bottom: 0; width: 100%; height: 100%; cursor: pointer; overflow: hidden; line-height: 500px; }

.c_amazon_shoppingtip_board .tip_button::before { position: absolute; right: 15px; top: 26px; width: 28px; height: 28px; border-radius: 50%; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.4); content: ""; }

.c_amazon_shoppingtip_board .tip_button::after { position: absolute; right: 25px; top: 35px; width: 5px; height: 5px; border: 1px solid #111; border-width: 2px 2px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(135deg); }

.c_amazon_shoppingtip_board dd { display: none; padding: 50px 50px 50px 100px; font-size: 15px; line-height: 24px; letter-spacing: -0.5px; color: #333; background: #fafafa; border-bottom: 1px solid #eee; }

.c_amazon_shoppingtip_board .active dd { display: block; }

.c_amazon_shoppingtip_board .active .tip_button::after { top: 38px; width: 5px; height: 5px; border: 1px solid #111; border-width: 2px 2px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(-45deg); }

.c_amazon_sort { zoom: 1; position: relative; min-height: 29px; margin: 0 0 29px 0; z-index: 30; }

.c_amazon_sort:after { display: block; content: ""; clear: both; }

.c_amazon_sort .total { float: left; margin: 5px 0 0 0; font-size: 16px; color: #333; }

.c_amazon_sort .c_amazon_refresh { float: left; }

.c_amazon_sort .sort { position: absolute; right: 0; top: -2px; }

.c_amazon_sort .c_amazon_selectbox { float: left; margin: 0 0 0 10px; }

.c_amazon_sort.c_amazon_sort_line { border-top: 1px solid #eee; padding-top: 20px; margin-bottom: 20px; }

.c_amazon_sort.c_amazon_sort_line .sort { top: 18px; }

.c_amazon_tab_category { margin: 0 0 22px 0; }

.c_amazon_tab_category .tab_inner { position: relative; overflow: visible; width: 1240px; margin: 0 auto; box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) inset; background: #fff; }

.c_amazon_tab_category.c_amazon_tab_category_fixed { position: fixed; top: 139px; left: 0; right: 0; z-index: 1000; }

.c_amazon_tab_category li { float: left; width: 103px; height: 90px; }

.c_amazon_tab_category li button[aria-selected="true"] { font-weight: bold; color: #ff0038; }

.c_amazon_tab_category li button[aria-selected="true"] .text:before { position: absolute; left: 0; right: 0; bottom: -1px; height: 2px; background: #ff0038; content: ""; }

.c_amazon_tab_category li button { display: block; width: 100%; height: 100%; text-align: center; box-sizing: border-box; cursor: pointer; font-weight: normal; color: #666; }

.c_amazon_tab_category li button .in_btn { display: block; position: relative; width: 100%; height: 100%; }

.c_amazon_tab_category li button .icon { position: absolute; left: 50%; top: 11px; width: 40px; height: 40px; margin-left: -20px; }

.c_amazon_tab_category li button .icon > img { width: 40px; height: 40px; }

.c_amazon_tab_category li button .text { position: relative; display: inline-block; padding: 57px 0 11px 0; }

.c_amazon_tab_category li button:hover { text-decoration: underline; }

.c_amazon_selectbox.active:after { border-color: #111; }

.c_amazon_selectbox:after { border-color: #111; }

.c_amazon_selectbox .selectbox_selected { min-width: 130px; padding-left: 12px; font-size: 15px; height: 40px; line-height: 38px; border: 1px solid #ccc; }

.c_amazon_selectbox .selectbox_text { font-weight: normal; }

.c_amazon_selectbox .selectbox_button:after { right: 13px; border-color: #111; }

.c_amazon_selectbox .selectbox_option { top: 39px; border: 1px solid #ccc; }

.c_amazon_selectbox .selectbox_option .option { font-size: 15px; padding: 6px 10px; }

.c_amazon_refresh { position: relative; display: inline-block; height: 38px; line-height: 36px; padding: 0 45px 0 20px; border-radius: 20px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); background: #fff; color: #333; font-size: 15px; z-index: 10; }

.c_amazon_refresh .date { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_refresh .refresh_button { position: absolute; left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; line-height: 200px; }

.c_amazon_refresh .refresh_button::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -596px -100px; width: 24px; height: 24px; background-size: 1191px 201px; position: absolute; top: 6px; right: 20px; content: ""; }

.c_amazon_subtitle { zoom: 1; position: relative; padding: 0 0 20px 0; z-index: 50; }

.c_amazon_subtitle:after { display: block; content: ""; clear: both; }

.c_amazon_subtitle.c_amazon_subtitle_line { border-bottom: 1px solid #eee; }

.c_amazon_subtitle + .c_amazon_categorytitle { margin-top: 20px; }

.c_amazon_subtitle .title { float: left; font-size: 20px; font-weight: bold; color: #111; white-space: nowrap; }

.c_amazon_subtitle .total { float: left; margin: 7px 0 0 23px; font-size: 16px; line-height: 16px; color: #333; }

.c_amazon_subtitle .total .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_subtitle .c_amazon_selectbox { position: absolute; right: 0; top: -10px; }

.c_amazon_subtitle .c_more { position: absolute; right: 0; top: 4px; font-size: 15px; }

.c_amazon_subtitle .c_amazon_tab_line { position: absolute; right: -15px; top: 6px; }

.c_amazon_subtitle .c_layer_item { top: 40px; left: 0; width: 274px; padding: 20px 44px 20px 20px; font-size: 15px; color: #111; }

.c_amazon_categorytitle { zoom: 1; position: relative; margin: 48px 0 20px 0; }

.c_amazon_categorytitle:after { display: block; content: ""; clear: both; }

.c_amazon_categorytitle .title { float: left; font-size: 20px; font-weight: bold; color: #111; }

.c_amazon_categorytitle .title .point { color: #0b83e6; }

.c_amazon_categorytitle .c_more { position: absolute; right: 0; top: 4px; color: #111; font-size: 14px; }

.c_amazon_categorytitle .c_more:after { border-color: #111; }

.b_amazon_brandlogo { margin: 60px 0 0 0; }

.c_amazon_brandlogo ul { zoom: 1; }

.c_amazon_brandlogo ul:after { display: block; content: ""; clear: both; }

.c_amazon_brandlogo li { float: left; width: 224px; height: 170px; margin: 30px 0 0 30px; text-align: center; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); background: #fff; }

.c_amazon_brandlogo li:nth-child(1), .c_amazon_brandlogo li:nth-child(2), .c_amazon_brandlogo li:nth-child(3), .c_amazon_brandlogo li:nth-child(4), .c_amazon_brandlogo li:nth-child(5) { margin-top: 0; }

.c_amazon_brandlogo li:nth-child(5n + 1) { margin-left: 0; }

.c_amazon_brandlogo a { display: block; height: 100%; }

.c_amazon_brandlogo a:hover { text-decoration: none; }

.c_amazon_brandlogo .brandlist_logo { display: block; overflow: hidden; width: 160px; height: 60px; margin: 35px auto 18px; }

.c_amazon_brandlogo .brandlist_logo img { width: 100%; height: 100%; vertical-align: top; }

.c_amazon_brandlogo .brandlist_text { display: inline-block; overflow: hidden; max-width: 160px; height: 25px; font-weight: bold; font-size: 17px; color: #111; text-overflow: ellipsis; white-space: nowrap; }

.c_amazon_brandlogo .brandlist_new { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -576px -50px; width: 20px; height: 20px; background-size: 1191px 201px; display: inline-block; margin: 3px 0 0 4px; overflow: hidden; line-height: 200px; vertical-align: top; }

.c_amazon_brand_intro { zoom: 1; display: table; }

.c_amazon_brand_intro:after { display: block; content: ""; clear: both; }

.c_amazon_brand_intro .intro_visual { display: table-cell; vertical-align: middle; }

.c_amazon_brand_intro .intro_thumbnail { overflow: hidden; width: 600px; height: 240px; vertical-align: top; }

.c_amazon_brand_intro .intro_thumbnail img { width: 100%; }

.c_amazon_brand_intro .intro_description { display: table-cell; width: 640px; padding: 0 60px; box-sizing: border-box; vertical-align: middle; }

.c_amazon_brand_intro .intro_description p { overflow: hidden; max-height: 140px; color: #fff; font-size: 17px; line-height: 28px; }

.c_amazon_brand_intro.c_amazon_brand_intro_logo { display: block; padding: 25px 0 27px 0; }

.c_amazon_brand_intro.c_amazon_brand_intro_logo .intro_visual { display: block; }

.c_amazon_brand_intro.c_amazon_brand_intro_logo .intro_thumbnail { width: 100%; height: auto; text-align: center; }

.c_amazon_brand_intro.c_amazon_brand_intro_logo .intro_thumbnail img { width: 200px; height: 75px; }

.c_amazon_brand_intro.c_amazon_brand_intro_logo .intro_description { display: block; width: auto; padding: 5px 50px 0 50px; }

.c_amazon_brand_intro.c_amazon_brand_intro_logo .intro_description p { max-height: 28px; font-size: 20px; text-align: center; color: #111; }

.c_amazon_brand_banner { text-align: center; border-radius: 4px; overflow: hidden; }

.c_amazon_brand_banner img { max-width: 100%; height: 100px; }

.l_amazon_category { zoom: 1; width: 1240px; margin: 0 auto; }

.l_amazon_category:after { display: block; content: ""; clear: both; }

.l_amazon_category .l_amazon { width: auto; }

.l_amazon_category .c_amazon_sort { margin-bottom: 42px; }

.l_amazon_category_side { float: left; width: 224px; }

.l_amazon_category_content { float: left; width: 1016px; padding: 0 0 0 28px; box-sizing: border-box; }

.c_amazon_category_title { position: relative; height: 100px; }

.c_amazon_category_title.title_expand .inner { width: 900px; }

.c_amazon_category_title .inner { zoom: 1; position: absolute; left: 0; top: 0; padding: 37px 0 0 0; }

.c_amazon_category_title .inner:after { display: block; content: ""; clear: both; }

.c_amazon_category_title .title { float: left; font-size: 20px; color: #111; }

.c_amazon_category_title .total { float: left; margin: 2px 0 0 10px; font-size: 16px; }

.c_amazon_category_title .point { color: #ff0038; }

.c_amazon_category_dropdown { position: relative; padding: 15px 0; border-top: 1px solid #eee; }

.c_amazon_category_dropdown.active .dropdown_button::before { top: 10px; width: 7px; height: 7px; border: 1px solid #111; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(-45deg); }

.c_amazon_category_dropdown.active .dropdown_content { display: block; }

.c_amazon_category_dropdown .dropdown_subject { position: relative; font-size: 14px; line-height: 24px; color: #111; }

.c_amazon_category_dropdown .dropdown_content { display: none; padding: 0 0 8px 0; }

.c_amazon_category_dropdown .category_menu { margin: 12px 0 0 0; }

.c_amazon_category_dropdown .category_menu > li { margin: 5px 0 0 0; }

.c_amazon_category_dropdown .category_menu > li:first-child { margin-top: 0; }

.c_amazon_category_dropdown .category_menu .c_radiobox { display: inline-block; vertical-align: top; cursor: pointer; }

.c_amazon_category_dropdown .category_menu .c_radiobox .text { display: inline-block; font-size: 14px; color: #333; height: 23px; line-height: 23px; padding-left: 0; vertical-align: top; }

.c_amazon_category_dropdown .category_menu .c_radiobox .text:before { display: none; }

.c_amazon_category_dropdown .category_menu .c_radiobox .count { margin: 0 0 0 4px; font-size: 13px; color: #aaa; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_category_dropdown .category_menu .c_radiobox input:checked + .text { color: #ff0038; font-weight: bold; }

.c_amazon_category_dropdown .category_menu .menu_2depth { margin: 5px 0 0 15px; }

.c_amazon_category_dropdown .category_menu .menu_2depth > ul > li { margin: 5px 0 0 0; }

.c_amazon_category_dropdown .category_menu .menu_2depth > ul > li:first-child { margin-top: 0; }

.c_amazon_category_dropdown .category_menu .menu_2depth > ul > li .c_radiobox { max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: left; }

.c_amazon_category_dropdown .category_menu .menu_2depth > ul > li .c_radiobox .text { display: inline; }

.c_amazon_category_dropdown .category_menu .menu_2depth > ul > li .c_radiobox:hover { text-decoration: none; }

.c_amazon_category_dropdown .category_menu .menu_2depth > ul > li .c_radiobox:hover .text { text-decoration: underline; }

.c_amazon_category_dropdown .category_menu .menu_2depth > ul > li.menu_on .menu_3depth { display: block; }

.c_amazon_category_dropdown .category_menu .menu_3depth { margin: 5px 0 0 12px; }

.c_amazon_category_dropdown .category_menu .menu_3depth > ul > li { margin: 5px 0 0 0; }

.c_amazon_category_dropdown .category_menu .menu_3depth > ul > li:first-child { margin-top: 0; }

.c_amazon_category_dropdown .category_menu .menu_3depth > ul > li .c_radiobox { max-width: 190px; }

.c_amazon_category_dropdown .c_more { display: inline-block; margin: 12px 0 0 0; font-size: 12px; color: #999; }

.c_amazon_category_dropdown .c_more:after { border-color: #999; }

.c_amazon_category_dropdown .dropdown_button { position: absolute; right: 0; top: 0; width: 100%; height: 100%; overflow: hidden; line-height: 200px; }

.c_amazon_category_dropdown .dropdown_button::before { position: absolute; right: 8px; top: 4px; width: 8px; height: 8px; border: 1px solid #111; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(135deg); }

.c_amazon_category_dropdown .choice_menu { zoom: 1; margin: 7px 0 0 0; }

.c_amazon_category_dropdown .choice_menu:after { display: block; content: ""; clear: both; }

.c_amazon_category_dropdown .choice_menu li { margin: 7px 0 0 0; }

.c_amazon_category_dropdown .choice_menu li:first-child { margin-top: 0; }

.c_amazon_category_dropdown .choice_menu .c_radiobox > span { font-size: 13px; color: #222; }

.c_amazon_category_dropdown .choice_menu.col li { float: left; margin: 0 15px 0 0; }

.c_amazon_category_dropdown .choice_menu_no { padding: 9px 0 0 0; font-size: 13px; color: #222; }

.c_amazon_category_dropdown .choice_price { zoom: 1; margin: 10px 0 0 0; }

.c_amazon_category_dropdown .choice_price:after { display: block; content: ""; clear: both; }

.c_amazon_category_dropdown .choice_price .c_input_item { float: left; width: 90px; height: 30px; padding: 0 10px; line-height: 28px; font-size: 12px; border: 1px solid #ddd; outline: none; }

.c_amazon_category_dropdown .choice_price .c_input_item:focus { border: 1px solid #ff0038; }

.c_amazon_category_dropdown .choice_price .symbol { float: left; width: 14px; color: #222; text-align: center; line-height: 30px; }

.c_amazon_category_dropdown .choice_price .choice_price_button { float: left; position: relative; width: 30px; height: 30px; margin: 0 0 0 0; border: 1px solid #ddd; border-left: none; overflow: hidden; line-height: 200px; }

.c_amazon_category_dropdown .choice_price .choice_price_button:after { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -654px -100px; width: 24px; height: 24px; background-size: 1191px 201px; position: absolute; left: 50%; top: 50%; margin: -12px 0 0 -11px; content: ""; }

.c_amazon_category_dropdown .choice_price .choice_price_button:focus, .c_amazon_category_dropdown .choice_price .choice_price_button.active { border: 1px solid #ff0038; background-color: #ff0038; margin-left: -2px; }

.c_amazon_category_dropdown .choice_price .choice_price_button:focus:after, .c_amazon_category_dropdown .choice_price .choice_price_button.active:after { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -683px -100px; width: 24px; height: 24px; background-size: 1191px 201px; }

.c_amazon_category_dropdown .content_research { margin: 20px 0 0 0; }

.c_amazon_category_dropdown .content_research .c_search_amazon_content { height: 30px; border: none; }

.c_amazon_category_dropdown .content_research .c_search_amazon_content .search_text { height: 100%; padding: 0 30px 0 10px; font-size: 12px; outline: 0; border: 1px solid #ddd; }

.c_amazon_category_dropdown .content_research .c_search_amazon_content .search_text:focus { border: 1px solid #ff0038; }

.c_amazon_category_dropdown .content_research .c_search_amazon_content .search_button { position: absolute; right: 0px; top: 0px; width: 30px; height: 30px; overflow: hidden; line-height: 200px; }

.c_amazon_category_dropdown .content_research .c_search_amazon_content .search_button:after { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -654px -100px; width: 24px; height: 24px; background-size: 1191px 201px; position: absolute; left: 50%; top: 50%; margin: -12px 0 0 -12px; content: ""; }

.c_amazon_category_dropdown .content_research .c_search_amazon_content .search_button:focus, .c_amazon_category_dropdown .content_research .c_search_amazon_content .search_button.active { background-color: #ff0038; }

.c_amazon_category_dropdown .content_research .c_search_amazon_content .search_button:focus:after, .c_amazon_category_dropdown .content_research .c_search_amazon_content .search_button.active:after { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -683px -100px; width: 24px; height: 24px; background-size: 1191px 201px; }

.c_amazon_category_dropdown .c_checkbox { cursor: pointer; }

.c_amazon_category_dropdown .c_checkbox > span:nth-of-type(1) { overflow: hidden; max-width: 201px; height: 18px; padding-left: 23px; font-size: 13px; line-height: 18px; text-overflow: ellipsis; white-space: nowrap; }

.c_amazon_category_dropdown .c_checkbox > span:nth-of-type(1):before { width: 18px; height: 18px; }

.c_amazon_category_dropdown .c_checkbox > span:nth-of-type(1):after { width: 8px; height: 4px; }

.c_amazon_category_dropdown .c_radiobox { cursor: pointer; }

.c_amazon_category_dropdown .c_radiobox > span:nth-of-type(1) { font-size: 13px; line-height: 18px; height: 18px; padding-left: 23px; }

.c_amazon_category_dropdown .c_radiobox > span:nth-of-type(1):before { width: 18px; height: 18px; }

.c_amazon_layer_category { display: none; position: absolute; top: 48px; z-index: 50; width: 862px; padding: 0; background-color: #fff; border: solid 1px #eee; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

.c_amazon_layer_category .layer_title { border-bottom: 1px solid #eee; }

.c_amazon_layer_category .layer_title .title { padding: 30px 70px 29px; text-align: center; color: #111; font-size: 20px; }

.c_amazon_layer_category .layer_close { position: absolute; top: 38px; right: 32px; margin-top: -16px; width: 32px; height: 32px; overflow: hidden; line-height: 200px; }

.c_amazon_layer_category .layer_close:before, .c_amazon_layer_category .layer_close:after { position: absolute; top: 50%; left: 50%; width: 24px; height: 1px; background-color: #999; content: ""; }

.c_amazon_layer_category .layer_close:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.c_amazon_layer_category .layer_close:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.c_amazon_layer_category .layer_content { padding: 30px; box-sizing: border-box; overflow-y: auto; min-height: 190px; max-height: 460px; }

.c_amazon_layer_category .layer_bottom { padding: 15px 0; text-align: center; box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1); }

.c_amazon_layer_category .button_confirm { min-width: 99px; height: 42px; line-height: 40px; font-weight: bold; font-size: 15px; background-color: #ff0038; background-image: linear-gradient(to right, #fc422f, #fc144e); border-color: #ff0038; color: #fff; }

.c_amazon_layer_category .category_list { zoom: 1; margin-top: -9px; }

.c_amazon_layer_category .category_list:after { display: block; content: ""; clear: both; }

.c_amazon_layer_category .category_list > li { position: relative; float: left; width: 25%; padding: 9px 20px 9px 0; box-sizing: border-box; }

.c_amazon_layer_category .category_list > li:nth-child(4n-3) { clear: both; }

.c_amazon_layer_category .category_list .c_radiobox { cursor: pointer; }

.c_amazon_layer_category .category_list .c_radiobox > span:nth-of-type(1) { font-size: 14px; height: auto; color: #222; vertical-align: top; }

.c_amazon_layer_category .category_list .c_radiobox .text { overflow: hidden; max-width: 140px; white-space: nowrap; text-overflow: ellipsis; padding-left: 0; color: #222; }

.c_amazon_layer_category .category_list .c_radiobox .text:before { display: none; }

.c_amazon_layer_category .category_list .c_radiobox .count { display: inline-block; color: #aaa; vertical-align: middle; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 11px; }

.c_amazon_layer_category .category_list .c_radiobox:hover .text, .c_amazon_layer_category .category_list .c_radiobox:hover .count { text-decoration: underline; }

.c_amazon_layer_category .category_list .c_checkbox > span:nth-of-type(1) { max-width: 157px; }

.s_amazon_category .c_list_card_deal { width: auto; }

.s_amazon_category .c_list_card_deal .c-card-item--deal { width: 478px; }

.s_amazon_category .c_list_card_deal .c-card-item--deal .c-card-item__thumb-img { height: 239px; }

.s_amazon_category .c_list_card_gallery { width: auto; }

.s_amazon_category .c_list_card_gallery.col_4 li { flex: 0 1 224px; width: 224px; }

.s_amazon_category .c_list_card_gallery.col_4 .c-card-item { width: 224px; }

.s_amazon_category .c_list_card_gallery.col_4 .c-card-item .c-card-item__thumb-img { width: 204px; height: 204px; }

.s_amazon_category .c_list_card_gallery.col_4 .c-card-item .c-card-item__soldout-text { height: 204px; }

.s_amazon_category .c_list_card_gallery.col_4 .c-card-item .c-card-item__lowest-value { clear: left; }

.l_amazon_category .c_amazon_card_flex.col4 li { flex: 0 1 224px; width: 224px; }

.l_amazon_category .c_amazon_card_flex.col4 .c-card-item { width: 224px; }

.l_amazon_category .c_amazon_card_flex.col4 .c-card-item .c-card-item__thumb-img { width: 204px; height: 204px; }

.c_amazon_tab_category_sub { position: relative; margin: -22px 0 30px 0; padding: 10px 40px 22px 40px; border: 1px solid #f0f0f0; border-top: none; }

.c_amazon_tab_category_sub ul { zoom: 1; }

.c_amazon_tab_category_sub ul:after { display: block; content: ""; clear: both; }

.c_amazon_tab_category_sub li { float: left; width: 165px; margin: 12px 0 0 0; padding: 0 5px 0 0; box-sizing: border-box; }

.c_amazon_tab_category_sub button[aria-selected="true"] { color: #ff0038; font-weight: bold; }

.c_amazon_tab_line { display: inline-block; margin: 0 0 0 20px; }

.c_amazon_tab_line li > button { padding: 0 18px; line-height: 22px; font-size: 18px; background-color: #fff; font-weight: normal; font-family: "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_tab_line li > button::before { position: absolute; left: 0; top: 2px; width: 1px; height: 16px; background: #eee; content: ""; }

.c_amazon_tab_line li > button[aria-selected="true"] { color: #ff0038; font-weight: bold; }

.c_amazon_tab_line li:first-child button::before { display: none; }

.c_amazon_help { float: left; position: relative; width: 20px; height: 20px; margin: 6px 0 0 2px; overflow: hidden; line-height: 200px; }

.c_amazon_help::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -863px -25px; width: 14px; height: 14px; background-size: 1191px 201px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); content: ""; }

.c_amazon_search_result { clear: both; padding: 88px 0; }

.c_amazon_search_result .result_info { line-height: 1.6; font-size: 20px; color: #666; text-align: center; }

.c_amazon_search_result .result_info strong { color: #222; }

.c_amazon_search_result .result_info .point { color: #ff0038; }

.c_amazon_search_result .result_suggestion { margin: 40px 0 0 310px; }

.c_amazon_search_result .result_suggestion li { position: relative; line-height: 1.6; font-size: 18px; color: #666; text-indent: 14px; }

.c_amazon_search_result .result_suggestion li:before { margin-right: 10px; display: inline-block; vertical-align: middle; content: ""; width: 2px; height: 2px; background: #666; }

.c_amazon_deal_promotion { height: 100px; margin: 0 0 30px 0; text-align: center; background: #2ba0b4; padding: 0 80px; border-radius: 4px; box-sizing: border-box; }

.c_amazon_deal_promotion .title { overflow: hidden; width: 100%; text-overflow: ellipsis; line-height: 100px; color: #fff; font-size: 23px; white-space: nowrap; }

.s_amazon_dealcorner { padding-bottom: 0; }

.c_amazon_card_row_deal .swiper-container { padding: 20px 25px 25px 25px; margin: -20px -25px -25px -25px; }

.c_amazon_card_row_deal .swiper-slide { margin-right: 30px; }

.c_amazon_card_row_deal .list_navigator button { width: 50px; height: 50px; margin-top: -25px; border-radius: 50%; box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_amazon_card_row_deal .list_navigator button.previous::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -87px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_row_deal .list_navigator button.previous:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -102px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_row_deal .list_navigator button.next::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: 0px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_row_deal .list_navigator button.next:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -15px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_row_deal .list_navigator button:hover { box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6); }

.c_amazon_card_row_deal li { float: left; width: 605px; height: 232px; margin: 0 0 0 30px; padding: 10px; box-sizing: border-box; overflow: hidden; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); }

.c_amazon_card_row_deal li:first-child { margin-left: 0; }

.c_amazon_card_row_deal .c-card-item a:hover { text-decoration: none; }

.c_amazon_card_row_deal .c-card-item:hover .c-card-item__thumb-img img { transform: scale(1.08); }

.c_amazon_card_row_deal .c-card-item .c-card-item__thumb-img { overflow: hidden; display: inline-block; width: 212px; height: 212px; vertical-align: top; }

.c_amazon_card_row_deal .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_amazon_card_row_deal .c-card-item .c-card-item__soldout-text::before { width: 60%; height: 60%; background-size: 100% 100%; }

.c_amazon_card_row_deal .c-card-item .c-card-item__info { zoom: 1; padding: 6px 10px 0 20px; vertical-align: top; }

.c_amazon_card_row_deal .c-card-item .c-card-item__info:after { display: block; content: ""; clear: both; }

.c_amazon_card_row_deal .c-card-item .c-nation { margin-bottom: 9px; }

.c_amazon_card_row_deal .c-card-item .c-time-flag { float: left; margin: 0 0 8px 0; }

.c_amazon_card_row_deal .c-card-item .c-flag { float: left; margin: 0 0 8px 0; }

.c_amazon_card_row_deal .c-card-item .c-card-item__name { clear: both; max-height: 1.5em; line-height: 1.5; margin: 0 0 10px 0; }

.c_amazon_card_row_deal .c-card-item .c-card-item__price-info { margin: 0 0 11px 0; padding: 0; }

.c_amazon_card_row_deal .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; font-size: 16px; }

.c_amazon_card_row_deal .c-card-item .c-card-item__price-info .c-card-item__rate .value { font-size: 22px; line-height: 22px; }

.c_amazon_card_row_deal .c-card-item .c-card-item__price-info .c-card-item__rate .c-card-item__special { font-size: 18px; line-height: 22px; }

.c_amazon_card_row_deal .c-card-item .c-card-item__price-info .c-card-item__price { font-size: 15px; line-height: 22px; }

.c_amazon_card_row_deal .c-card-item .c-card-item__price-info .c-card-item__price .value { font-size: 22px; line-height: 22px; }

.c_amazon_card_row_deal .c-card-item .c-starrate { zoom: 1; margin: 0 0 10px 0; line-height: 18px; }

.c_amazon_card_row_deal .c-card-item .c-starrate:after { display: block; content: ""; clear: both; }

.c_amazon_card_row_deal .c-card-item .c-starrate dd { float: left; }

.c_amazon_card_row_deal .c-card-item .c-starrate .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_amazon_card_row_deal .c-card-item .c-card-item__benefit { margin: 0 0 7px 0; }

.c_amazon_card_row_deal .c-card-item .c-card-item__benefit .benefit { padding: 0 0 0 12px; }

.c_amazon_card_row_deal .c-card-item .c-card-item__benefit .benefit:before { position: absolute; left: 5px; top: 9px; width: 2px; height: 2px; border-radius: 50%; background: #ccc; content: ""; }

.c_amazon_card_row_deal .c-card-item .c-card-item__benefit dt + .benefit { padding-left: 0; }

.c_amazon_card_row_deal .c-card-item .c-card-item__benefit dt + .benefit::before { display: none; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery { zoom: 1; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery:after { display: block; content: ""; clear: both; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery dd { float: left; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery .allclub:before { float: left; margin: 2px 2px 0 0; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery .allclub .c-card-item__point { color: #ff0038; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery .delivery { position: relative; padding: 0 0 0 12px; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery .delivery:before { position: absolute; left: 5px; top: 9px; width: 2px; height: 2px; margin: 0; border-radius: 50%; background: #ccc; content: ""; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery .delivery .c-card-item__point { color: #0b83e6; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery dt + .delivery { padding-left: 0; }

.c_amazon_card_row_deal .c-card-item .c-card-item__delivery dt + .delivery::before { display: none; }

.c_amazon_card_row_deal.c_amazon_card_row_deal_only { float: left; }

.c_amazon_card_row_deal.c_amazon_card_row_deal_only li { width: 732px; }

.c_amazon_card_row_deal.c_amazon_card_row_deal_only .c-card-item .c-card-item__info { padding: 6px 20px 0 30px; }

.c_amazon_dealcorner_banner { float: left; overflow: hidden; width: 478px; height: 232px; margin: 0 0 0 30px; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); }

.c_amazon_dealcorner_banner > a { display: block; overflow: hidden; width: 100%; height: 100%; }

.c_amazon_dealcorner_banner img { width: 100%; height: 100%; }

.c_amazon_dealcorner_category_link { position: relative; }

.c_amazon_dealcorner_category_link li { float: left; width: 106px; margin: 0 20px 0 0; box-sizing: border-box; }

.c_amazon_dealcorner_category_link li a { position: relative; display: block; width: 100%; height: 100%; text-align: center; box-sizing: border-box; font-weight: normal; color: #111; }

.c_amazon_dealcorner_category_link li a .icon { overflow: hidden; display: block; width: 90px; height: 90px; margin: 0 auto; border-radius: 45px; }

.c_amazon_dealcorner_category_link li a .icon > img { width: 100%; height: 100%; }

.c_amazon_dealcorner_category_link li a .text { display: inline-block; margin: 11px 0 0 0; font-size: 15px; }

.c_amazon_dealcorner_category_link li a:hover { text-decoration: underline; }

.c_amazon_dealcorner_category_link .list_navigator button { overflow: hidden; position: absolute; top: 50%; text-align: center; box-sizing: border-box; width: 50px; height: 50px; margin-top: -25px; border-radius: 50%; box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); line-height: 200px; z-index: 10; background: #fff; }

.c_amazon_dealcorner_category_link .list_navigator button.previous { left: -25px; }

.c_amazon_dealcorner_category_link .list_navigator button.previous::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -626px -50px; width: 10px; height: 18px; background-size: 1191px 201px; position: absolute; left: 50%; top: 50%; margin: -9px 0 0 -5px; content: ""; }

.c_amazon_dealcorner_category_link .list_navigator button.previous:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -641px -50px; width: 10px; height: 18px; background-size: 1191px 201px; }

.c_amazon_dealcorner_category_link .list_navigator button.next { right: -25px; }

.c_amazon_dealcorner_category_link .list_navigator button.next::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -656px -50px; width: 10px; height: 18px; background-size: 1191px 201px; position: absolute; left: 50%; top: 50%; margin: -9px 0 0 -5px; content: ""; }

.c_amazon_dealcorner_category_link .list_navigator button.next:hover::before { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -671px -50px; width: 10px; height: 18px; background-size: 1191px 201px; }

.c_amazon_dealcorner_category_link .list_navigator button:hover { box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6); }

.c_amazon_dealcorner_category_link .list_navigator button:disabled { display: none; }

.c_amazon_card_specialprice ul { zoom: 1; }

.c_amazon_card_specialprice ul:after { display: block; content: ""; clear: both; }

.c_amazon_card_specialprice .swiper-container { padding: 25px; margin: -25px; }

.c_amazon_card_specialprice .swiper-slide { margin-right: 30px; }

.c_amazon_card_specialprice .list_navigator button { width: 50px; height: 50px; margin-top: -25px; border-radius: 50%; box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_amazon_card_specialprice .list_navigator button.previous::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -87px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_specialprice .list_navigator button.previous:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -102px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_specialprice .list_navigator button.next::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: 0px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_specialprice .list_navigator button.next:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -15px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_amazon_card_specialprice .list_navigator button:hover { box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6); }

.c_amazon_card_specialprice li { float: left; overflow: hidden; width: 224px; height: 315px; margin: 0 0 0 30px; box-sizing: border-box; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); background: #fff; }

.c_amazon_card_specialprice li:hover .thumb .img { transform: scale(1.08); }

.c_amazon_card_specialprice a:hover { text-decoration: none; }

.c_amazon_card_specialprice .thumb { overflow: hidden; width: 224px; height: 224px; }

.c_amazon_card_specialprice .thumb .img { display: block; width: 100%; height: 100%; background-size: cover; background-repeat: no-repeat; transition: transform 0.2s ease-in; background-position: 50% 50%; }

.c_amazon_card_specialprice .price_info { overflow: hidden; height: 1.5em; margin: 14px 20px 0 20px; color: #0b83e6; font-size: 18px; text-align: center; word-break: break-all; }

.c_amazon_card_specialprice .price_info .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_amazon_card_specialprice .text { overflow: hidden; height: 1.5em; margin: 2px 20px 0 20px; color: #111; font-size: 19px; text-align: center; word-break: break-all; }

.c_amazon_dealcorner_hotdealcategory ul { zoom: 1; }

.c_amazon_dealcorner_hotdealcategory ul:after { display: block; content: ""; clear: both; }

.c_amazon_dealcorner_hotdealcategory li { position: relative; overflow: hidden; float: left; width: 394px; height: 270px; margin: 29px 0 0 29px; text-align: center; box-sizing: border-box; border-radius: 4px; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); }

.c_amazon_dealcorner_hotdealcategory li:nth-child(3n-2) { margin-left: 0; }

.c_amazon_dealcorner_hotdealcategory li:nth-child(1), .c_amazon_dealcorner_hotdealcategory li:nth-child(2), .c_amazon_dealcorner_hotdealcategory li:nth-child(3) { margin-top: 0; }

.c_amazon_dealcorner_hotdealcategory .thumb img { width: 394px; height: 270px; }

.c_amazon_dealcorner_hotdealcategory .subject { position: absolute; left: 0; right: 0; bottom: 20px; }

.c_amazon_dealcorner_hotdealcategory .text { overflow: hidden; display: inline-block; max-width: 354px; height: 32px; margin: 0 20px; padding: 0 20px; font-size: 19px; line-height: 30px; vertical-align: top; color: #111; background: rgba(255, 255, 255, 0.8); }

.c_amazon_no_data { min-height: 508px; padding: 88px 0 88px; text-align: center; box-sizing: border-box; }

.c_amazon_no_data .text_emphasis { font-size: 20px; color: #222; }

.c_amazon_no_data .text { font-size: 18px; color: #666; }

.c_amazon_no_data .point { color: #ff0038; }

.c_loading { position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 2000; background-color: rgba(255, 255, 255, 0.5); }

.c_loading .loading { overflow: hidden; position: absolute; top: 50%; left: 50%; width: 60px; height: 60px; margin: -30px 0 0 -30px; background-color: #fff; border: solid 1px rgba(0, 0, 0, 0.06); border-radius: 50%; line-height: 200px; }

.c_loading .loading:after { position: absolute; top: 50%; left: 50%; width: 28px; height: 28px; margin: -14px 0 0 -14px; background: url("/img/common/v2/loading_04.gif") no-repeat 0 0; background-size: 100%; content: ""; }

.c_amazon_loading { position: relative; height: 304px; }

.c_amazon_loading .loading { overflow: hidden; position: absolute; top: 50%; left: 50%; width: 60px; height: 60px; margin: -30px 0 0 -30px; background-color: #fff; border: solid 1px rgba(0, 0, 0, 0.06); border-radius: 50%; line-height: 200px; }

.c_amazon_loading .loading:after { position: absolute; top: 50%; left: 50%; width: 28px; height: 28px; margin: -14px 0 0 -14px; background: url("/img/common/v2/loading_04.gif") no-repeat 0 0; background-size: 100%; content: ""; }

.c_list_space { margin-top: 30px; }

.c_amazon_error { position: relative; clear: both; height: 500px; box-sizing: border-box; background: #fff; }

.c_amazon_error .caution { position: absolute; top: 50%; width: 100%; margin-top: -50px; font-size: 16px; color: #333; text-align: center; }

.c_amazon_error .caution::before { display: block; margin: 0 auto 15px auto; background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -308px -25px; width: 70px; height: 70px; background-size: 1191px 201px; content: ""; }
