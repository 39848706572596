@charset "UTF-8";

.c_bi_amazon {
    display: inline-block;
    @include logo-svg($logo-amazon, 80px, 25px);
    background-size: 100% 100%;
    vertical-align: middle;
    overflow: hidden;
    line-height: 200px;

    &.c_bi_amazon_allwhite {
        @include logo-svg($logo-amazon-allwhite, 80px, 25px);
        background-size: 100% 100%;
    }

    &.c_bi_amazon_white {
        @include logo-svg($logo-amazon-white, 80px, 25px);
        background-size: 100% 100%;
    }

    &.c_bi_amazon_grey {
        @include logo-svg($logo-amazon-black, 80px, 25px);
        background-size: 100% 100%;
    }
}

.c_icon_amazon {
    display: inline-block;
    @include logo-svg($logo-amazon-icon, 14px, 14px);
    background-size: 100% 100%;
    vertical-align: middle;
    overflow: hidden;
    line-height: 200px;
}
