@charset "UTF-8";

@import "../../common/partial/component/layer";
@import "../../common/partial/component/search";
@import "../../common/partial/c_amazon";

@import "../sprites/sp_amazon_2x";

.l_amazon_header {
    position: relative;
    z-index: 500;
    width: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
    background: linear-gradient(to left, #a5e6cc, #a5e6cc 44%, #84d7e2 48%, #84d7e1);

    &.l_amazon_header_fixed {
        position: fixed;
        top: 69px;
        left: 0;
        right: 0;
        z-index: 1100;
    }
}

.b_amazon_header {
    @include clearfix;
    letter-spacing: 0;
    font-family: $font-family;
    position: relative;
    width: 1240px;
    height: 70px;
    margin: 0 auto;
    background: linear-gradient(to left, #a5e6cc, #a5e6cc 44%, #84d7e1);
}

.c_amazon_header_category {
    float: left;
    position: relative;

    .category_button {
        position: relative;
        width: 224px;
        height: 70px;
        padding: 0 40px 0 0;
        font-size: 17px;
        font-weight: bold;
        color: #111;
        text-align: left;
        z-index: 10;

        .c_bi_amazon {
            margin: 8px 10px 0 0;
        }
        &:after {
            content: "";
            position: absolute;
            right: 17px;
            top: 33px;
            width: 0;
            border-style: solid;
            border-width: 6px 5px 0;
            border-color: #111 transparent;
        }
        &::before {
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background: #41a1ab;
            opacity: 0.29;
            content: "";
        }
    }

    &.category_visible {
        .category_button {
            &:after {
                transform: rotate(180deg);
            }
        }
        &:before {
            position: absolute;
            right: 0;
            top: 0;
            width: 3000px;
            height: 100%;
            background: #41a1ab;
            opacity: 0.2;
            content: "";
        }
        .c_amazon_header_category_layer {
            display: block;
        }
    }
}

.c_amazon_header_category_layer {
    display: none;
    position: absolute;
    left: 0;
    top: 70px;
    overflow: hidden;
    width: 672px;
    background: #fff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);

    &.subcategory_none {
        width: 224px;
    }
    > ul {
        position: relative;
        width: 224px;
        height: 100%;
        border-right: 1px solid #eee;
        box-sizing: border-box;

        > li {
            a {
                position: relative;
                display: block;
                height: 44px;
                padding: 0 10px 0 20px;
                line-height: 42px;
                box-sizing: border-box;
                font-size: 15px;

                &:hover {
                    text-decoration: none;
                }
            }
            > a {
                border-top: 1px solid #fafafa;
                border-bottom: 1px solid #fafafa;
                background: #fafafa;
            }
            &:first-child {
                &.active {
                    > a {
                        border-top: 1px solid #fafafa;
                    }
                }
            }
            &.active {
                > a {
                    color: $color-11st-red;
                    font-weight: bold;
                    background: #fff;
                    border-top: 1px solid #eee;
                    border-bottom: 1px solid #eee;

                    &::before {
                        @include ico-angle(right, 5px, 1px, $color-11st-red, static);
                        position: absolute;
                        right: 20px;
                        top: 18px;
                        content: "";
                    }
                    &::after {
                        position: absolute;
                        right: -1px;
                        top: 0;
                        width: 1px;
                        height: 100%;
                        background: #fff;
                        content: "";
                    }
                }
                .box_2depth {
                    display: block;
                }
            }
            &.depth_none {
                > a {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    .box_2depth {
        @include clearfix;
        display: none;
        position: absolute;
        left: 223px;
        top: 0;
        width: 448px;

        ul {
            position: relative;
            float: left;
            width: 224px;

            &:nth-child(2) {
                &:before {
                    display: none;
                }
            }
            &:before {
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
                height: 800px;
                background: #eee;
                content: "";
            }
        }

        a {
            &:hover {
                color: $color-11st-red;
                font-weight: bold;
            }
        }
    }
}

.c_amazon_header_servicelink {
    float: left;
    margin: 0 0 0 25px;

    ul {
        float: left;
    }
    li {
        float: left;
        margin: 0 0 0 20px;

        &:first-child {
            margin-left: 0;
        }
        a {
            display: block;
            height: 70px;
            line-height: 70px;
            font-weight: bold;
            font-size: 16px;
            color: #111;
        }
    }
}

//24.04.01 서비스 종료 이후  3.1일자로 노출 제거예정 이후 css 제거 처리.
.c_amazon_universepass_landing {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px 0 0 0;

    .text {
        float: left;
        margin: 9px 10px 0 0;
        color: $color-grayscale-01;
        font-size: 15px;
    }
    .link {
        position: relative;
        float: left;
        height: 40px;
        padding: 7px 25px 0 15px;
        box-sizing: border-box;
        background: #3617ce;
        border-radius: 21px;
        color: #fff;
        font-size: 16px;

        &:hover {
            text-decoration: none;
        }
        &::after {
            @include ico-angle(right, 5px, 1px, #fff, static);
            position: absolute;
            right: 17px;
            top: 17px;
            content: "";
        }
        .universepass {
            @include sprite-retina($sp_amazon_2x_logo_universepass);
            display: inline-block;
            margin: 5px 4px 0 0;
            overflow: hidden;
            line-height: 200px;
            vertical-align: top;
        }
    }
}
//E:24.04.01 서비스 종료 이후  3.1일자로 노출 제거예정 이후 css 제거 처리.
