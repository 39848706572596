@charset "UTF-8";

@mixin selectbox($width, $height, $font-size, $font-color, $font-color-disabled, $font-color-selected, $background-color-disabled, $border-color, $arrow-url, $arrow-size, $border-color-disabled: #f1f1f1, $arrow-url-disabled: "/img/common/selectbox/arrow_disabled.png") {
    display: inline-block;
    overflow: hidden;
    width: $width;
    height: $height;
    border: 1px solid $border-color;

    .select {
        width: $width; /* wrap width + 20px */
        height: $height;
        border: none;
        padding-left: 12px;
        padding-right: 43px;
        background: url($arrow-url) no-repeat right 50%;
        background-size: $arrow-size, $arrow-size;
        border-radius: 0px; /* iOS 둥근모서리 제거 */
        -webkit-appearance: none; /* 네이티브 외형 감추기 */
        -moz-appearance: none;
        appearance: none;
        line-height: $height;
        font-size: $font-size;
        color: $font-color;

        /* IE 10, 11의 네이티브 화살표 숨기기 */
        &::-ms-expand {
            display: none;
        }

        &:disabled {
            background-color: $background-color-disabled;
            color: $font-color-disabled;
        }
    }

    &.selected {
        .select {
            color: $font-color-selected;
        }
    }

    &.disabled {
        border-color: $border-color-disabled;

        .select {
            background-image: url($arrow-url-disabled);
        }
    }
}
