@charset "UTF-8";
@import "../eui/component/c-button";

.c_modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;

    &.active {
        display: block;
    }

    &.c_modal_dimmed {
        background: rgba(0, 0, 0, 0.2);
    }

    &--w500 {
        .modal_inner {
            width: 500px;
        }
    }

    .modal_inner {
        position: relative;
        margin: 50vh auto;
        transform: translateY(-50%);
        background: #fff;
        box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12);
    }

    .modal_header {
        border-bottom: 1px solid #eee;

        .title {
            text-align: center;
        }
    }
    .modal_content {
        &.scroll {
            overflow-y: auto;
            height: 100px;
        }
    }
    .modal_footer {
        border-top: 1px solid #f4f4f4;
        text-align: center;

        button {
            display: inline-block;
            margin: 0 5px;
        }
    }
    .modal_close {
        button {
            position: absolute;
            top: 17px;
            right: 21px;
            @include ico-cancel-retina(36px, 3px, #111, 0);
        }
    }
}

.c_layer_expand {
    display: inline-block;
    position: relative;

    &.active {
        z-index: 100;

        .c_layer_item {
            display: block;
        }
    }
}

.c_layer_item {
    display: none;
    position: absolute;
    left: 0;
    width: 240px;
    padding: 20px;
    box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 10;

    &.active {
        display: block;
    }

    //layer_info_subject 부분은 좀 더 고려 후 필요시 적용
    .layer_info_subject {
        dt {
            padding-bottom: 6px;
            font-size: 14px;
            font-weight: bold;
            color: #111;
        }
        dd {
            margin-top: 4px;
        }
        .dash {
            &::before {
                display: inline-block;
                width: 4px;
                height: 1px;
                margin-right: 4px;
                background-color: #666;
                vertical-align: 5px;
                content: "";
            }
        }
        .dash_align {
            position: relative;
            padding-left: 8px;

            &::before {
                position: absolute;
                left: 0;
                top: 50%;
                width: 4px;
                height: 1px;
                background-color: #666;
                content: "";
            }
        }
    }

    .layer_info {
        li {
            margin-top: 4px;

            &:first-child {
                margin-top: 0;
            }
        }
        .dash {
            &::before {
                display: inline-block;
                width: 4px;
                height: 1px;
                margin-right: 4px;
                background-color: #666;
                vertical-align: 5px;
                content: "";
            }
        }
        .dash_align {
            position: relative;
            padding-left: 8px;

            &::before {
                position: absolute;
                left: 0;
                top: 50%;
                width: 4px;
                height: 1px;
                background-color: #666;
                content: "";
            }
        }
    }

    .layer_close {
        position: absolute;
        right: 15px;
        top: 15px;
        @include ico-cancel(13px, 1px, #111, 5px);
    }
}
