@charset "UTF-8";

.c_search_item {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #ddd;
    background: #fff;

    .search_text {
        display: block;
        width: 100%;
        background: none;
        box-sizing: border-box;
        border: none;
        font-family: $font-family;
        letter-spacing: 0;
        color: #111;
        outline: none;
        padding: 0;

        &::placeholder {
            color: #999;
        }
    }
    .search_button {
        position: absolute;
        right: -1px;
        top: -1px;
        overflow: hidden;
        line-height: 200px;
        height: 100%;
    }
    .c_layer_item {
        width: 100%;
    }
}
