@charset "UTF-8";

@mixin c-p-more($fontSize, $fontColor, $lineHeight, $fontWeight) {
    color: $fontColor;
    font-size: $fontSize;
    font-weight: $fontWeight;
    line-height: $lineHeight;
}

@mixin c-p-more-arrow($size, $gap: 0, $center: true) {
    @if $center {
        &::after {
            width: $size;
            height: $size;
            margin-top: -#{round($size/2)};
            border-style: solid;
            display: inline-block;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            vertical-align: middle;
            content: "";
        }
    } @else {
        text-align: left;
        padding-right: $gap;

        &::after {
            right: calc(#{$gap} - #{round($size/2 * -1)});
            width: $size;
            height: $size;
            border-style: solid;
            display: block;
            position: absolute;
            top: 50%;
            right: 0px;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg) translateX(-50%);
            content: "";
        }
    }
}

.c-p-more {
    position: relative;
    display: inline-block;
    //@include c-p-more($fontSize, $fontColor, $gabRight, $lineHeight, $fontWeight)

    &--arrow {
        //화살표사이즈
        //@include -p-more-arrow(6px);
    }

    &--arrow-right {
        //화살표사이즈,버튼우측공간,arrow 센터정렬유무(true,false)
        //@include c-p-more-arrow(6px, 16px, false);
    }
}

.c_more {
    position: relative;
    display: inline-block;
    padding: 0 12px 0 0;

    &:after {
        position: absolute;
        top: 50%;
        right: 2px;
        width: 5px;
        height: 5px;
        margin-top: -3px;
        border: solid #666;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
        content: "";
    }
    &.c_more_down {
        padding: 0 14px 0 0;

        &:after {
            margin-top: -4px;
            transform: rotate(135deg);
        }
    }
    &.c_more_up {
        padding: 0 14px 0 0;

        &:after {
            margin-top: -2px;
            transform: rotate(-45deg);
        }
    }
}

.c_more_icon {
    display: block;
    overflow: hidden;
    position: relative;
    line-height: 300px;
    width: 50px;
    height: 50px;
    border: 1px solid #666;
    border-radius: 50%;

    &:after {
        position: absolute;
        top: 50%;
        right: 50%;
        width: 5px;
        height: 5px;
        margin-right: -3px;
        margin-top: -3px;
        border: solid #666;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
        content: "";
    }
}

.c-list-more {
    margin: 50px 0;

    &__button {
        position: relative;
        display: block;
        padding: 0 30px;
        margin: 0 auto;
        background-color: $color-gray-14;
        border: 1px solid $color-gray-09;
        border-radius: 28px;
        box-sizing: border-box;
        font-size: 17px;
        line-height: 54px;

        &::after {
            @include ico-angle(down, 6px, 1px, $color-gray-03, static);
            display: inline-block;
            margin: -9px 0 0 10px;
            content: "";
        }
    }

    &__text {
        color: $color-gray-02;

        &--highlight {
            color: $color-11st-blue;
        }

        &--emphasis {
            color: $color-11st-red;
        }
    }
}
