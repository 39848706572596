@charset "UTF-8";

.c_icon_allclub {
    display: inline-block;
    @include logo-svg($logo-allclub, 49px, 20px);
    background-size: 100% 100%;
    vertical-align: middle;
    overflow: hidden;
    line-height: 200px;
}
