@charset "UTF-8";

@import "../common/partial/component/info";
//가격안내
.c_modal_lowest {
    .modal_inner {
        width: 500px;
    }

    .modal_header {
        .title {
            line-height: 70px;
            color: #111;
            font-size: 19px;
        }
    }

    .modal_content {
        padding: 30px;
        font-size: 16px;
        color: #333;
    }

    .c-p-info {
        @include c-p-info(16px, $color-grayscale-03, 19px);

        &__list {
            @include c-p-info-list($gap: 16px, $bulletColor: #333);
            &--bullet {
                .c-p-info__list-item {
                    &::before {
                        width: 3px;
                        height: 3px;
                    }
                }
            }
        }
    }
}
