@charset "UTF-8";

@import "../common/mixins"; //믹스인모음

@import "../common/helpers/variables"; //변수모음
@import "../common/helpers/copyright"; //copyright

@import "../common/partial/component/search";
@import "../common/partial/component/billboard";
@import "../common/partial/component/more";
@import "../common/partial/component/list";
@import "../common/partial/component/tab";
@import "../common/partial/component/select";
@import "../common/partial/component/form";
@import "../common/animation";
@import "../common/partial/component/layer";

@import "../common/partial/c_amazon";
@import "../common/partial/c_allclub";
@import "../common/partial/component/info";

@import "../eui/component/c-iconinfo";
@import "../eui/component/c-flag";
@import "../eui/component/c-nation";
@import "../eui/component/c-time-flag";
@import "../eui/component/c-starrate";
@import "../eui/component/c-addition";
@import "../eui/component/c-card-item";

@import "../amazon/partial/header";
@import "../amazon/partial/modal_lowest";
@import "sprites/_sp_amazon_2x";
@import "sprites/_sp_mdtab_2x";

$point-red: #f43142;
$point-blue: #0b83e6;

.l_amazon_wide {
    width: 100%;

    .b_amazon {
        width: 1240px;
        margin: 0 auto;
    }
}
.l_amazon {
    width: 1240px;
    margin: 0 auto;
}
.l_amazon_bg {
    background: #fafafa;
}
.s_amazon {
    padding: 0 0 100px 0;
}
.b_amazon {
    @include clearfix;
}

.swiper-container {
    @include clearfix;
    overflow: hidden;
    width: 1240px;

    .swiper-slide {
        float: left;
    }
}

//아마존 공통리스트 유형
.c_list_card {
    clear: both;
}

.c_list_card_collection {
    &.col_4 {
        .c-card-item__info {
            min-height: 167px;
        }
        .c-card-item--space {
            .c-card-item__info {
                min-height: 156px;
            }
        }

        .c-card-item__name-addition {
            max-height: 1.5em;
            margin: 0 0 8px 0;
            font-size: 15px;
        }

        .c-card-item__price {
            font-size: 15px;
            line-height: 20px;
        }
    }

    &.col_5 {
        .c-card-item {
            &__lowest {
                &__rate {
                    clear: left;
                }
            }
        }
    }
}

.c-card-item {
    .c-card-item__info {
        position: relative;
    }

    .c-card-item__rank {
        padding: 0 6px;
        background: rgba(244, 49, 66, 0.8);
        border: solid 1px rgba(244, 49, 66, 0.1);
        color: $color-gray-14;

        &.c-card-item__rank--light {
            height: 32px;
            line-height: 32px;
            border: solid 1px rgba(255, 0, 56, 0.1);
            background: #fff7f8;
            text-align: center;
            color: $color-11st-red;
        }
    }

    &.c-card-item--deal {
        .c-card-item__info {
            min-height: 234px;
        }
    }
    &.c-card-item--collection {
        .c-card-item__rank {
            top: 10px;
            left: 10px;
        }
    }

    &.c-card-item--gallery {
        &.c-card-item--space {
            padding-bottom: 52px;

            .c-card-item__info {
                padding-bottom: 20px;
            }
            .c-card-item__addition {
                border-top: 1px solid #eee;
            }
        }
    }
}

.c_amazon_card_deal {
    .swiper-slide {
        margin-right: 29px;
    }
    li {
        margin: 0 0 0 29px;
    }
    .c-card-item {
        overflow: hidden;
        width: 394px;
        height: 446px;
        border-radius: 4px;

        .c-card-item__thumb-img {
            display: inline-block;
            width: 100%;
            height: 197px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            img {
                transition: transform 0.2s ease-in;
            }
        }
        .c-card-item__thumb-emblem {
            top: 9px;
            left: 9px;

            img {
                width: 80px;
                height: 80px;
            }
        }
        .c-card-item__info {
            min-height: 194px;
            padding: 12px 0 18px 20px;
            box-sizing: border-box;
        }
        .c-nation {
            float: left;
            margin-bottom: 10px;

            .amazon {
                margin-right: 1px;
            }
        }
        .c-time-flag {
            float: left;
            margin: 0 0 10px 8px;
        }
        .c-flag {
            float: left;
            margin-bottom: 6px;
        }
        .c-card-item__name {
            clear: both;
            height: 1.5em;
            margin: 0 20px 1px 0;
        }
        .c-card-item__name-addition {
            overflow: hidden;
            margin: 0 20px 7px 0;
            color: #0b83e6;
            font-size: 13px;
            height: 1.5em;
        }
        .c-card-item__price-info {
            padding: 0;

            .c-card-item__rate {
                margin-right: 8px;

                .value {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
            .c-card-item__price {
                margin-right: 8px;

                .value {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
            .c-card-item__price-del {
                color: #949494;
                font-size: 16px;
                line-height: 24px;
            }
            .c-card-item__price--point {
                color: $color-11st-red;
            }
            .c-card-item__detail {
                display: block;
                margin: 10px 0 0 0;
                color: #666;
                font-size: 13px;
            }
        }
        .c-card-item__counsel {
            margin: -5px 0 0 20px;
        }
        .c-starrate {
            position: relative;
            float: left;
            margin: 13px 0 0 0;
            padding: 0 12px 0 0;

            dd {
                float: left;
            }
            &:after {
                position: absolute;
                right: 4px;
                top: 9px;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background: #ccc;
                content: "";
            }
            .c-starrate__review {
                .sr-only {
                    @include sr-only;
                }
            }
        }
        .c-card-item__benefit {
            float: left;
            margin: 13px 0 0 0;
            height: 18px;

            .benefit {
                padding: 0 0 0 12px;

                &:before {
                    position: absolute;
                    left: 5px;
                    top: 9px;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background: #ccc;
                    content: "";
                }
            }
            dt + .benefit {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
        .c-card-item__delivery {
            clear: both;
            padding: 5px 0 0 0;

            dt {
                @include skip;
            }
            dd {
                position: relative;
                float: left;
            }
            .allclub {
                font-size: 13px;
                color: #666;

                &:before {
                    @include logo-svg($logo-allclub, 40px, 16px);
                    display: inline-block;
                    margin: -2px 0px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
            }
            .value {
                font-family: $font-family-number-lato;
            }
        }
        .c-card-item__deal-guide {
            color: #666;
        }
        .card_addition {
            height: 53px;
            margin: 0 21px;
            padding: 15px 0 0 0;
            border-top: 1px solid #f4f4f4;

            .c_prd_coupon {
                margin-left: 12px;
            }
            .c-card-item__deal {
                padding: 0;
                border: none;

                &:after {
                    display: none;
                }
            }
        }
    }
}

.c_amazon_card_row {
    li {
        height: 270px;
        margin: 0 20px;
        padding: 20px 0 0 0;
        border-top: 1px solid #f4f4f4;
        box-sizing: border-box;

        &:first-child {
            border-top: none;
        }
    }
    .c-card-item {
        &:hover {
            .c-card-item__thumb-img {
                img {
                    transform: scale(1.08);
                }
            }
        }
        a {
            &:hover {
                text-decoration: none;
            }
        }
        .c-card-item__thumb-img {
            overflow: hidden;
            display: inline-block;
            width: 128px;
            height: 128px;
            vertical-align: top;

            img {
                transition: transform 0.2s ease-in;
            }
        }
        .c-card-item__soldout-text {
            width: 128px;
            height: 128px;

            &::before {
                width: 60%;
                height: 60%;
                background-size: 100% 100%;
            }
        }
        .c-card-item__info {
            @include clearfix;
            padding: 4px 0 0 20px;
            vertical-align: top;
        }
        .c-time-flag {
            margin: 0 0 8px 0;
        }
        .c-flag {
            margin: 0 0 8px 0;
        }
        .c-card-item__name {
            max-height: 3em;
            line-height: 1.5;
            margin: 0 0 10px 0;
        }
        .c-card-item__name-addition {
            overflow: hidden;
            max-height: 1.5em;
            margin: 0 0 15px 0;
            color: #0b83e6;
            line-height: 1.5;
        }
        .c-card-item__price-info {
            margin: 0 0 12px 0;
            padding: 0;

            .c-card-item__rate {
                margin-right: 8px;
                font-size: 16px;

                .value {
                    font-size: 22px;
                    line-height: 22px;
                }
            }
            .c-card-item__price {
                font-size: 15px;

                .value {
                    font-size: 22px;
                    line-height: 22px;
                }
            }
            + .c-card-item__delivery {
                padding-left: 0;

                &.before {
                    display: none;
                }
            }
        }
        .c-starrate {
            float: left;
            margin: 0 0 7px 0;
            line-height: 18px;

            dd {
                float: left;
            }
            .sr-only {
                @include sr-only;
            }
        }
        .c-card-item__delivery {
            position: relative;
            float: none;
            clear: both;
            padding: 0;

            &:before {
                position: absolute;
                left: 5px;
                top: 9px;
                width: 2px;
                height: 2px;
                background: #ccc;
                border-radius: 100%;
                content: "";
            }
            .allclub {
                &:before {
                    float: left;
                    margin: 2px 2px 0 0;
                }
            }
            .sktpass {
                .c-card-item__point {
                    line-height: 18px;
                }
            }
        }
        .card_ranking_flag {
            padding-left: 12px;
            font-size: 19px;
            line-height: 45px;
            color: #fff;
            @include sprite-retina($sp_amazon_2x_flag_rank_red);
            border-top-left-radius: 4px;
        }
    }
}

.c_amazon_card_review {
    li {
        margin: 30px 0 0 30px;

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 0;
        }
        &:nth-child(2n-1) {
            margin-left: 0;
        }
    }
    .c-card-item {
        display: block;
        width: 605px;
        border-radius: 4px;

        .c-card-item__thumb {
            padding: 20px 0 20px 20px;
        }

        .c-card-item__thumb-img {
            overflow: hidden;
            display: inline-block;
            width: 160px;
            height: 160px;
            vertical-align: top;

            img {
                transition: transform 0.2s ease-in;
            }
        }
        .c-card-item__soldout-text {
            width: 160px;
            height: 160px;
            left: 20px;
            top: 20px;

            &::before {
                width: 60%;
                height: 60%;
                background-size: 100% 100%;
            }
        }
        .c-card-item__info {
            padding: 27px 20px 20px 20px;
            vertical-align: top;
        }
        .c-nation {
            margin-bottom: 8px;
        }
        .c-flag {
            margin: 0 0 8px 0;
        }
        .c-card-item__rank {
            top: 20px;
            left: 20px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #fff;
            background: rgba(244, 49, 66, 0.8);
        }
        .c-card-item__name {
            max-height: 1.5em;
            line-height: 1.5;
            margin: 0 0 9px 0;
        }
        .c-card-item__price-info {
            margin: 0 0 10px 0;
            padding: 0;

            .c-card-item__rate {
                margin-right: 8px;
            }
        }
        .c-starrate {
            float: left;
            line-height: 18px;

            dd {
                float: left;
            }
            .sr-only {
                @include sr-only;
            }
        }
        .c-card-item__delivery {
            position: relative;
            float: left;
            padding: 0 0 0 12px;

            &:before {
                position: absolute;
                left: 5px;
                top: 9px;
                width: 2px;
                height: 2px;
                background: #ccc;
                border-radius: 100%;
                content: "";
            }
            .allclub {
                &:before {
                    float: left;
                    margin: 2px 2px 0 0;
                }
            }
        }
        .card_review {
            clear: both;
            position: relative;
            border-top: 1px solid #f4f4f4;

            .review_box {
                position: relative;
                padding: 20px 20px 20px 42px;

                &:before {
                    @include sprite-retina($sp_amazon_2x_quote);
                    position: absolute;
                    left: 19px;
                    top: 20px;
                    content: "";
                }
            }
            .review_text {
                overflow: hidden;
                height: 4.5em;
                font-size: 15px;
                line-height: 22px;
            }
            .review_info {
                @include clearfix;
                margin: 15px 0 0 0;
            }
            .review_user {
                float: left;
                margin: 0 4px 0 0;
                line-height: 18px;
                color: #999;
                font-weight: normal;
            }
            .c-starrate {
                float: left;
                position: relative;
                padding: 1px 0 0 8px;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 8px;
                    width: 2px;
                    height: 2px;
                    background: #ccc;
                    border-radius: 100%;
                    content: "";
                }
                .c-starrate__review {
                    margin: -1px 0 0 0;
                    font-size: 14px;
                    line-height: 18px;
                    color: #333;
                }
            }
            .review_navigator {
                position: absolute;
                right: 22px;
                bottom: 18px;
                padding: 0 28px;
                text-align: center;

                .page {
                    display: inline-block;
                    margin: 0 10px;
                    font-size: 15px;
                    line-height: 28px;
                    color: #666;

                    .current {
                        color: #111;
                        font-weight: bold;
                    }
                }

                button {
                    overflow: hidden;
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    line-height: 300px;
                    border-radius: 50%;
                    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
                    background: #fff;

                    &::before {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        content: "";
                    }
                    &.previous {
                        left: 0;

                        &::before {
                            margin: -6px 0 0 -5px;
                            @include sprite-retina($sp_amazon_2x_arrow_left_2);
                        }
                        &:hover {
                            &::before {
                                @include sprite-retina($sp_amazon_2x_arrow_left_2_on);
                            }
                        }
                    }
                    &.next {
                        right: 0;

                        &::before {
                            margin: -6px 0 0 -3px;
                            @include sprite-retina($sp_amazon_2x_arrow_right_2);
                        }
                        &:hover {
                            &::before {
                                @include sprite-retina($sp_amazon_2x_arrow_right_2_on);
                            }
                        }
                    }
                    &:disabled {
                        &.previous {
                            &::before {
                                @include sprite-retina($sp_amazon_2x_arrow_left_2_dim);
                            }
                        }
                        &.next {
                            &::before {
                                @include sprite-retina($sp_amazon_2x_arrow_right_2_dim);
                            }
                        }
                        &:hover {
                            box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
                        }
                    }
                    &:hover {
                        box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
                    }
                }
            }
        }
    }
}

//탭 네비 공통
.c_tab_item {
    .tab_navigator {
        button {
            width: 50px;
            height: 50px;
            margin-top: -25px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);

            &.previous {
                &::before {
                    @include sprite-retina($sp_amazon_2x_arrow_left);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_left_on);
                    }
                }
            }
            &.next {
                &::before {
                    @include sprite-retina($sp_amazon_2x_arrow_right);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_right_on);
                    }
                }
            }
            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
        }
    }
}

//메인
.s_amazon_main {
    padding-bottom: 0;
}
.c_amazon_main_billboard {
    width: 1240px;
    height: 400px;

    .visual {
        li {
            width: 1240x;
            height: 400px;
            transition: opacity 0.3s ease-in-out;

            img {
                width: 1240px;
                height: 400px;
            }
        }
    }
    .navigator {
        height: 45px;
        border-radius: 23px;
        background: rgba(0, 0, 0, 0.2);

        button {
            width: 46px;
            height: 45px;

            &:before {
                top: 11px;
            }
            &.previous {
                &:before {
                    left: 16px;
                    @include sprite-retina($sp_amazon_2x_billboard_previous);
                }
            }
            &.next {
                &:before {
                    right: 16px;
                    @include sprite-retina($sp_amazon_2x_billboard_next);
                }
            }
        }
    }
    .indicate {
        height: 45px;
        line-height: 45px;
        padding: 0 17px;
        border-radius: 23px;
        background: rgba(0, 0, 0, 0.2);

        .pagination {
            display: inline-block;
            width: 70px;
            font-size: 17px;
            color: #fff;
            text-align: center;

            .line {
                margin: 0 4px;
                opacity: 0.6;
            }
            .total {
                opacity: 0.6;
            }
        }
        .more {
            position: absolute;
            right: 0;
            top: 0;
            width: 45px;
            height: 45px;
            overflow: hidden;
            line-height: 200px;

            &::before {
                position: absolute;
                right: 11px;
                top: 11px;
                @include sprite-retina($sp_amazon_2x_billboard_more);
                content: "";
            }
        }
    }
    .control {
        right: 216px;
        border-radius: 23px;
        background: rgba(0, 0, 0, 0.2);

        button {
            width: 45px;
            height: 45px;

            &:before {
                margin: -12px 0 0 -12px;
            }
            &.stop {
                &:before {
                    @include sprite-retina($sp_amazon_2x_billboard_stop);
                }
            }
            &.play {
                &:before {
                    @include sprite-retina($sp_amazon_2x_billboard_play);
                }
            }
        }
    }
}

.c_amazon_title {
    @include clearfix;
    position: relative;
    margin: 0 0 21px 0;

    &.c_amazon_title_line {
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
    }

    .title {
        float: left;
        font-size: 24px;
        color: #111;
        vertical-align: middle;
    }
    .point {
        color: #0b83e6;
    }
    .description {
        float: left;
        margin: 6px 0 0 19px;
        font-size: 16px;
        color: #666;

        .date {
            font-family: $font-family-number-lato;
        }
    }
    .c_more {
        position: absolute;
        right: 1px;
        top: 7px;

        &:after {
            color: #111;
        }
    }
    & + .c_amazon_theme_text {
        margin-top: -15px;
    }
}

.c_amazon_main_timedeal_sort {
    @include clearfix;
    margin: 0 0 25px 0;

    li {
        float: left;
        margin: 0 0 0 10px;

        &:first-child {
            margin-left: 0;
        }
    }
    a[aria-selected="true"] {
        .c-card-item {
            border-color: #111;
        }
    }
    .c-card-item {
        width: 240px;
        height: 80px;
        padding: 14px 0 0 14px;
        border: 1px solid #eee;
        box-sizing: border-box;
        border-radius: 40px;
        background: #fff;
    }
    .c-card-item__thumb {
        float: left;
        width: 50px;
        margin: 0 10px 0 0;
    }
    .c-card-item__thumb-img {
        overflow: hidden;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        vertical-align: top;
    }
    .c-card-item__info {
        float: left;
        width: 140px;
    }
    .c-card-item__name {
        overflow: hidden;
        margin: 2px 0 0 0;
        height: 1.5em;
        font-size: 15px;
        color: #333;
    }
    .card_timedeal_status {
        overflow: hidden;
        height: 1.5em;
        font-size: 15px;
        color: #49a3c7;
        font-weight: bold;
    }
}

.c_card_amazon_timedeal {
    .c-card-item {
        @include clearfix;
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
        background: #fff;

        &:hover {
            .c-card-item__thumb-img {
                img {
                    transform: scale(1.08);
                }
            }
        }

        .c-card-item__thumb {
            float: left;
            overflow: hidden;
            position: relative;
            width: 605px;
            height: 302px;
            margin: 0 40px 0 0;
            text-align: center;

            img {
                overflow: hidden;
                max-width: 100%;
                height: 100%;
                transition: transform 0.2s ease-in;
            }
            &:after {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.02);
                content: "";
            }
        }
        .c-card-item__thumb-img {
            width: 100%;
            height: 100%;

            img {
                transition: transform 0.2s ease-in;
            }
            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
        .c-card-item__info {
            position: relative;
            float: left;
            width: 595px;
            height: 302px;
            padding: 22px 50px 0 0;
            box-sizing: border-box;
        }
        .c-nation {
            float: left;
            margin: 0 8px 8px 0;
            line-height: 20px;

            .amazon {
                margin-right: 1px;
            }
        }
        .c-flag {
            float: left;
            margin-bottom: 8px;
        }
        .c-card-item__name {
            clear: both;
            height: 1.5em;
            margin: 0 0 28px 0;
            font-size: 19px;

            a {
                color: #111;
            }
        }
        .c-card-item__name-addition {
            overflow: hidden;
            height: 20px;
            margin: -28px 0 8px 0;
            color: #0b83e6;
        }
        .c-card-item__price-info {
            @include clearfix;
            padding: 0;

            .c-card-item__rate {
                margin-right: 8px;

                .value {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
            .c-card-item__special {
                display: inline-block;
                font-size: 18px;
                line-height: 22px;
                margin-top: 2px;
                vertical-align: top;
            }
            .c-card-item__price {
                float: left;
                margin-right: 8px;

                .value {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
            .c-card-item__price-del {
                float: left;
                font-size: 16px;
                line-height: 24px;
                color: #949494;
            }
            + .c-card-item__benefit {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
        .c-card-item__counsel {
            margin: -5px 0 0 20px;
        }
        .c-starrate {
            float: left;
            margin: 28px 0 0 0;

            dd {
                float: left;
            }
            .c-starrate__review {
                .sr-only {
                    @include sr-only;
                }
            }
        }
        .c-starrate__review {
            color: #666;
        }
        .c-card-item__benefit {
            float: left;
            position: relative;
            overflow: hidden;
            height: 18px;
            margin: 28px 0 0 0;
            padding: 0 0 0 14px;

            &:before {
                position: absolute;
                left: 7px;
                top: 9px;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background: #ccc;
                content: "";
            }
            .benefit {
                padding: 0 0 0 14px;
                color: #666;

                &:before {
                    position: absolute;
                    left: 6px;
                    top: 9px;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background: #ccc;
                    content: "";
                }
            }
            dt + .benefit {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
        .c-card-item__delivery {
            clear: both;
            overflow: hidden;
            height: 18px;
            padding: 8px 0 0 0;

            .allclub {
                float: left;
                font-size: 13px;

                &:before {
                    margin-bottom: 0;
                }
            }
            .sktpass {
                float: left;
                font-size: 13px;
            }
            .delivery {
                float: left;
                position: relative;
                padding: 0 0 0 14px;
                color: #666;

                &:before {
                    position: absolute;
                    left: 3px;
                    top: 9px;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background: #ccc;
                    content: "";
                }
            }
            dt + .delivery {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
        .c-card-item__info-text {
            font-size: 18px;
            line-height: 24px;
            color: #666;

            + .c-card-item__benefit {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
    .c_timecount {
        @include clearfix;
        display: block;
        margin: 0 0 12px 0;
        color: $color-11st-red;
        font-size: 28px;
        font-weight: bold;
        transition: 0.5s;

        dd {
            float: left;
        }
        .status {
            margin: 0 10px 0 0;
            line-height: 48px;
        }
        .value {
            font-family: $font-family-number-lato;
        }
        .hour,
        .minutes,
        .second,
        .colon {
            float: left;
        }
        .box {
            float: left;
            overflow: hidden;
            width: 20px;
            height: 40px;
        }
        .minutes,
        .hour {
            &:after {
                font-size: 32px;
                font-family: $font-family-number-lato;
                color: $color-11st-red;
                content: ":";
            }
        }
        .number_group {
            display: block;
            width: 20px;

            .number {
                display: block;
                height: 40px;
                font-size: 32px;
                font-family: $font-family-number-lato;
                font-weight: bold;
                color: $color-11st-red;
                text-align: center;
            }
        }
    }
    .c-card-item__soldout-text {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;
        line-height: 1600px;
        z-index: 20;

        &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 160px;
            height: 160px;
            background: url("/img/common/v3/soldout.png") no-repeat;
            background-size: 160px 160px;
            content: "";
        }
    }
}

.c_amazon_shoppingtip {
    position: relative;
    float: left;
    width: 605px;
    height: 185px;
    padding: 29px 30px 0 30px;
    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
    box-sizing: border-box;
    border-radius: 4px;

    .tip_title {
        margin: 0 0 27px 0;
        font-size: 20px;
        line-height: 24px;
        color: #111;
    }
    li {
        overflow: hidden;
        position: relative;
        margin: 15px 0 0 0;
        padding: 0 65px 0 0;

        &:first-child {
            margin-top: 0;
        }
        a {
            display: inline-block;
            overflow: hidden;
            max-width: 332px;
            height: 28px;
            font-size: 17px;
            line-height: 28px;
            color: #333;
            vertical-align: top;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .tip_category {
        display: inline-block;
        height: 30px;
        margin: 0 10px 0 0;
        padding: 0 15px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 15px;
        color: #111;
        border: 1px solid rgba(51, 51, 51, 0.2);
        box-sizing: border-box;
        vertical-align: top;
        font-weight: bold;

        &.tip_category_point {
            color: $color-11st-red;
            border-color: rgba(244, 49, 66, 0.2);
        }
    }
    .tip_date {
        position: absolute;
        right: 0;
        top: 4px;
        color: #999;
        font-size: 15px;
        font-family: $font-family-number-lato;
    }
    .tip_new {
        overflow: hidden;
        display: inline-block;
        margin: 3px 0 0 6px;
        vertical-align: middle;
        line-height: 200px;
        @include sprite-retina($sp_amazon_2x_new_2);
    }
    .tip_more {
        position: absolute;
        right: 30px;
        top: 30px;
    }
}

.c_amazon_coupon {
    @include sprite-retina($sp_amazon_2x_coupon_box);
    position: relative;
    float: left;
    margin: -6px -6px -6px 21px;

    .coupon_box {
        position: relative;
        padding: 34px 98px 0 37px;
        box-sizing: border-box;
    }
    .coupon_name {
        overflow: hidden;
        height: 60px;
        margin: 0 0 3px 0;
        color: #49a3c7;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;

        .value {
            font-size: 26px;
        }
        .unit {
            display: inline-block;
            font-size: 17px;
        }
    }
    .coupon_detail {
        overflow: hidden;
        height: 36px;
        color: #333;
        font-size: 15px;
        line-height: 18px;
    }
    .coupon_download {
        position: absolute;
        left: 7px;
        top: 6px;
        width: 290px;
        height: 135px;
        overflow: hidden;
        line-height: 500px;

        &:after {
            position: absolute;
            right: 25px;
            top: 68px;
            @include sprite-retina($sp_amazon_2x_coupon_button);
            content: "";
        }
    }

    .coupon_navigator {
        position: absolute;
        left: 37px;
        bottom: 30px;
        padding: 0 28px;
        text-align: center;

        .page {
            display: inline-block;
            margin: 0 10px;
            font-size: 15px;
            line-height: 28px;
            color: #666;

            .current {
                color: #111;
                font-weight: bold;
            }
        }
        .line {
            margin: 0 4px;
        }
        button {
            overflow: hidden;
            position: absolute;
            width: 28px;
            height: 28px;
            line-height: 300px;
            border-radius: 50%;
            box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
            background: #fff;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: "";
            }
            &.previous {
                left: 0;

                &::before {
                    margin: -6px 0 0 -5px;
                    @include sprite-retina($sp_amazon_2x_arrow_left_2);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_left_2_on);
                    }
                }
            }
            &.next {
                right: 0;

                &::before {
                    margin: -6px 0 0 -3px;
                    @include sprite-retina($sp_amazon_2x_arrow_right_2);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_right_2_on);
                    }
                }
            }
            &:disabled {
                &.previous {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_left_2_dim);
                    }
                }
                &.next {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_right_2_dim);
                    }
                }
                &:hover {
                    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
                }
            }
            &:hover {
                box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            }
        }
    }
}

.c_amazon_infotip {
    position: relative;
    float: left;
    width: 287px;
    height: 185px;
    margin: 0 0 0 30px;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);

    img {
        width: 100%;
        height: 100%;
    }
    .navigator {
        position: absolute;
        left: 30px;
        bottom: 20px;
        padding: 0 28px;
        text-align: center;

        .page {
            display: inline-block;
            margin: 0 10px;
            font-size: 15px;
            line-height: 28px;
            color: #666;

            .current {
                color: #111;
                font-weight: bold;
            }
        }
        .line {
            margin: 0 4px;
        }
        button {
            overflow: hidden;
            position: absolute;
            width: 28px;
            height: 28px;
            line-height: 300px;
            border-radius: 50%;
            box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
            background: #fff;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: "";
            }
            &.previous {
                left: 0;

                &::before {
                    margin: -6px 0 0 -5px;
                    @include sprite-retina($sp_amazon_2x_arrow_left_2);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_left_2_on);
                    }
                }
            }
            &.next {
                right: 0;

                &::before {
                    margin: -6px 0 0 -3px;
                    @include sprite-retina($sp_amazon_2x_arrow_right_2);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_right_2_on);
                    }
                }
            }
            &:disabled {
                &.previous {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_left_2_dim);
                    }
                }
                &.next {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_right_2_dim);
                    }
                }
                &:hover {
                    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
                }
            }
            &:hover {
                box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            }
        }
    }
}

.c_amazon_picklist {
    @include clearfix;
    margin: 0 0 25px 0;

    li {
        float: left;
        width: 302px;
        margin: 0 0 0 11px;
        box-shadow: none;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-left: 10px;
        }
        &.active {
            > .c-card-item {
                border: 1px solid #111;

                .c-card-item__thumb {
                    img {
                        transform: scale(1.08);
                    }
                }
            }
        }
        &.dummy {
            //로딩될때만 사용
            > .c-card-item {
                border: 1px solid #eee;
            }
        }
        .recommnedlist {
            display: none;
            position: absolute;
            left: 0;
            top: 140px;
            width: 1240px;
        }
        > .c-card-item {
            border: 1px solid #eee;
            border-radius: 55px;
            height: 110px;

            a {
                display: block;
                height: 100%;
                padding: 15px 0 0 15px;
                box-sizing: border-box;

                &:hover {
                    text-decoration: none;
                }
            }
            .c-card-item__info {
                margin: 0 0 0 95px;
            }
            .c-card-item__thumb {
                float: left;
                overflow: hidden;
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }
            .c-card-item__thumb-img {
                img {
                    transition: transform 0.2s ease-in;
                }
            }
            .c-card-item__name {
                overflow: hidden;
                width: 150px;
                height: 3em;
                margin: 0 0 8px 0;
            }
            .c-card-item__price-info {
                .c-card-item__rate {
                    margin-right: 4px;
                }
            }
        }
    }
}

.c_amazon_tab_tag {
    overflow: hidden;
    margin: 0 0 25px 0;
    height: 42px;

    li {
        margin: 0 0 0 10px;

        &:first-child {
            margin-left: 0;
        }
        button {
            overflow: hidden;
            display: inline-block;
            max-width: 220px;
            height: 40px;
            padding: 0 15px;
            line-height: 38px;
            vertical-align: top;
            border: 1px solid #ddd;
            border-radius: 20px;
            box-sizing: border-box;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: normal;
            color: #333;
            background: $color-grayscale-13;

            &:hover {
                text-decoration: underline;
            }
        }
        button[aria-selected="true"] {
            color: #49a3c7;
            border-color: rgba(73, 163, 199, 0.4);
            font-weight: bold;
        }
    }
}

.c_amazon_theme_text {
    position: relative;
    margin: 40px 0 24px 0;

    .text {
        position: relative;
        display: inline-block;
        color: #0b83e6;
        font-size: 20px;

        &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 12px;
            background: rgba(11, 131, 230, 0.1);
            content: "";
        }
    }
    .c_more {
        position: absolute;
        right: 0;
        top: 8px;
    }
}

.c_amazon_card_mdhot_review {
    @include clearfix;

    li {
        overflow: hidden;
        float: left;
        width: 394px;
        height: 388px;
        margin: 31px 0 0 29px;
        border-radius: 4px;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 0;
        }
        &:nth-child(3n-2) {
            margin-left: 0;
        }
        &:hover {
            box-shadow: 0px 6px 32px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.7);

            .c-card-item__thumb-img {
                img {
                    transform: scale(1.08);
                }
            }
        }
    }
    dt {
        @include sr-only;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .card_review_info {
        padding: 17px 0 0 0;
    }
    .c-card-item__thumb {
        position: relative;

        .video {
            @include sprite-retina($sp_amazon_2x_video);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            line-height: 200px;
            z-index: 10;
        }
        .c-card-item__thumb-img {
            width: 394px;
            height: 197px;
        }
        img {
            transition: transform 0.2s ease-in;
        }
    }
    .c-starrate {
        margin: 0 0 15px 0;
        text-align: center;

        &.c-starrate--amazon {
            dt {
                @include get-sprite-common("bi_amazon_icon", 18);
                float: none;
                display: inline-block;
                margin: -3px 5px 0 0;
                vertical-align: middle;
            }
        }
        dd {
            display: inline-block;
        }
        .c-starrate__sati {
            @include get-sprite-common("ic_star", 108);
            float: none;
            display: inline-block;
            margin: 0 5px 0 0;
            vertical-align: top;

            .c-starrate__gauge {
                @include get-sprite-common("ic_star_on", 108);
            }
        }
        .c-starrate__review {
            float: none;
            display: inline-block;
            font-size: 16px;
            font-family: $font-family-number-lato;
            font-weight: bold;
            color: #333;

            .sr-only {
                @include sr-only;
            }
        }
    }
    .c-card-item__name-addition {
        overflow: hidden;
        height: 22px;
        margin: 0 40px 10px 40px;
        color: #0b83e6;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
    }
    .card_review_text {
        position: relative;
        overflow: hidden;
        height: 3em;
        margin: 0 0 7px 0;
        padding: 0 40px 0 40px;
        text-align: center;
        color: #333;
        font-size: 15px;
        line-height: 22px;

        dd {
            position: relative;
            display: inline-block;
            max-width: 314px;

            &:before {
                @include sprite-retina($sp_amazon_2x_quote);
                display: inline-block;
                vertical-align: top;
                margin: 0 8px 0 0;
                content: "";
            }
        }
    }
    .card_review_number {
        text-align: center;
        font-size: 15px;
        color: #999;
    }
}

.c_amazon_tab_keyword {
    position: relative;
    margin: 0 0 20px 0;

    .tab_inner {
        overflow: hidden;
        height: 50px;
    }
    ul {
        margin-top: -10px;
        transition: all 0.3s ease;
    }
    li {
        padding: 10px 10px 0 0;

        button {
            overflow: hidden;
            display: inline-block;
            max-width: 220px;
            height: 40px;
            padding: 0 15px;
            line-height: 38px;
            vertical-align: top;
            border: 1px solid #ddd;
            border-radius: 25px;
            box-sizing: border-box;
            font-size: 15px;
            color: #333;
            background: #fff;
            font-weight: normal;
        }
        button[aria-selected="true"] {
            color: #fff;
            background: #49a3c7;
            border-color: #49a3c7;
            font-weight: bold;
        }
    }
    .tab_navigator {
        button {
            margin-top: -30px;
        }
    }
}

.c_amazon_tab_sort {
    float: left;
    margin: -1px 0 0 20px;
    height: 40px;
    border-radius: 20px;
    background: #f4f4f4;
    box-shadow: inset 0 0 0 1px #eee;

    li {
        float: left;
        margin: 0 0 0 -10px;

        &:first-child {
            margin-left: 0;

            button {
                left: 0;
                margin-left: 0;
            }
        }
    }
    button {
        position: relative;
        height: 40px;
        padding: 0 24px;
        vertical-align: top;
        border-radius: 20px;

        &[aria-selected="true"] {
            background: #49a3c7;
            color: #fff;
        }
    }
}

.c_amazon_main_trendgender {
    @include clearfix;

    > ul > li {
        float: left;
        overflow: hidden;
        width: 394px;
        margin: 30px 0 0 29px;
        border-radius: 4px;
        box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);

        &:nth-child(3n-2) {
            margin-left: 0;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 0;
        }
    }
    .card_trend_thumb {
        img {
            width: 394px;
            height: 168px;
        }
    }
    .c_amazon_card_row {
        height: 810px;
    }
    .card_trend_more {
        display: block;
        position: relative;
        margin: -1px 0 0 0;
        border-top: 1px solid #f4f4f4;
        height: 58px;
        line-height: 58px;
        text-align: center;
        font-size: 15px;

        .point {
            color: #0b83e6;
        }
        .c_more {
            padding: 0 30px 0 0;

            &:before {
                position: absolute;
                top: 50%;
                right: -2px;
                width: 22px;
                height: 22px;
                margin: -11px 0 0 0;
                border: 1px solid #ddd;
                border-radius: 100%;
                content: "";
            }
            &:after {
                right: 7px;
                width: 6px;
                height: 6px;
                border-color: #0b83e6;
            }
        }
    }
}

.c_amazon_main_banner {
    text-align: center;

    img {
        height: 100px;
        vertical-align: top;
    }
}

.c_amazon_card_more {
    position: relative;
    margin: 30px 0 0 0;
    text-align: center;

    &:after {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background: #eee;
        content: "";
    }
    .c_more {
        height: 56px;
        padding: 0 50px 0 30px;
        line-height: 54px;
        border: 1px solid #ccc;
        font-size: 17px;
        border-radius: 28px;
        color: #111;
        background: #fff;
        z-index: 10;

        &:after {
            right: 35px;
            margin-top: -5px;
            @include ico-angle(right, 8px, 1px, #111, static);
        }
        &.c_more_down {
            &:after {
                right: 28px;
                margin-top: -7px;
                @include ico-angle(down, 8px, 1px, #111, static);
            }
        }
        &:hover {
            text-decoration: none;
        }
        .point {
            color: $color-11st-red;
        }
    }
    .value {
        font-family: $font-family-number-lato;
    }
}

.c_amazon_brand {
    position: relative;
    width: 1240px;
    margin: 30px 0 0 0;

    &:first-child {
        margin-top: 0;
    }
    .brand_visual {
        width: 1240px;
        height: 446px;
        background: #999;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .brand_more {
        position: absolute;
        left: 149px;
        top: 274px;
        padding: 0 30px;
        height: 52px;
        line-height: 52px;
        border: 1px solid #fff;
        border-radius: 26px;
        box-sizing: border-box;
        color: #fff;
        font-size: 17px;
        font-weight: bold;

        &:after {
            right: 20px;
            margin-top: -4px;
            @include ico-angle(right, 6px, 1px, #fff, static);
        }
    }
    .c_amazon_card_col_5 {
        position: absolute;
        left: 478px;
        top: 50px;
    }
}

.c_amazon_realtime_refresh {
    @include clearfix;
    position: relative;
    margin: 20px 0 0 0;

    &:before {
        @include sprite-retina($sp_amazon_2x_realtime_bg);
        position: absolute;
        left: 132px;
        top: 13px;
        content: "";
    }
    .refresh_time {
        position: relative;
        float: right;
        height: 38px;
        margin: 0 39px 0 0;
        line-height: 36px;
        padding: 0 45px 0 20px;
        border: 1px solid #eee;
        border-radius: 19px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
        background: #fff;
        color: #333;
        font-size: 15px;
        z-index: 10;

        .value {
            font-family: $font-family-number-lato;
        }
    }
    .refresh_button {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        line-height: 200px;

        &::before {
            @include sprite-retina($sp_amazon_2x_refresh);
            position: absolute;
            right: 20px;
            top: 6px;
            content: "";
        }
    }
}

.c_amazon_hotbrand {
    @include clearfix;

    > ul > li {
        float: left;
        overflow: hidden;
        width: 394px;
        margin: 29px 0 0 29px;
        border-radius: 4px;
        box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 0;
        }
        &:nth-child(3n-2) {
            margin-left: 0;
        }
        > a {
            display: block;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .c_amazon_card_row {
        height: 705px;

        li {
            height: 235px;
        }
    }
    .card_brand_thumb {
        img {
            width: 394px;
            height: 197px;
        }
    }
    .card_brand_description {
        position: relative;
        z-index: 10;
        margin: -50px 20px 0 20px;
        border-radius: 4px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
        background-color: #fff;

        .text {
            overflow: hidden;
            height: 4.5em;
            margin: 0 20px 13px 20px;
            padding: 16px 0 0 0;
            color: #666;
            font-size: 15px;
        }
    }
    .card_brand_more {
        display: block;
        border-top: 1px solid #f4f4f4;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 15px;
        color: #111;

        .point {
            color: #0b83e6;
        }
        .c_more {
            &:after {
                width: 6px;
                height: 6px;
                border-color: #111;
            }
        }
    }
}

.c_amazon_tab_metacategory {
    @include clearfix;
    position: relative;
    margin: 0 0 25px 0;

    .tab_inner {
        position: relative;
        width: 1240px;
        margin: 0 auto;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) inset;
        background: #fff;
    }
    li {
        position: relative;
        float: left;
        width: 103px;
        height: 90px;

        &.all {
            width: 105px;
            font-weight: bold;
        }

        &.all {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_all);
            }
        }
        &.brand {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_brand);
            }
        }
        &.trend {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_trend);
            }
        }
        &.beauty {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_beauty);
            }
        }
        &.food {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_food);
            }
        }
        &.sports {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_sports);
            }
        }
        &.baby {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_baby);
            }
        }
        &.interior {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_interior);
            }
        }
        &.life {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_life);
            }
        }
        &.digital {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_digital);
            }
        }
        &.health {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_health);
            }
        }
        &.hobby {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_hobby);
            }
        }
        &.car {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_car);
            }
        }
        &.gift {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_gift);
            }
        }
        &.overseas {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_overseas);
            }
        }
        &.tour {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_tour);
            }
        }
        &.ticket {
            button::before {
                @include sprite-retina($sp_mdtab_2x_md_ticket);
            }
        }
        &.active {
            button {
                color: #fff;
            }
            &.all {
                background: $color-11st-red;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_all_on);
                }
            }
            &.brand {
                background: #fe6d01;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_brand_on);
                }
            }
            &.trend {
                background: #ffa705;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_trend_on);
                }
            }
            &.beauty {
                background: #689e36;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_beauty_on);
                }
            }
            &.food {
                background: #388e3c;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_food_on);
                }
            }
            &.sports {
                background: #0e997b;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_sports_on);
                }
            }
            &.baby {
                background: #00887a;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_baby_on);
                }
            }
            &.interior {
                background: #02acc0;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_interior_on);
                }
            }
            &.life {
                background: #0288cf;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_life_on);
                }
            }
            &.digital {
                background: #0d47a1;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_digital_on);
                }
            }
            &.health {
                background: #311b91;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_health_on);
                }
            }
            &.hobby {
                background: #5e34b1;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_hobby_on);
                }
            }
            &.car {
                background: #7c1da2;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_car_on);
                }
            }
            &.gift {
                background: #4a148c;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_gift_on);
                }
            }
            &.overseas {
                background: #d71a60;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_overseas_on);
                }
            }
            &.tour {
                background: #870f50;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_tour_on);
                }
            }
            &.ticket {
                background: #3e2722;

                button::before {
                    @include sprite-retina($sp_mdtab_2x_md_ticket_on);
                }
            }
        }
    }
    button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 43px 0 0 0;
        text-align: center;
        box-sizing: border-box;

        &::before {
            position: absolute;
            left: 50%;
            top: 11px;
            margin-left: -20px;
            content: "";
        }

        &.all {
            background: $color-11st-red;
        }
        &.brand {
            @include sprite-retina($sp_mdtab_2x_md_brand);
        }
        &.trend {
            background: #ffa705;
        }
        &.beauty {
            background: #689e36;
        }
        &.food {
            background: #388e3c;
        }
        &.sports {
            background: #0e997b;
        }
        &.baby {
            background: #00887a;
        }
        &.interior {
            background: #02acc0;
        }
        &.life {
            background: #0288cf;
        }
        &.digital {
            background: #0d47a1;
        }
        &.health {
            background: #311b91;
        }
        &.hobby {
            background: #5e34b1;
        }
        &.car {
            background: #7c1da2;
        }
        &.gift {
            background: #4a148c;
        }
        &.overseas {
            background: #d71a60;
        }
        &.tour {
            background: #870f50;
        }
        &.ticket {
            background: #3e2722;
        }
    }
    .icon {
        position: absolute;
        left: 50%;
        top: 11px;
        width: 40px;
        height: 40px;
        margin-left: -20px;
        background-size: 40px 40px;
    }
}

//와칸다 쇼핑 TIP
.c_pagination {
    &.c_pagination_style_1 {
        margin-top: 30px;
    }
}

//서브공통
.c_amazon_title_subtop {
    @include clearfix;
    position: relative;
    padding: 55px 0 22px 0;

    .title {
        float: left;
        font-size: 28px;
        color: #111;
        font-weight: bold;
    }
    .description {
        float: left;
        margin: 9px 0 0 10px;
        font-size: 17px;
        color: #666;
    }
    .c_amazon_refresh {
        position: absolute;
        right: 0;
        top: 55px;
    }
    .point {
        color: #0b83e6;
    }
    .c_more {
        position: absolute;
        right: 0;
        top: 63px;
    }
    + .c_amazon_theme_text {
        margin-top: -5px;
    }
}

.c_amazon_shoppingtip_board {
    border-top: 1px solid #eee;

    dt {
        position: relative;
        display: block;
        padding: 27px 140px 28px 100px;
        font-size: 17px;
        letter-spacing: -0.5px;
        border-bottom: 1px solid #eee;
        color: #111;
    }
    .tip_category {
        position: absolute;
        left: 0;
        top: 25px;
        overflow: hidden;
        max-width: 98px;
        height: 30px;
        line-height: 28px;
        padding: 0 12px;
        border: 1px solid rgba(51, 51, 51, 0.2);
        border-radius: 15px;
        box-sizing: border-box;
        font-size: 13px;
        letter-spacing: -0.5px;
        font-weight: bold;
        color: #111;

        &.tip_category_point {
            color: $color-11st-red;
            letter-spacing: 0;
            font-family: $font-family-number-lato;
            border: solid 1px rgba(244, 49, 66, 0.2);
        }
    }
    .tip_date {
        position: absolute;
        right: 63px;
        top: 29px;
        font-size: 15px;
        color: #999;
        font-family: $font-family-number-lato;
    }
    .tip_new {
        @include sprite-retina($sp_amazon_2x_new);
        display: inline-block;
        margin: -3px 0 0 8px;
        overflow: hidden;
        line-height: 200px;
        vertical-align: middle;
    }
    .tip_button {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        line-height: 500px;

        &::before {
            position: absolute;
            right: 15px;
            top: 26px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.4);
            content: "";
        }
        &::after {
            position: absolute;
            right: 25px;
            top: 35px;
            @include ico-angle(down, 5px, 2px, #111, static);
        }
    }
    dd {
        display: none;
        padding: 50px 50px 50px 100px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #333;
        background: #fafafa;
        border-bottom: 1px solid #eee;
    }
    .active {
        dd {
            display: block;
        }
        .tip_button {
            &::after {
                top: 38px;
                @include ico-angle(up, 5px, 2px, #111, static);
            }
        }
    }
}

.c_amazon_sort {
    @include clearfix;
    position: relative;
    min-height: 29px;
    margin: 0 0 29px 0;
    z-index: 30;

    .total {
        float: left;
        margin: 5px 0 0 0;
        font-size: 16px;
        color: #333;
    }
    .c_amazon_refresh {
        float: left;
    }
    .sort {
        position: absolute;
        right: 0;
        top: -2px;
    }
    .c_amazon_selectbox {
        float: left;
        margin: 0 0 0 10px;
    }
    &.c_amazon_sort_line {
        border-top: 1px solid #eee;
        padding-top: 20px;
        margin-bottom: 20px;

        .sort {
            top: 18px;
        }
    }
}

.c_amazon_tab_category {
    margin: 0 0 22px 0;

    .tab_inner {
        position: relative;
        overflow: visible;
        width: 1240px;
        margin: 0 auto;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) inset;
        background: #fff;
    }

    &.c_amazon_tab_category_fixed {
        position: fixed;
        top: 139px;
        left: 0;
        right: 0;
        z-index: 1000;
    }

    li {
        float: left;
        width: 103px;
        height: 90px;

        button[aria-selected="true"] {
            font-weight: bold;
            color: $color-11st-red;

            .text {
                &:before {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 2px;
                    background: $color-11st-red;
                    content: "";
                }
            }
        }

        button {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;
            font-weight: normal;
            color: #666;

            .in_btn {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
            }
            .icon {
                position: absolute;
                left: 50%;
                top: 11px;
                width: 40px;
                height: 40px;
                margin-left: -20px;

                > img {
                    width: 40px;
                    height: 40px;
                }
            }
            .text {
                position: relative;
                display: inline-block;
                padding: 57px 0 11px 0;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.c_amazon_selectbox {
    &.active {
        &:after {
            border-color: #111;
        }
    }
    &:after {
        border-color: #111;
    }
    .selectbox_selected {
        min-width: 130px;
        padding-left: 12px;
        font-size: 15px;
        height: 40px;
        line-height: 38px;
        border: 1px solid #ccc;
    }
    .selectbox_text {
        font-weight: normal;
    }
    //개발 후 수정 후 삭제 시작
    .selectbox_button {
        &:after {
            right: 13px;
            border-color: #111;
        }
    }
    //개발 후 수정 후 삭제 끝
    .selectbox_option {
        top: 39px;
        border: 1px solid #ccc;

        .option {
            font-size: 15px;
            padding: 6px 10px;
        }
    }
}

.c_amazon_refresh {
    position: relative;
    display: inline-block;
    height: 38px;
    line-height: 36px;
    padding: 0 45px 0 20px;
    border-radius: 20px;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
    background: #fff;
    color: #333;
    font-size: 15px;
    z-index: 10;

    .date {
        font-family: $font-family-number-lato;
    }
    .refresh_button {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        line-height: 200px;

        &::before {
            @include sprite-retina($sp_amazon_2x_refresh);
            position: absolute;
            top: 6px;
            right: 20px;
            content: "";
        }
    }
}

.c_amazon_subtitle {
    @include clearfix;
    position: relative;
    padding: 0 0 20px 0;
    z-index: 50;

    &.c_amazon_subtitle_line {
        border-bottom: 1px solid #eee;
    }
    & + .c_amazon_categorytitle {
        margin-top: 20px;
    }
    .title {
        float: left;
        font-size: 20px;
        font-weight: bold;
        color: #111;
        white-space: nowrap;
    }
    .total {
        float: left;
        margin: 7px 0 0 23px;
        font-size: 16px;
        line-height: 16px;
        color: #333;

        .value {
            font-family: $font-family-number-lato;
        }
    }
    .c_amazon_selectbox {
        position: absolute;
        right: 0;
        top: -10px;
    }
    .c_more {
        position: absolute;
        right: 0;
        top: 4px;
        font-size: 15px;
    }
    .c_amazon_tab_line {
        position: absolute;
        right: -15px;
        top: 6px;
    }
    .c_layer_item {
        top: 40px;
        left: 0;
        width: 274px;
        padding: 20px 44px 20px 20px;
        font-size: 15px;
        color: $color-grayscale-02;
    }
}

.c_amazon_categorytitle {
    @include clearfix;
    position: relative;
    margin: 48px 0 20px 0;

    .title {
        float: left;
        font-size: 20px;
        font-weight: bold;
        color: #111;

        .point {
            color: #0b83e6;
        }
    }
    .c_more {
        position: absolute;
        right: 0;
        top: 4px;
        color: #111;
        font-size: 14px;

        &:after {
            border-color: #111;
        }
    }
}

//인기 브랜드
.b_amazon_brandlogo {
    margin: 60px 0 0 0;
}

.c_amazon_brandlogo {
    ul {
        @include clearfix;
    }
    li {
        float: left;
        width: 224px;
        height: 170px;
        margin: 30px 0 0 30px;
        text-align: center;
        border-radius: 4px;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
        background: #fff;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
            margin-top: 0;
        }
        &:nth-child(5n + 1) {
            margin-left: 0;
        }
    }
    a {
        display: block;
        height: 100%;

        &:hover {
            text-decoration: none;
        }
    }
    .brandlist_logo {
        display: block;
        overflow: hidden;
        width: 160px;
        height: 60px;
        margin: 35px auto 18px;

        img {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }
    .brandlist_text {
        display: inline-block;
        overflow: hidden;
        max-width: 160px;
        height: 25px;
        font-weight: bold;
        font-size: 17px;
        color: #111;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .brandlist_new {
        @include sprite-retina($sp_amazon_2x_new);
        display: inline-block;
        margin: 3px 0 0 4px;
        overflow: hidden;
        line-height: 200px;
        vertical-align: top;
    }
}

//인기 브랜드 상세
.c_amazon_brand_intro {
    @include clearfix;
    display: table;

    .intro_visual {
        display: table-cell;
        vertical-align: middle;
    }
    .intro_thumbnail {
        overflow: hidden;
        width: 600px;
        height: 240px;
        vertical-align: top;

        img {
            width: 100%;
        }
    }
    .intro_description {
        display: table-cell;
        width: 640px;
        padding: 0 60px;
        box-sizing: border-box;
        vertical-align: middle;

        p {
            overflow: hidden;
            max-height: 140px;
            color: #fff;
            font-size: 17px;
            line-height: 28px;
        }
    }
    &.c_amazon_brand_intro_logo {
        display: block;
        padding: 25px 0 27px 0;

        .intro_visual {
            display: block;
        }
        .intro_thumbnail {
            width: 100%;
            height: auto;
            text-align: center;

            img {
                width: 200px;
                height: 75px;
            }
        }
        .intro_description {
            display: block;
            width: auto;
            padding: 5px 50px 0 50px;

            p {
                max-height: 28px;
                font-size: 20px;
                text-align: center;
                color: $color-grayscale-02;
            }
        }
    }
}

.c_amazon_brand_banner {
    text-align: center;
    border-radius: 4px;
    overflow: hidden;

    img {
        max-width: 100%;
        height: 100px;
    }
}

//카테고리
.l_amazon_category {
    @include clearfix;
    width: 1240px;
    margin: 0 auto;

    .l_amazon {
        width: auto;
    }
    .c_amazon_sort {
        margin-bottom: 42px;
    }
}

.l_amazon_category_side {
    float: left;
    width: 224px;
}

.l_amazon_category_content {
    float: left;
    width: 1016px;
    padding: 0 0 0 28px;
    box-sizing: border-box;
}

.c_amazon_category_title {
    position: relative;
    height: 100px;

    &.title_expand {
        .inner {
            width: 900px;
        }
    }
    .inner {
        @include clearfix;
        position: absolute;
        left: 0;
        top: 0;
        padding: 37px 0 0 0;
    }
    .title {
        float: left;
        font-size: 20px;
        color: #111;
    }
    .total {
        float: left;
        margin: 2px 0 0 10px;
        font-size: 16px;
    }
    .point {
        color: $color-11st-red;
    }
}

.c_amazon_category_dropdown {
    position: relative;
    padding: 15px 0;
    border-top: 1px solid #eee;

    &.active {
        .dropdown_button {
            &::before {
                top: 10px;
                @include ico-angle(up, 7px, 1px, #111, static);
            }
        }
        .dropdown_content {
            display: block;
        }
    }
    .dropdown_subject {
        position: relative;
        font-size: 14px;
        line-height: 24px;
        color: #111;
    }
    .dropdown_content {
        display: none;
        padding: 0 0 8px 0;
    }
    .category_menu {
        margin: 12px 0 0 0;

        > li {
            margin: 5px 0 0 0;

            &:first-child {
                margin-top: 0;
            }
        }
        .c_radiobox {
            display: inline-block;
            vertical-align: top;
            cursor: pointer;

            .text {
                display: inline-block;
                font-size: 14px;
                color: #333;
                height: 23px;
                line-height: 23px;
                padding-left: 0;
                vertical-align: top;

                &:before {
                    display: none;
                }
            }
            .count {
                margin: 0 0 0 4px;
                font-size: 13px;
                color: #aaa;
                font-family: $font-family-number-lato;
            }
            input:checked + .text {
                color: $color-11st-red;
                font-weight: bold;
            }
        }
        .menu_2depth {
            margin: 5px 0 0 15px;

            > ul > li {
                margin: 5px 0 0 0;

                &:first-child {
                    margin-top: 0;
                }
                .c_radiobox {
                    max-width: 200px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align: left;

                    .text {
                        display: inline;
                    }
                    &:hover {
                        text-decoration: none;

                        .text {
                            text-decoration: underline;
                        }
                    }
                }
                &.menu_on {
                    .menu_3depth {
                        display: block;
                    }
                }
            }
        }
        .menu_3depth {
            margin: 5px 0 0 12px;

            > ul > li {
                margin: 5px 0 0 0;

                &:first-child {
                    margin-top: 0;
                }
                .c_radiobox {
                    max-width: 190px;
                }
            }
        }
    }
    .c_more {
        display: inline-block;
        margin: 12px 0 0 0;
        font-size: 12px;
        color: #999;

        &:after {
            border-color: #999;
        }
    }
    .dropdown_button {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        line-height: 200px;

        &::before {
            position: absolute;
            right: 8px;
            top: 4px;
            @include ico-angle(down, 8px, 1px, #111, static);
        }
    }
    .choice_menu {
        @include clearfix;
        margin: 7px 0 0 0;

        li {
            margin: 7px 0 0 0;

            &:first-child {
                margin-top: 0;
            }
        }
        .c_radiobox {
            > span {
                font-size: 13px;
                color: #222;
            }
        }
        &.col {
            li {
                float: left;
                margin: 0 15px 0 0;
            }
        }
    }
    .choice_menu_no {
        padding: 9px 0 0 0;
        font-size: 13px;
        color: #222;
    }
    .choice_price {
        @include clearfix;
        margin: 10px 0 0 0;

        .c_input_item {
            float: left;
            width: 90px;
            height: 30px;
            padding: 0 10px;
            line-height: 28px;
            font-size: 12px;
            border: 1px solid #ddd;
            outline: none;

            &:focus {
                border: 1px solid $color-11st-red;
            }
        }

        .symbol {
            float: left;
            width: 14px;
            color: #222;
            text-align: center;
            line-height: 30px;
        }

        .choice_price_button {
            float: left;
            position: relative;
            width: 30px;
            height: 30px;
            margin: 0 0 0 0;
            border: 1px solid #ddd;
            border-left: none;
            overflow: hidden;
            line-height: 200px;

            &:after {
                @include sprite-retina($sp_amazon_2x_search_gray_24);
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -12px 0 0 -11px;
                content: "";
            }

            &:focus,
            &.active {
                border: 1px solid $color-11st-red;
                background-color: $color-11st-red;
                margin-left: -2px;

                &:after {
                    @include sprite-retina($sp_amazon_2x_search_white_24);
                }
            }
        }
    }
    .content_research {
        margin: 20px 0 0 0;

        .c_search_amazon_content {
            height: 30px;
            border: none;

            .search_text {
                height: 100%;
                padding: 0 30px 0 10px;
                font-size: 12px;
                outline: 0;
                border: 1px solid #ddd;

                &:focus {
                    border: 1px solid $color-11st-red;
                }
            }

            .search_button {
                position: absolute;
                right: 0px;
                top: 0px;
                width: 30px;
                height: 30px;
                overflow: hidden;
                line-height: 200px;

                &:after {
                    @include sprite-retina($sp_amazon_2x_search_gray_24);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin: -12px 0 0 -12px;
                    content: "";
                }

                &:focus,
                &.active {
                    background-color: $color-11st-red;

                    &:after {
                        @include sprite-retina($sp_amazon_2x_search_white_24);
                    }
                }
            }
        }
    }
    .c_checkbox {
        cursor: pointer;

        > span:nth-of-type(1) {
            overflow: hidden;
            max-width: 201px;
            height: 18px;
            padding-left: 23px;
            font-size: 13px;
            line-height: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:before {
                width: 18px;
                height: 18px;
            }
            &:after {
                width: 8px;
                height: 4px;
            }
        }
    }
    .c_radiobox {
        cursor: pointer;

        > span:nth-of-type(1) {
            font-size: 13px;
            line-height: 18px;
            height: 18px;
            padding-left: 23px;

            &:before {
                width: 18px;
                height: 18px;
            }
        }
    }
}

.c_amazon_layer_category {
    display: none;
    position: absolute;
    top: 48px;
    z-index: 50;
    width: 862px;
    padding: 0;
    background-color: #fff;
    border: solid 1px #eee;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

    .layer_title {
        border-bottom: 1px solid #eee;

        .title {
            padding: 30px 70px 29px;
            text-align: center;
            color: #111;
            font-size: 20px;
        }
    }
    .layer_close {
        position: absolute;
        top: 38px;
        right: 32px;
        margin-top: -16px;

        @include ico-cancel(18px, 1px, #999, 7px);
    }
    .layer_content {
        padding: 30px;
        box-sizing: border-box;
        overflow-y: auto;
        min-height: 190px;
        max-height: 460px;
    }
    .layer_bottom {
        padding: 15px 0;
        text-align: center;
        box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
    }
    .button_confirm {
        min-width: 99px;
        height: 42px;
        line-height: 40px;
        font-weight: bold;
        font-size: 15px;
        background-color: $color-11st-red;
        background-image: linear-gradient(to right, #fc422f, #fc144e);
        border-color: $color-11st-red;
        color: #fff;
    }
    .category_list {
        @include clearfix;
        margin-top: -9px;

        > li {
            position: relative;
            float: left;
            width: 25%;
            padding: 9px 20px 9px 0;
            box-sizing: border-box;

            &:nth-child(4n-3) {
                clear: both;
            }
        }
        .c_radiobox {
            cursor: pointer;

            > span:nth-of-type(1) {
                font-size: 14px;
                height: auto;
                color: #222;
                vertical-align: top;
            }
            .text {
                overflow: hidden;
                max-width: 140px;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-left: 0;
                color: #222;

                &:before {
                    display: none;
                }
            }
            .count {
                display: inline-block;
                color: #aaa;
                vertical-align: middle;
                font-family: $font-family-number-lato;
                font-size: 11px;
            }
            &:hover {
                .text,
                .count {
                    text-decoration: underline;
                }
            }
        }
        .c_checkbox {
            > span:nth-of-type(1) {
                max-width: 157px;
            }
        }
    }
}

.s_amazon_category {
    .c_list_card_deal {
        width: auto;

        .c-card-item--deal {
            width: 478px;

            .c-card-item__thumb-img {
                height: 239px;
            }
        }
    }
    .c_list_card_gallery {
        width: auto;

        &.col_4 {
            li {
                flex: 0 1 224px;
                width: 224px;
            }
            .c-card-item {
                width: 224px;

                .c-card-item__thumb-img {
                    width: 204px;
                    height: 204px;
                }
                .c-card-item__soldout-text {
                    height: 204px;
                }

                .c-card-item__lowest {
                    &-value {
                        clear: left;
                    }
                }
            }
        }
    }
}

.l_amazon_category {
    .c_amazon_card_flex {
        &.col4 {
            li {
                flex: 0 1 224px;
                width: 224px;
            }
            .c-card-item {
                width: 224px;

                .c-card-item__thumb-img {
                    width: 204px;
                    height: 204px;
                }
            }
        }
    }
}

//베스트
.c_amazon_tab_category_sub {
    position: relative;
    margin: -22px 0 30px 0;
    padding: 10px 40px 22px 40px;
    border: 1px solid #f0f0f0;
    border-top: none;

    ul {
        @include clearfix;
    }
    li {
        float: left;
        width: 165px;
        margin: 12px 0 0 0;
        padding: 0 5px 0 0;
        box-sizing: border-box;
    }
    button {
        &[aria-selected="true"] {
            color: $color-11st-red;
            font-weight: bold;
        }
    }
}

.c_amazon_tab_line {
    display: inline-block;
    margin: 0 0 0 20px;

    li {
        > button {
            padding: 0 18px;
            line-height: 22px;
            font-size: 18px;
            background-color: #fff;
            font-weight: normal;
            font-family: $font-family-default;

            &::before {
                position: absolute;
                left: 0;
                top: 2px;
                width: 1px;
                height: 16px;
                background: #eee;
                content: "";
            }
            &[aria-selected="true"] {
                color: $color-11st-red;
                font-weight: bold;
            }
        }
        &:first-child {
            button {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.c_amazon_help {
    float: left;
    position: relative;
    width: 20px;
    height: 20px;
    margin: 6px 0 0 2px;
    overflow: hidden;
    line-height: 200px;

    &::before {
        @include sprite-retina($sp_amazon_2x_help);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "";
    }
}

//검색결과
.c_amazon_search_result {
    clear: both;
    padding: 88px 0;

    .result_info {
        line-height: 1.6;
        font-size: 20px;
        color: #666;
        text-align: center;

        strong {
            color: #222;
        }
        .point {
            color: $color-11st-red;
        }
    }
    .result_suggestion {
        margin: 40px 0 0 310px;

        li {
            position: relative;
            line-height: 1.6;
            font-size: 18px;
            color: #666;
            text-indent: 14px;

            &:before {
                margin-right: 10px;
                display: inline-block;
                vertical-align: middle;
                content: "";
                width: 2px;
                height: 2px;
                background: #666;
            }
        }
    }
}

//오늘의 딜
.c_amazon_deal_promotion {
    height: 100px;
    margin: 0 0 30px 0;
    text-align: center;
    background: #2ba0b4;
    padding: 0 80px;
    border-radius: 4px;
    box-sizing: border-box;

    .title {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        line-height: 100px;
        color: #fff;
        font-size: 23px;
        white-space: nowrap;
    }
}

//아마존딜탭
.s_amazon_dealcorner {
    padding-bottom: 0;
}

.c_amazon_card_row_deal {
    .swiper-container {
        padding: 20px 25px 25px 25px;
        margin: -20px -25px -25px -25px;
    }
    .swiper-slide {
        margin-right: 30px;
    }
    .list_navigator {
        button {
            width: 50px;
            height: 50px;
            margin-top: -25px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);

            &.previous {
                &::before {
                    @include sprite-retina($sp_card_item_arrow_left);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_card_item_arrow_left_on);
                    }
                }
            }
            &.next {
                &::before {
                    @include sprite-retina($sp_card_item_arrow_right);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_card_item_arrow_right_on);
                    }
                }
            }
            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
        }
    }

    li {
        float: left;
        width: 605px;
        height: 232px;
        margin: 0 0 0 30px;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);

        &:first-child {
            margin-left: 0;
        }
    }

    .c-card-item {
        a {
            &:hover {
                text-decoration: none;
            }
        }
        &:hover {
            .c-card-item__thumb-img {
                img {
                    transform: scale(1.08);
                }
            }
        }
        .c-card-item__thumb-img {
            overflow: hidden;
            display: inline-block;
            width: 212px;
            height: 212px;
            vertical-align: top;

            img {
                transition: transform 0.2s ease-in;
            }
        }
        .c-card-item__soldout-text {
            &::before {
                width: 60%;
                height: 60%;
                background-size: 100% 100%;
            }
        }
        .c-card-item__info {
            @include clearfix;
            padding: 6px 10px 0 20px;
            vertical-align: top;
        }
        .c-nation {
            margin-bottom: 9px;
        }
        .c-time-flag {
            float: left;
            margin: 0 0 8px 0;
        }
        .c-flag {
            float: left;
            margin: 0 0 8px 0;
        }
        .c-card-item__name {
            clear: both;
            max-height: 1.5em;
            line-height: 1.5;
            margin: 0 0 10px 0;
        }
        .c-card-item__price-info {
            margin: 0 0 11px 0;
            padding: 0;

            .c-card-item__rate {
                margin-right: 8px;
                font-size: 16px;

                .value {
                    font-size: 22px;
                    line-height: 22px;
                }
                .c-card-item__special {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            .c-card-item__price {
                font-size: 15px;
                line-height: 22px;

                .value {
                    font-size: 22px;
                    line-height: 22px;
                }
            }
        }
        .c-starrate {
            @include clearfix;
            margin: 0 0 10px 0;
            line-height: 18px;

            dd {
                float: left;
            }
            .sr-only {
                @include sr-only;
            }
        }
        .c-card-item__benefit {
            margin: 0 0 7px 0;

            .benefit {
                padding: 0 0 0 12px;

                &:before {
                    position: absolute;
                    left: 5px;
                    top: 9px;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background: #ccc;
                    content: "";
                }
            }
            dt + .benefit {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
        .c-card-item__delivery {
            @include clearfix;

            dd {
                float: left;
            }
            .allclub {
                &:before {
                    float: left;
                    margin: 2px 2px 0 0;
                }
                .c-card-item__point {
                    color: $color-11st-red;
                }
            }
            .delivery {
                position: relative;
                padding: 0 0 0 12px;

                &:before {
                    position: absolute;
                    left: 5px;
                    top: 9px;
                    width: 2px;
                    height: 2px;
                    margin: 0;
                    border-radius: 50%;
                    background: #ccc;
                    content: "";
                }
                .c-card-item__point {
                    color: $color-11st-blue;
                }
            }
            dt + .delivery {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    &.c_amazon_card_row_deal_only {
        float: left;

        li {
            width: 732px;
        }
        .c-card-item {
            .c-card-item__info {
                padding: 6px 20px 0 30px;
            }
        }
    }
}

.c_amazon_dealcorner_banner {
    float: left;
    overflow: hidden;
    width: 478px;
    height: 232px;
    margin: 0 0 0 30px;
    border-radius: 4px;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);

    > a {
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
    }
}

.c_amazon_dealcorner_category_link {
    position: relative;

    li {
        float: left;
        width: 106px;
        margin: 0 20px 0 0;
        box-sizing: border-box;

        a {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            box-sizing: border-box;
            font-weight: normal;
            color: #111;

            .icon {
                overflow: hidden;
                display: block;
                width: 90px;
                height: 90px;
                margin: 0 auto;
                border-radius: 45px;

                > img {
                    width: 100%;
                    height: 100%;
                }
            }
            .text {
                display: inline-block;
                margin: 11px 0 0 0;
                font-size: 15px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .list_navigator {
        button {
            overflow: hidden;
            position: absolute;
            top: 50%;
            text-align: center;
            box-sizing: border-box;
            width: 50px;
            height: 50px;
            margin-top: -25px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            line-height: 200px;
            z-index: 10;
            background: #fff;

            &.previous {
                left: -25px;

                &::before {
                    @include sprite-retina($sp_amazon_2x_arrow_left);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin: -9px 0 0 -5px;
                    content: "";
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_left_on);
                    }
                }
            }
            &.next {
                right: -25px;

                &::before {
                    @include sprite-retina($sp_amazon_2x_arrow_right);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin: -9px 0 0 -5px;
                    content: "";
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_amazon_2x_arrow_right_on);
                    }
                }
            }
            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
            &:disabled {
                display: none;
            }
        }
    }
}

.c_amazon_card_specialprice {
    ul {
        @include clearfix;
    }
    .swiper-container {
        padding: 25px;
        margin: -25px;
    }
    .swiper-slide {
        margin-right: 30px;
    }
    .list_navigator {
        button {
            width: 50px;
            height: 50px;
            margin-top: -25px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);

            &.previous {
                &::before {
                    @include sprite-retina($sp_card_item_arrow_left);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_card_item_arrow_left_on);
                    }
                }
            }
            &.next {
                &::before {
                    @include sprite-retina($sp_card_item_arrow_right);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_card_item_arrow_right_on);
                    }
                }
            }
            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
        }
    }
    li {
        float: left;
        overflow: hidden;
        width: 224px;
        height: 315px;
        margin: 0 0 0 30px;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
        background: #fff;

        &:hover {
            .thumb {
                .img {
                    transform: scale(1.08);
                }
            }
        }
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .thumb {
        overflow: hidden;
        width: 224px;
        height: 224px;

        .img {
            display: block;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            transition: transform 0.2s ease-in;
            background-position: 50% 50%;
        }
    }
    .price_info {
        overflow: hidden;
        height: 1.5em;
        margin: 14px 20px 0 20px;
        color: #0b83e6;
        font-size: 18px;
        text-align: center;
        word-break: break-all;

        .value {
            font-family: $font-family-number-lato;
        }
    }
    .text {
        overflow: hidden;
        height: 1.5em;
        margin: 2px 20px 0 20px;
        color: #111;
        font-size: 19px;
        text-align: center;
        word-break: break-all;
    }
}

.c_amazon_dealcorner_hotdealcategory {
    ul {
        @include clearfix;
    }
    li {
        position: relative;
        overflow: hidden;
        float: left;
        width: 394px;
        height: 270px;
        margin: 29px 0 0 29px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);

        &:nth-child(3n-2) {
            margin-left: 0;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 0;
        }
    }
    .thumb {
        img {
            width: 394px;
            height: 270px;
        }
    }
    .subject {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
    }
    .text {
        overflow: hidden;
        display: inline-block;
        max-width: 354px;
        height: 32px;
        margin: 0 20px;
        padding: 0 20px;
        font-size: 19px;
        line-height: 30px;
        vertical-align: top;
        color: #111;
        background: rgba(255, 255, 255, 0.8);
    }
}

.c_amazon_no_data {
    min-height: 508px;
    padding: 88px 0 88px;
    text-align: center;
    box-sizing: border-box;

    .text_emphasis {
        font-size: 20px;
        color: #222;
    }
    .text {
        font-size: 18px;
        color: $color-grayscale-04;
    }
    .point {
        color: $color-11st-red;
    }
}

// 로딩중
.c_loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    background-color: rgba(255, 255, 255, 0.5);

    .loading {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        background-color: #fff;
        border: solid 1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        line-height: 200px;

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 28px;
            height: 28px;
            margin: -14px 0 0 -14px;
            background: url("/img/common/v2/loading_04.gif") no-repeat 0 0;
            background-size: 100%;
            content: "";
        }
    }
}

.c_amazon_loading {
    position: relative;
    height: 304px;

    .loading {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        background-color: #fff;
        border: solid 1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        line-height: 200px;

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 28px;
            height: 28px;
            margin: -14px 0 0 -14px;
            background: url("/img/common/v2/loading_04.gif") no-repeat 0 0;
            background-size: 100%;
            content: "";
        }
    }
}

.c_list_space {
    //리스트에서 무한스크롤시 간격추가를 위한 클래스
    margin-top: 30px;
}

.c_amazon_error {
    position: relative;
    clear: both;
    height: 500px;
    box-sizing: border-box;
    background: #fff;

    .caution {
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -50px;
        font-size: 16px;
        color: $color-grayscale-03;
        text-align: center;

        &::before {
            display: block;
            margin: 0 auto 15px auto;
            @include sprite-retina($sp_amazon_2x_caution);
            content: "";
        }
    }
}
