@import "../../common/helpers/variables";
@import "../../common/mixins";
@import "../sprites/sp_card_item.scss";

.c-time-flag {
    display: inline-block;
    vertical-align: middle;
}

.c-time {
    float: left;
    height: 22px;
    min-width: 73px;
    padding: 3px 5px 2px;
    margin-right: 2px;
    border-color: #49a3c7;
    background-color: #49a3c7;
    font-size: 12px;
    color: #fff;
    line-height: 1.3;
    white-space: nowrap;
    box-sizing: border-box;

    [role="timer"] {
        font-family: $font-family-number-lato;
    }

    &:before {
        @include sprite-retina($sp_card_item_timer);
        display: inline-block;
        margin: -2px 3px 0 0;
        vertical-align: middle;
        content: "";
    }

    &--gradient-purple {
        color: $color-gray-14;
        border: 0;
        background: linear-gradient(90deg, rgba(120, 14, 255, 0.85) 0%, rgba(44, 89, 255, 0.85) 100%);
        line-height: 18px;
    }
}
