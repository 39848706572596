@charset "UTF-8";

.c_billboard {
    position: relative;
    overflow: hidden;
    width: 1240px;
    height: 400px;

    .visual {
        li {
            float: left;
        }
    }
    .navigator {
        position: absolute;
        right: 0;
        bottom: 25px;

        button {
            float: left;
            position: relative;
            overflow: hidden;
            line-height: 300px;

            &:before {
                position: absolute;
                content: "";
            }
            &.previous {
                &:before {
                    left: 16px;
                }
            }
            &.next {
                &:before {
                    right: 16px;
                }
            }
        }
    }
    .indicate {
        position: absolute;
        bottom: 25px;
        right: 102px;
        line-height: 45px;
        box-sizing: border-box;

        .pagination {
            font-family: $font-family-number-lato;

            .current {
                font-weight: bold;
            }
        }
        .more {
            position: absolute;
            right: 0;
            top: 0;
            overflow: hidden;
            line-height: 300px;

            &::before {
                position: absolute;
                right: 11px;
                top: 11px;
                content: "";
            }
        }
    }
    .control {
        position: absolute;
        right: 243px;
        bottom: 25px;

        button {
            position: relative;
            overflow: hidden;
            line-height: 300px;

            &:before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: "";
            }
        }
    }
}

.c_billboard_animated {
    .visual .active {
        animation: fadeIn 1s ease-in;
    }

    .slide {
        transition: 1s all;
    }
}
