@charset "UTF-8";

.c_tab_item {
    ul {
        @include clearfix;
    }
    li {
        float: left;

        button,
        > a {
            position: relative;
            display: block;
            color: #111;
            font-weight: bold;
            box-sizing: border-box;
            font-family: $font-family;
            text-align: center;
        }
        a {
            &:hover {
                text-decoration: none;
            }
        }
        button {
            width: 100%;
        }
        .icon {
            display: block;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .tab_inner {
        overflow: hidden;
    }
    .tab_navigator {
        button {
            overflow: hidden;
            position: absolute;
            top: 50%;
            line-height: 300px;
            background: #fff;
            z-index: 10;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -9px 0 0 -5px;
                content: "";
            }
            &.previous {
                left: -25px;
            }
            &.next {
                right: -25px;
            }
            &:disabled {
                display: none;
            }
        }
    }
}
