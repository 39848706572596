@charset "UTF-8";

@import "../../../common/mixins/selectbox"; //셀렉트박스

.c_selectbox {
    @include selectbox($width: 220px, $height: 46px, $font-size: 16px, $font-color: #666, $font-color-disabled: #999, $font-color-selected: #111, $background-color-disabled: #fafafa, $border-color: #ddd, $arrow-url: "/img/common/selectbox/style_1.png", $arrow-size: 38px);
    vertical-align: middle;
}

.c_selectbox_style {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    .selectbox_selected {
        position: relative;
        min-width: 168px;
        height: 36px;
        line-height: 36px;
        padding: 0 35px 0 20px;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #333;
        border: 1px solid #eee;
        box-sizing: border-box;
        text-align: left;
        font-family: $font-family;
    }

    .selectbox_button {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        line-height: 200px;

        &:after {
            position: absolute;
            right: 16px;
            top: 50%;
            margin: -5px 0 0 -4px;
            @include ico-angle(down, 6px, 1px, #999, static);
        }
    }
    &.active {
        .selectbox_button {
            &:after {
                margin-top: -2px;
                @include ico-angle(up, 6px, 1px, #999, static);
            }
        }
    }
    .selectbox_option {
        display: none;
        position: absolute;
        left: 0;
        top: 35px;
        width: 100%;
        padding: 5px 0;
        box-sizing: border-box;
        border: 1px solid #eee;
        border-top-color: #f4f4f4;
        background: #fff;

        li > a,
        li > button {
            display: block;
            padding: 6px 20px;
            color: #333;
            box-sizing: border-box;
            font-size: 14px;
        }
        li > button {
            width: 100%;
            text-align: left;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    &.active {
        .selectbox_option {
            display: block;
            z-index: 10;
        }
    }
}

.c_selectbox_design {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    &:after {
        position: absolute;
        right: 16px;
        top: 50%;
        margin: -5px 0 0 -4px;
        @include ico-angle(down, 6px, 1px, #999, static);
    }
    &.active {
        &:after {
            margin-top: -2px;
            @include ico-angle(up, 6px, 1px, #999, static);
        }
        .selectbox_option {
            display: block;
            z-index: 10;
        }
    }
    .selectbox_selected {
        position: relative;
        min-width: 168px;
        height: 36px;
        line-height: 34px;
        padding: 0 35px 0 20px;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #333;
        border: 1px solid #eee;
        box-sizing: border-box;
        text-align: left;
        font-family: $font-family;
        cursor: pointer;
    }
    .selectbox_option {
        display: none;
        position: absolute;
        left: 0;
        top: 35px;
        width: 100%;
        padding: 5px 0;
        box-sizing: border-box;
        border: 1px solid #eee;
        border-top-color: #f4f4f4;
        background: #fff;

        .option {
            display: block;
            width: 100%;
            padding: 6px 20px;
            color: #333;
            box-sizing: border-box;
            font-size: 14px;
            text-align: left;
            cursor: pointer;

            &:hover,
            &.focused {
                text-decoration: underline;
            }
        }
    }
}
